import React from 'react';
import { TableCell as MuiTableCell } from '@mui/material';

import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';

const style = ({ palette }) => ({
  customCell: {
    border: 'none',
    whiteSpace: 'pre',
  },
  bodyCell: {
    border: 'none',
    padding: '4px',
  },
  autoComplete: {
    width: 200,
  },
  noDisplay: {
    display: 'none',
  },
  normalDisplay: {
    display: 'table-cell',
  },
  stickyCell: {
    position: 'sticky',
    right: 0,
    backgroundColor: palette.gray['+9'],
  },
  oddRow: { backgroundColor: palette.primary['+9'] },
  evenRow: { backgroundColor: palette.background.paper },
});

const WTCTableCell = ({
  classes,
  children,
  display,
  bodyCell,
  autoComplete,
  sticky = false,
  allowanceBtnsBg = null,
}) => {
  return (
    <MuiTableCell
      size="small"
      className={clsx({
        [classes.stickyCell]: sticky,
        [classes.bodyCell]: bodyCell,
        [classes.customCell]: !bodyCell,
        [classes.normalDisplay]: display,
        [classes.noDisplay]: !display,
        [classes.autoComplete]: autoComplete,
        [classes.evenRow]: allowanceBtnsBg === 'even',
        [classes.oddRow]: allowanceBtnsBg === 'odd',
      })}
    >
      {children}
    </MuiTableCell>
  );
};

export default withStyles(style)(WTCTableCell);
