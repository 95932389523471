import createAction from './createAction';

export const runReport = createAction('run/report');
export const loading = createAction('loading/schedule/reports');
export const fetchScheduledReports = createAction('fetch/schedule/report');
export const storeScheduledReports = createAction('store/schedule/report');
export const saveScheduleReport = createAction('save/schedule/report');
export const removeScheduledReport = createAction('remove/schedule/report');
export const editingScheduledReport = createAction('editing/schedule/report');
export const fetchClientDetails = createAction(
  'fetch/fetchClientDetails/report',
);

export const rejectedLoading = createAction(
  'loading/rejectedTimecardCommentReport/reports',
);
export const runRejectedTimecardCommentsReport = createAction(
  'run/rejectedTimecardCommentReport/report',
);
export const runTemplateReport = createAction('report/TemplateReport');
export const loadAllClients = createAction('report/LoadAllClients');
export const storeAllCounts = createAction('store/report/storeAllCounts');
export const storeClientDetailsById = createAction(
  'store/report/storeClientDetailsById',
);

export const runHotHostReport = createAction('run/hotCostReport');
export const setHotCostParams = createAction('run/setHotCostParams');

export const onMRFCancel = createAction('run/hotCostReport/cancel');
export const onMRFRunDownload = createAction('run/hotCostReport/download');
export const onMRFToDts = createAction('run/hotCostReport/redirectToDts');

export const fetchUsersAllProjects = createAction('fetch/searchEmail/projects');
export const storeCorrectEmailProjects = createAction(
  'store/correctEmail/projects',
);
export const storeIncorrectEmailProjects = createAction(
  'store/incorrectEmail/projects',
);
export const mergeEmailProjects = createAction('merge/projects');

export const editAdminUser = createAction('edit/accessadmin/adminUsers');
export const saveAdminUser = createAction('save/accessadmin/adminUsers');
export const fetchAdminUsers = createAction('fetch/accessadmin/adminUsers');
export const storeAdminUsers = createAction('store/accessadmin/adminUsers');
export const sortAdminUsers = createAction('sort/accessadmin/adminUsers');
export const runAdminUsersReport = createAction('run/AdminUsersReport');
