module.exports = {
  hostUrl: '$HOST_URL',
  apiUrl: '$HOST_URL/api',
  myCncUrl: '$MYCNC_URL',
  startPlusUrl: '$START_PLUS_URL',
  okta: {
    issuer: '$OKTA_ISSUER',
    clientId: '$OKTA_CID',
    redirect: '$HOST_URL',
  },
  notifications_api: {
    baseUrl: '$HOST_URL/signalr',
    timecard: '/timecard',
  },
  keys: {
    muiProLicenseKey: '$MUI_PRO_LICENSE_KEY',
  },
  dashboardConfig: {
    issuer: '$MY_CNC_ISSUER',
    clientId: '$MY_CNC_CID',
    url: {
      myCnC: '$MY_CNC_API',
      oktaUserSettings: '$OKTA_USER_SETTINGS_API',
    },
  },
};
