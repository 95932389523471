import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  COMMENT_STATUS_MAP,
  FILE_COMMENT_CATEGORY_MAP,
  makeTextFilter,
  makeTimecardsFilter,
  DELIVERY_METHODS,
  US_SELECTABLE_DELIVERY_METHODS,
  CAN_SELECTABLE_DELIVERY_METHODS,
  getTcHeadersFromRecordId,
} from 'feature/DigitalEdits/digitalEditsUtils';

//selectors
import { currentUser } from 'selectors/session';
import { getData } from '../feature/SearchInvoices/selectors';

import { makeFilterSelectors } from './shared';
import { isRegionCanada } from 'utils/helperFunctions';
import { customFormatDate } from 'utils/formatDate';

export const getCurrentRecordId = state =>
  _.get(state, 'digitalEdits.currentRecordId', '');

export const getCurrentInvoiceId = state =>
  _.get(state, 'digitalEdits.currentInvoiceId', '');

export const getSearchText = state =>
  _.get(state, 'digitalEdits.searchText', '');

export const getSortBy = state => _.get(state, 'digitalEdits.sortBy', {});

export const getDataCardsView = state =>
  _.get(state, 'digitalEdits.dataCardsView', []);

const getFilters = state => _.get(state, 'digitalEdits.filters');

export const getInvoice = state => _.get(state, 'digitalEdits.invoice', {});

export const getFiltersActive = createSelector([getFilters], filters => {
  const activeFilters = {
    anySelected: false,
  };
  for (const filterName in filters) {
    if (Object.hasOwnProperty.call(filters, filterName)) {
      const filterList = filters[filterName];
      activeFilters[filterName] = filterList
        .filter(f => f.selected)
        .map(f => f.value);
      if (activeFilters[filterName].length > 0) {
        activeFilters.anySelected = true;
      }
    }
  }

  return activeFilters;
});

export const getCommentState = state =>
  _.get(state, 'digitalEdits.commentState', 'closed');

const getCommentsUnsorted = state => _.get(state, 'digitalEdits.comments', []);

export const getComments = createSelector([getCommentsUnsorted], comments => {
  const sorted = comments.slice();
  sorted.sort((a, b) => {
    const aDate = new Date(a.updatedDate);
    const bDate = new Date(b.updatedDate);
    if (aDate < bDate) return 1;
    return -1;
  });
  return sorted;
});

export const getUnsubmittedComment = createSelector([getComments], comments => {
  const unsubmitted = comments.find(
    c =>
      c.status === COMMENT_STATUS_MAP['Unsubmitted'] &&
      c.category === FILE_COMMENT_CATEGORY_MAP['None'],
  );

  if (unsubmitted) return unsubmitted;

  return {};
});

export const getDeliveryNote = createSelector([getComments], comments => {
  const note = comments.find(
    c =>
      c.status === COMMENT_STATUS_MAP['Unsubmitted'] &&
      c.category === FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery'],
  );

  if (note) return note;

  return {};
});

export const getHasUnsubmitted = createSelector(
  [getUnsubmittedComment],
  comment => !_.isEmpty(comment),
);

export const getExistingComments = createSelector([getComments], comments => {
  const existing = comments.filter(
    c => c.status !== COMMENT_STATUS_MAP['Unsubmitted'],
  );

  if (existing) return existing;

  return [];
});

const getRecordObj = state => _.get(state, 'digitalEdits.records', {});

export const getRecordArray = createSelector([getRecordObj], obj => {
  let records = [];
  for (const id in obj) {
    if (Object.hasOwnProperty.call(obj, id)) {
      const record = obj[id];
      records.push(record);
    }
  }

  return records;
});

const getSortedRecords = createSelector(
  [getRecordArray, getSortBy],
  (unsorted, sortBy) => {
    const LESS_THAN = sortBy.sortOrder === 'ascSort' ? -1 : 1;
    const GREATER_THAN = LESS_THAN * -1;
    const sortField = sortBy.field;

    const records = unsorted.slice().sort((a, b) => {
      let aSortVal = _.get(a, sortField, '~~~~~~~~~~~~~~~~~~~~~');
      let bSortVal = _.get(b, sortField, '~~~~~~~~~~~~~~~~~~~~~');

      if (sortBy.isNumber) {
        aSortVal = Number(aSortVal);
        bSortVal = Number(bSortVal);
      }

      if (aSortVal < bSortVal) {
        return LESS_THAN;
      } else if (aSortVal > bSortVal) {
        return GREATER_THAN;
      } else {
        const aSecondSort = a.employeeName;
        const bSecondSort = b.employeeName;
        if (aSecondSort < bSecondSort) {
          return -1;
        } else if (aSecondSort > bSecondSort) {
          return 1;
        } else {
          const aEarnedDate = a?.distributions?.[0]?.earnedDate || '';
          const bEarnedDate = b?.distributions?.[0]?.earnedDate || '';

          const aDate = moment(aEarnedDate);
          const bDate = moment(bEarnedDate);

          if (aDate.isBefore(bDate)) {
            return -1;
          } else {
            return 1;
          }
        }
      }
    });

    return records;
  },
);

const getUnsavedEdits = state => _.get(state, 'digitalEdits.unsavedEdits', {});

export const getErrorHeaderIds = state =>
  _.get(state, 'digitalEdits.errorHeaderIds', []);

export const getTimeoutHeaderIds = state =>
  _.get(state, 'digitalEdits.timeoutHeaderIds', []);

export const getAcEditRecord = state =>
  _.get(state, 'digitalEdits.acEditRecord', null);

export const getRecords = createSelector(
  [
    getSortedRecords,
    getSearchText,
    getFiltersActive,
    getErrorHeaderIds,
    getTimeoutHeaderIds,
    getAcEditRecord,
  ],
  (records, searchText, activeFilters, errors, timeouts, aceEditRecord) => {
    const textFilterFunc = makeTextFilter(searchText);

    const errorAndTimeouts = errors.concat(timeouts);

    const filterFunc = makeTimecardsFilter(
      activeFilters,
      errorAndTimeouts,
      aceEditRecord,
    );

    return records.filter(textFilterFunc).filter(filterFunc);
  },
);
export const getHasInvoiceUnapprovedDeal = createSelector(
  [getRecords],
  records => {
    return records.some(record =>
      record?.dealMemoProvisionalDetails?.some(
        dealMemo =>
          dealMemo.provisional === 'I' || dealMemo.validStatus === 'N',
      ),
    );
  },
);
export const getNameListLoading = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.details && loading.summary;
};

export const getEditFieldsLoading = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.editFields;
};
export const getSavingACE = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.savingACE;
};
export const getRecordListLoading = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.details || loading.summary;
};
export const getIsSavingComment = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.savingComment || false;
};
export const getIsLoadingComments = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.comments || false;
};
export const getIsSubmittingComment = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.submittingComment || false;
};

//get current invoice on either searchInvoices or digitalEdits page
const getCurrentInvoice = createSelector(
  [getData, getCurrentInvoiceId, getInvoice],
  (invoices, currentInvoiceId, invoice) => {
    const searchInvoicesInvoice = invoices.find(
      invoice => invoice.htgInvoiceId === currentInvoiceId,
    );

    if (searchInvoicesInvoice) {
      return searchInvoicesInvoice;
    }

    return invoice;
  },
);

export const getIsPreEditComment = createSelector(
  [getCurrentInvoice],
  invoice => !!(invoice?.isPreEditInvoice === 'Y'),
);

export const getIsDigitalEditEligible = createSelector(
  [getCurrentInvoice],
  invoice => !!(invoice?.digitalEditEligible === 'Y'),
);

export const getSelectedInvoiceStatus = createSelector(
  [getCurrentInvoice],
  invoice => invoice?.status || '',
);
export const getIsFormDirty = state =>
  _.get(state, 'digitalEdits.isFormDirty', false);
export const getIsInvoiceStatusChanged = state =>
  _.get(state, 'digitalEdits.isInvoiceStatusChanged', false);
export const getIsLoadingUploads = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.uploads || false;
};
export const getIsApproving = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.approving || false;
};
export const getIsDeletingComment = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.deleteComment || false;
};
export const getResubmitLoading = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.resubmitInvoice || false;
};

export const getIsUpdatingInvoiceStatus = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.updateInvoiceStatus || false;
};
export const getCommentSaveFails = state => {
  return _.get(state, 'digitalEdits.commentSaveFails', 0);
};

export const getInvoiceCharges = createSelector([getInvoice], invoice => {
  const charges = invoice?.invoiceCharges || [];

  const invoiceCharges = charges?.map(charge => {
    return {
      ...charge,
      freeFieldCode1: charge?.freeFieldCode1 || null,
      freeFieldCode2: charge?.freeFieldCode2 || null,
      freeFieldCode3: charge?.freeFieldCode3 || null,
      freeFieldCode4: charge?.freeFieldCode4 || null,
      insuranceCode: charge?.insuranceCode || null,
      locationCode: charge?.locationCode || null,
      setCode: charge?.setCode || null,
      accountCode: charge?.accountCode || null,
      amount: charge?.amount || null,
      description: charge?.description || null,
      payCode: charge?.payCode || null,
      seriesCode: charge?.seriesCode || null,
    };
  });
  return invoiceCharges?.slice() || [];
});
export const getInvoiceStatusCode = state =>
  _.get(state, 'digitalEdits.invoice.status', '');

export const getInvoiceStatus = createSelector([getInvoice], invoice => {
  if (!invoice) return null;

  const status = invoice?.status || null;

  return status;
});

export const {
  getCustomFilter,
  getListByFilterName,
  getFilteredDropdown,
  getSelectedCount,
} = makeFilterSelectors('digitalEdits');

export const getSubmittedFiles = state =>
  _.get(state, 'digitalEdits.files.submitted', []);

const getAllUploadedFiles = state =>
  _.get(state, 'digitalEdits.files.uploaded', []);

export const getUploadedFiles = createSelector([getAllUploadedFiles], files => {
  return files.filter(f => f?.category === FILE_COMMENT_CATEGORY_MAP['None']);
});

export const getUploadedDeliveryFiles = createSelector(
  [getAllUploadedFiles],
  files => {
    return files.filter(
      f => f.category === FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery'],
    );
  },
);

export const getUploadsInProgress = state =>
  _.get(state, 'digitalEdits.uploadsInProgress', []);

export const hasAnyFileErrors = createSelector([getUploadedFiles], uploaded => {
  return uploaded.some(file => file.errors && file.errors.length > 0);
});
export const getAnyUploading = createSelector([getUploadedFiles], uploaded =>
  uploaded.some(file => file.uploading),
);

export const getNotifications = createSelector([getUploadedFiles], uploaded => {
  const errs = [];
  const fileTooLarge = uploaded.some(file =>
    file?.errors?.includes('File Too Large'),
  );
  const unsupportedFileType = uploaded.some(file =>
    file?.errors?.includes('Unsupported File Type'),
  );
  const exceededMax = uploaded.some(file =>
    file?.errors?.includes('Exceeded maximum number of files'),
  );
  const fileNameTooLong = false;
  if (fileNameTooLong) {
    errs.push({
      variant: 'error',
      type: 'file_name_too_long',
      title: 'File name(s) too long (max characters 250)',
      text: 'Remove file and re-upload with shorter name',
    });
  }
  if (fileTooLarge) {
    errs.push({
      variant: 'error',
      type: 'file_too_large',
      title: 'File size too large',
      text: 'Split file into smaller size and re-upload again',
    });
  }
  if (exceededMax) {
    errs.push({
      variant: 'error',
      type: 'max_files_count',
      title: 'Exceeded maximum number of files',
      text: 'Merge your files and try again or upload your files via ',
      studioPlusRedirect: true,
    });
  }
  if (unsupportedFileType) {
    errs.push({
      variant: 'error',
      type: 'unsupported_file_type',
      title: 'Unsupported file type',
      text: 'Convert to a supported file type or upload via ',
      studioPlusRedirect: true,
    });
  }

  return errs;
});

export const getDeliveryMethodOptions = createSelector([], () => {
  const deliveryMethods = DELIVERY_METHODS.slice();
  const isCanada = isRegionCanada();

  const selectable = isCanada
    ? CAN_SELECTABLE_DELIVERY_METHODS
    : US_SELECTABLE_DELIVERY_METHODS;
  deliveryMethods.forEach(dm => {
    if (!dm.label) {
      dm.label = 'Special Handling';
    }
    if (selectable.includes(dm.code)) {
      dm.selectable = true;
    }

    if (isCanada) {
      if (dm.labelCAN) {
        dm.label = dm.labelCAN;
      }
      if (dm.fileHelperCAN) {
        dm.fileHelper = dm.fileHelperCAN;
      }
      if (dm.notePlaceholderCAN) {
        dm.notePlaceholder = dm.notePlaceholderCAN;
      }
    }
  });

  return deliveryMethods;
});

export const getInvoiceDetails = createSelector([getInvoice], invoice => {
  const checkLabel = isRegionCanada() ? 'Cheque' : 'Check';
  const deliveryMethod = DELIVERY_METHODS.find(
    d => d.code === Number(invoice.printPriority),
  );

  if (deliveryMethod?.code === 16) {
    const region = localStorage.getItem('region');
    const mailLabel = isRegionCanada(region) ? 'Canada Post' : 'USPS Mail';
    deliveryMethod.label = mailLabel;
  }

  let deliveryLabel = deliveryMethod?.label || invoice.printPriorityDescription;

  const details = [
    { label: 'Invoice Overview', isHeader: true },
    { label: 'Timecards', value: invoice.timecards },
    { label: 'Employees', value: invoice.employees },
    { label: 'Direct Deposits', value: invoice.directDeposits },
    { label: `Physical ${checkLabel}s`, value: invoice.physicalChecks },
    { label: 'Delivery', value: deliveryLabel },
  ];
  if (invoice?.futureReleaseDate) {
    details.push({
      label: 'Invoice Release Date',
      value: customFormatDate(
        invoice.futureReleaseDate,
        'YYYY/MM/DD',
        'MM/DD/YY',
      ),
    });
  }

  const grossDetails = [
    {
      label: 'Invoice Total',
      value: invoice.invoiceTotal,
      isHeader: true,
      isCurrencyRow: true,
    },
    {
      label: 'Gross Pay',
      value: invoice.grossPay,
      isHeader: true,
      isCurrencyRow: true,
    },
    {
      label: 'Net Pay',
      value: invoice.netPay,
      isHeader: true,
      isCurrencyRow: true,
    },
    { label: 'Coordinator', value: invoice.coordinator, isHeader: true },
  ];

  return { details, grossDetails };
});

export const getDataCardVisible = createSelector(
  [getDataCardsView, (state, label) => label],
  (dataCards, label) => {
    const dataCard = dataCards.find(
      card => card.label === label || card.key === label,
    );
    if (`${label}`.toLowerCase() === 'ignore') return false;
    return dataCard?.selected;
  },
);

export const getVisibleCardCount = createSelector([getDataCardsView], cards => {
  const s = cards.filter(d => d.selected);
  return s.length;
});

export const getHideEmptyCols = state =>
  _.get(state, 'digitalEdits.hideEmptyCols', false);

export const getTaxColumnMap = state =>
  _.get(state, 'digitalEdits.taxColumnMap', {});

export const getEditReports = state =>
  _.get(state, 'digitalEdits.editReports', []);

export const sortedEditReports = createSelector(
  [getEditReports],
  editReports => {
    return editReports.length > 0
      ? _.sortBy(editReports, 'resourceId').reverse()
      : editReports;
  },
);

export const getCommentLocks = state =>
  _.get(state, 'digitalEdits.commentLocks', {});

/*
 * If current user who locks the comment is not the current user, return the lock object
 * Otherwise, return an empty object
 */
export const getCommentLockStatus = createSelector(
  [getCommentLocks, currentUser, getCurrentInvoiceId],
  (commentLocks, currentUser, invoiceId) => {
    const lock = commentLocks[invoiceId];

    if (lock && lock.email !== currentUser.email) {
      return { lockOwner: 'other', lock };
    }

    if (lock && lock.email === currentUser.email) {
      return { lockOwner: 'me', lock };
    }

    return { lockOwner: null, lock: {} };
  },
);

export const getDigitalEditsReportsLoading = state => {
  const loading = _.get(state, 'digitalEdits.loading', {});
  return loading.viewReports || false;
};

export const getEpisodes = state => _.get(state, 'digitalEdits.episodes', []);
export const getBatchesOpened = state =>
  _.get(state, 'digitalEdits.batchesOpened', false);

export const getUnsavedRecordEdits = createSelector(
  [getUnsavedEdits, (state, recordId) => recordId],
  (unsavedEdits, recordId) => {
    return unsavedEdits[recordId] || '';
  },
);

export const getPendingTasks = state =>
  _.get(state, 'digitalEdits.pendingTasks', {});

export const getAnyPendingTimecards = createSelector(
  [getPendingTasks],
  pendingTasks => {
    return !!(Object.keys(pendingTasks)?.length > 0);
  },
);

export const getHasPendingTimecards = createSelector(
  [getPendingTasks, (state, recordId) => recordId],
  (pendingTasks, recordId) => {
    let hasPendingTimecards = false;
    Object.keys(pendingTasks).forEach(taskId => {
      const pendingTask = pendingTasks[taskId];
      const pendingTimecards = pendingTask?.timecards || {};

      const timecardEntryHeaderIds = getTcHeadersFromRecordId(recordId);

      if (
        timecardEntryHeaderIds.some(headerId => !!pendingTimecards[headerId])
      ) {
        hasPendingTimecards = true;
      }
    });
    return hasPendingTimecards;
  },
);

export const getHasUnsavedErrors = createSelector(
  [getErrorHeaderIds, (state, recordId) => recordId],
  (errorHeaderIds, recordId) => {
    const headerIds = getTcHeadersFromRecordId(recordId);

    const hasUnsavedError = errorHeaderIds.some(headerId =>
      headerIds.includes(headerId),
    );
    return hasUnsavedError;
  },
);
export const getHasTimeoutErrors = createSelector(
  [getTimeoutHeaderIds, (state, recordId) => recordId],
  (timeoutHeaderIds, recordId) => {
    const headerIds = getTcHeadersFromRecordId(recordId);

    const hasTimeout = timeoutHeaderIds.some(headerId =>
      headerIds.includes(headerId),
    );

    return hasTimeout;
  },
);

export const getHasErrors = createSelector(
  [getRecordArray, (state, recordId) => recordId],
  (records, recordId) => {
    const record = records.find(r => r.recordId === recordId);
    return record?.hasErrors || false;
  },
);

export const getHasAnyError = createSelector(
  [getRecordArray, getErrorHeaderIds, getTimeoutHeaderIds],
  (records, errors, timeouts) => {
    const allErrors = errors.concat(timeouts);
    if (allErrors.length > 0) {
      return true;
    }

    let anyError = false;

    records.forEach(record => {
      if (record.hasErrors) {
        anyError = true;
      }
    });
    return anyError;
  },
);

export const getTriggerSplitRevert = state =>
  _.get(state, 'digitalEdits.triggerSplitRevert', 0);
