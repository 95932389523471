import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableHeader from './TableHeader';
import { TABLE_COLUMN_HEADERS } from './moveTCutils';
import { isInstantDealTimecard } from 'utils/wtcWeekUtils';
import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  LinearProgress,
  Table,
} from '@mui/material';

import { Field } from 'redux-form';
import clsx from 'clsx';
import { sortTimecards, getStatusBadge } from 'utils/helperFunctions';
import SearchFilter from './SearchFilter';
import { moveTcsFilterHandler } from './moveTCutils';
import InstantDealIcon from 'components/Shared/InstantDealIcon';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  evenRow: {
    backgroundColor: palette.primary.table,
  },
  table: {
    display: 'block',
    overflowY: 'scroll',
  },
  tCell: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 5,
    padding: 0,
    paddingRight: 4,
    borderBottom: `1px solid ${palette.primary['+8']}`,
    '&>label': {
      marginLeft: '0px',
      marginRight: '5px',
    },
  },
  instantDeal: {
    display: 'flex',
    alignItems: 'center',
    gap: '0px 10px',
  },
}));

const MoveTimecardsTable = props => {
  const {
    batchTimecards,
    change,
    moveLoading,
    isUPM,
    isPA,
    userLevel,
    timecardIds,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = useState('default');
  const [orderBy, setOrderBy] = useState('name');
  const [timecardList, setTimecardList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const tcs = sortTimecards(batchTimecards, 'ascSort', 'name');
    setTimecardList(tcs);
    setSearchValue('');
  }, [batchTimecards]);

  //sorting
  const handleRequestSort = (e, property) => {
    let sortOrd = order;
    if (property !== orderBy) {
      sortOrd = 'ascSort';
    } else if (order === 'ascSort') {
      sortOrd = 'decSort';
    } else if (order === 'decSort') {
      sortOrd = 'default';
    } else if (order === 'default') {
      sortOrd = 'ascSort';
    }
    setOrder(sortOrd);
    setOrderBy(property);
    setTimecardList(sortTimecards(timecardList, sortOrd, property));
  };

  const renderCheckbox = ({ input }) => (
    <Checkbox
      checked={input.value ? true : false}
      onChange={input.onChange}
      color="primary"
    />
  );

  const contentRows = timecardList?.map((tc, index) => {
    const isInstantDeal = isInstantDealTimecard(tc);
    return (
      <TableRow
        key={tc.timecardId}
        className={clsx(classes.row, {
          [classes.evenRow]: index % 2 === 0,
        })}
      >
        <TableCell className={clsx(classes.tCell, classes.instantDeal)}>
          <Field
            component={renderCheckbox}
            name={`timecardIds.${tc.entryHeaderId}`}
            id={tc.entryHeaderId}
            index={index}
          />
          {isInstantDeal && <InstantDealIcon />}
        </TableCell>
        <TableCell className={classes.tCell}>
          {tc?.timecard.user?.fullName}
        </TableCell>
        <TableCell className={classes.tCell}>{tc?.departmentName}</TableCell>
        <TableCell className={classes.tCell}>
          {getStatusBadge(
            tc?.userFriendlyTimecardStatus,
            isPA,
            isUPM,
            userLevel,
          )}
        </TableCell>
      </TableRow>
    );
  });

  const handleSelectAll = e => {
    timecardList.forEach(tc => {
      change(`timecardIds.${tc.entryHeaderId}`, e.target.checked);
    });
  };
  const onApplyFiltering = e => {
    const tcsList = sortTimecards(batchTimecards, order, orderBy);
    setSearchValue(e.target.value);
    let filteredTcs = moveTcsFilterHandler(e, tcsList);
    setTimecardList(filteredTcs);
  };

  return (
    <Paper className={classes.root}>
      <SearchFilter
        onApplyFiltering={onApplyFiltering}
        searchValue={searchValue}
      />
      {moveLoading.timecards && <LinearProgress />}
      <Table className={classes.table}>
        <TableHeader
          columns={TABLE_COLUMN_HEADERS}
          onSelectAll={handleSelectAll}
          timecardIds={timecardIds}
          timecardList={timecardList}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>{contentRows}</TableBody>
      </Table>
    </Paper>
  );
};
export default MoveTimecardsTable;
