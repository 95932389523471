import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { useLocation, Redirect, useParams } from 'react-router-dom';

//components
import Loader from 'components/Loader/Loader';
//selector
import {
  isUserInfoLoaded as getUserLoading,
  getCurrentUserRole,
  getSessionUser,
  getProjUsers,
} from 'selectors/session';
import { getHideGlobalNav } from 'selectors/appReady';

import { getProject } from 'selectors/routeParams';
import { LANDING_PAGE_BY_ROLE } from 'components/Shared/constants';
//actions
import { setViewingYear } from 'actions/project';
import { hideAlert } from 'actions/alert';
import { setHideGlobalNav } from 'actions/appReady';
import { storeProject } from 'actions/routeParams';

//utils
import { IA, PROJECT_ADMIN } from 'components/props/profiles';
import { db } from 'utils/helperFunctions';
import { loggedInUserProps } from 'components/props/users';

import { checkShouldRedirect } from 'components/Routes/routeUtil';
import QuickRedirect from './QuickRedirect';

const mapState = state => ({
  isProjUserLoaded: getUserLoading(state),
  userRole: getCurrentUserRole(state),
  projectId: getProject(state),
  projUserInfo: getProjUsers(state),
  loggedInUser: getSessionUser(state),
  hideGlobalNav: getHideGlobalNav(state),
});

const mapDispatch = dispatch => ({
  onSetViewingYear: viewingYear => dispatch(setViewingYear({ viewingYear })),
  onHideAlert: () => dispatch(hideAlert()),
  onSetHideGlobalNav: hideGlobalNav =>
    dispatch(setHideGlobalNav({ hideGlobalNav })),
  onStoreProject: projectId =>
    dispatch(storeProject({ projectId: `${projectId}` })),
});

const RedirectCheck = props => {
  const {
    //props
    headers,
    component: Component,
    layout: Layout,
    path,
    title = '',
    noCnCHeader = false,
    waitForUserToLoad = false, //used to be isLoadProjectActiveUser

    //mapped
    isProjUserLoaded,
    userRole,
    projectId,
    projUserInfo,
    loggedInUser,
    hideGlobalNav,

    //dispatched

    onSetViewingYear,
    onHideAlert,
    onSetHideGlobalNav,
    onStoreProject,
  } = props;
  db('path: ', path);

  React.useEffect(() => {
    if (hideGlobalNav !== noCnCHeader) {
      onSetHideGlobalNav(noCnCHeader);
    }
  }, [hideGlobalNav, onSetHideGlobalNav, noCnCHeader]);

  const URL_ACCESS_ADMIN = '/admin/accessadmin';
  const URL_ADMIN = '/admin/projects';
  const URL_SUPER_ADMIN = '/admin/superadmin';

  const {
    isAdmin = false,
    isAccessAdmin = false,
    isSuperAdmin = false,
    projectRoles = [],
  } = loggedInUser;

  const isIA = projectRoles.includes(IA) || false;
  const isProjAdmin = projectRoles.includes(PROJECT_ADMIN) || false;
  const isAdminPath = path?.includes('/admin') || false;
  const isProjectPath = path?.includes('/:projectId/') || false;

  //dismiss alert on navigation
  React.useEffect(() => {
    onHideAlert();
  });

  React.useEffect(() => {
    if (title) {
      document.title = `Hours+  ${title}`;
    } else {
      document.title = 'Hours+';
    }
  }, [title]);

  const location = useLocation();
  const params = useParams();

  const { search, pathname } = location;
  // pathname == from URL /projects/1234
  // path is /projects/:projectId

  // role is IA when user is invoice approver ONLY. otherwise its in the roles array
  // const isOnlyIA = userRole === IA; not used anymore, but good info so keeping comment

  const projectIdMisMatch =
    params.projectId && `${projectId}` !== `${params.projectId}`;

  React.useEffect(() => {
    if (projectIdMisMatch) {
      db('Updating Project ID from URL ', params.projectId);
      onStoreProject(params.projectId);
    }
  }, [onStoreProject, params.projectId, projectIdMisMatch]);

  let year = null;
  const query = new URLSearchParams(search);

  if (query.has('year')) {
    year = query.get('year');
    year = Number(year);

    const currentYear = new Date().getFullYear();

    if (year > 2020 && year <= currentYear) {
      //year valid, continue
    } else {
      console.warn(`Invalid year param (${year})`);
      year = null;
    }
  }

  React.useEffect(() => {
    if (year !== null) {
      //this needs to be in an effect to update without being yelled at by react since it triggers an update
      onSetViewingYear(year);
    }
  }, [onSetViewingYear, year]);

  if (year) {
    query.delete('year');
    db(`Redirecting to updated year: ${year}`);
    return <Redirect to={`${pathname}?${query.toString()}`} />;
  }

  if (projectIdMisMatch) {
    db(`Redirecting to updated projectId: ${params.projectId}`);
    return <Redirect to={`${pathname}?${query.toString()}`} />;
  }

  //redirect logic
  let redirectUrl = LANDING_PAGE_BY_ROLE(userRole, projectId);

  let renderComponent = false;
  //access admin routes
  if (isAccessAdmin) {
    if (!path.includes('/admin/accessadmin')) {
      db(`Redirecting1 from ${path} to ${redirectUrl}`);
      return <QuickRedirect path={path} redirect={URL_ACCESS_ADMIN} />;
    }
    renderComponent = true;
  }
  //only admin can access these routes
  else if (isAdmin) {
    //includes super admin permissions
    if (isSuperAdmin) {
      const superAdminPaths = [
        '/admin/projects',
        '/admin/users-all-projects',
        '/admin/superadmin',
        '/admin/feature-flags',
      ];
      if (!superAdminPaths.some(p => path.includes(p))) {
        db(`Redirecting2 from ${path} to ${redirectUrl}`);
        return <QuickRedirect path={path} redirect={URL_ADMIN} />;
      }
      renderComponent = true;
    } else if (
      !path.includes('/admin/projects') &&
      !path.includes('/admin/users-all-projects')
    ) {
      db(`Redirecting3 from ${path} to ${redirectUrl}`);
      return <QuickRedirect path={path} redirect={URL_ADMIN} />;
    }
    renderComponent = true;
  } else if (isSuperAdmin) {
    //only super admin can access these routes
    if (
      !path.includes('/admin/superadmin') &&
      !path.includes('/admin/feature-flags')
    ) {
      db(`Redirecting4 from ${path} to ${redirectUrl}`);
      return <QuickRedirect path={path} redirect={URL_SUPER_ADMIN} />;
    }
    renderComponent = true;
  }
  if (renderComponent) {
    return (
      <Layout headers={headers} path={path}>
        <React.Suspense
          fallback={
            <div style={{ height: '50vh' }}>
              <Loader />
            </div>
          }
        >
          <Component />
        </React.Suspense>
      </Layout>
    );
  }

  if (isAdminPath && !isAdmin && !isProjectPath) {
    //projectPath check is needed to keep from immediately redirecting
    // to project page when starting impersonation
    db(`Redirecting5 from ${path} to ${redirectUrl}`);
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  if (isProjUserLoaded) {
    if (isAdminPath && !isAdmin) {
      db(`Redirecting6 from ${path} to ${redirectUrl}`);
      return <Redirect to={{ pathname: redirectUrl }} />;
    }

    const projectUser = projUserInfo[projectId] || {};
    const { workSightId, worksightId } = projectUser;
    const hasWorksight = !!worksightId || !!workSightId;

    if (projectUser && projectUser.inviteStatus) {
      const inviteStatus = projectUser.inviteStatus;
      const isUserDeleted = projectUser.deletedAt !== null;
      // redirect user to invitation page if they haven't validated their SSN.
      if (inviteStatus === 'invited') {
        if (projectUser.invitationToken === null) {
          db(`Redirecting7 from ${path} to ${redirectUrl}`);
          return <Redirect to={'/projects'} />;
        }
        if (
          path !== '/projects' && //user may have other projects not in 'invited' status
          path !== '/' &&
          path !== '/invitations/:token' // or if they're on the right path already
        ) {
          const token = projectUser.invitationToken;
          const inviteURL = `/invitations/${token}`;
          db(`Redirecting8 from ${path} to ${redirectUrl}`);
          return <Redirect to={inviteURL} />;
        }
      } else if (
        inviteStatus === 'accepted' &&
        path === '/invitations/:token'
      ) {
        db(`Redirecting9 from ${path} to ${redirectUrl}`);
        return <Redirect to={'/'} />;
      } else if (isUserDeleted) {
        if (projectUser.invitationToken !== null) {
          // redirect deleted users to the invitation page if they have an invite token
          const token = projectUser.invitationToken;
          const inviteURL = `/invitations/${token}`;
          if (path !== '/invitations/:token') {
            db(`Redirecting10 from ${path} to ${redirectUrl}`);
            return <Redirect to={inviteURL} />;
          }
        }
        if (path.includes('/projects/')) {
          //deleted users should only be able to visit these urls:
          const allowedUrlList = [
            '/projects/:projectId/me/timecards',
            '/projects/:projectId/me/timecards/:timecardId',
            '/invitations/:token',
          ];
          if (allowedUrlList.includes(path) === false) {
            db(`Redirecting11 from ${path} to ${redirectUrl}`);
            return <Redirect to={'/'} />;
          }
        }
      }
    }

    if (path === '/projects/:projectId/settings' && !isProjAdmin) {
      db(`Redirecting12 from ${path} to ${redirectUrl}`);
      return <Redirect to={redirectUrl} />;
    }

    const shouldRedirect = checkShouldRedirect({
      path,
      userRole,
      isIA,
      hasWorksight,
    });

    if (shouldRedirect) {
      db(`Redirecting13 from ${path} to ${redirectUrl}`);
      return <Redirect to={{ pathname: redirectUrl }} />;
    }
  }

  const renderComp = waitForUserToLoad && !isProjUserLoaded ? false : true;
  return (
    <Layout headers={headers} path={path}>
      <React.Suspense
        fallback={
          <div style={{ height: '50vh' }}>
            <Loader />
          </div>
        }
      >
        {renderComp && <Component />}
      </React.Suspense>
    </Layout>
  );
};

RedirectCheck.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  layout: PropTypes.func.isRequired,
  loggedInUser: loggedInUserProps,
  isProjUserLoaded: PropTypes.bool,
  userRole: PropTypes.string,
  projectId: PropTypes.string,
  projUserInfo: PropTypes.object,
  hideGlobalNav: PropTypes.bool,
  onSetViewingYear: PropTypes.func.isRequired,
  onHideAlert: PropTypes.func.isRequired,
  onSetHideGlobalNav: PropTypes.func.isRequired,
  title: PropTypes.string,
  noCnCHeader: PropTypes.bool,
  waitForUserToLoad: PropTypes.bool,
  onStoreProject: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(RedirectCheck);
