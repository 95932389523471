import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  InvalidDeal: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    verticalAlign: 'top',
  },
}));
const InvalidDealIcon = props => {
  const {
    tooltipText = 'Invalid offer',
    label = '',
    color = 'error.main',
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.InvalidDeal}>
      <Box>
        <Tooltip title={tooltipText}>
          <span>
            <ErrorOutlineOutlinedIcon
              sx={{ color, width: 20, verticalAlign: 'top' }}
            />
          </span>
        </Tooltip>
      </Box>
      {label && (
        <Box sx={{ color: color, fontWeight: 500, ml: '10px' }}>{label}</Box>
      )}
    </Box>
  );
};
InvalidDealIcon.propTypes = {
  tooltipText: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
};

export default InvalidDealIcon;
