import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import emergencyIcon from 'assets/icons/emergency.png';
import resubmitIcon from 'assets/icons/ReSubmitIcon.png';

import { OutlinedIconBadge } from 'components/Shared/Text';
import moment from 'moment';

//constants
import {
  labelToStatusMap,
  TIMECARD_INCOMPLETE,
  TIMECARD_REJECTED,
} from 'components/Shared/constants';
import DeleteIcon from '@mui/icons-material/Delete';

export const EmergencyIcon = ({ className }) => (
  <div>
    <Tooltip title="This is an Emergency Timecard and does not require the employee's signature.">
      <img className={className} alt="Emergency" src={emergencyIcon} />
    </Tooltip>
  </div>
);

export const ForceResubmitIcon = ({ className }) => (
  <div>
    <Tooltip title="This timecard’s times or days have been changed. It needs to be sent back to the employee for a signature.">
      <img className={className} alt="ReSubmit" src={resubmitIcon} />
    </Tooltip>
  </div>
);

export const getStatusBadgeV2 = timecard => {
  const label = timecard.status;
  const status = labelToStatusMap(label) || 'warning';

  return <OutlinedIconBadge status={status}>{label}</OutlinedIconBadge>;
};

export const currentApprovers = currentApproversStr => {
  return currentApproversStr ? currentApproversStr.split('~') : [];
};

export const getDeleteIcon = (timecard, handleDelete, label = '') => {
  const statusLowercase = timecard.status.toLowerCase();
  return statusLowercase === TIMECARD_INCOMPLETE ||
    statusLowercase === TIMECARD_REJECTED ? (
    <IconButton
      color="primary"
      onClick={e => handleDelete(e, timecard.timecardId)}
      size="large"
    >
      <DeleteIcon style={{ paddingRight: 5 }} />
      <span style={{ fontSize: 14, height: 14, fontWeight: '500' }}>
        {label}
      </span>
    </IconButton>
  ) : (
    <div> </div>
  );
};

/**
 * Go through the current week based on weekEnding and if an employees deal memo
 * is invalid, mark in return obj, both ways
 * @param {string} weekEnding
 * @param {obj} timecard
 * @param {array} timecard.users
 * @returns {obj} if a day is invalid, it will be a key with the invalid userId, and vis versa.
 * {
 *   ID: 'DATE' ,
 *   DATE: ID
 * }
 *
 * if one date has multiple invalid users, only will be listed
 * Same if one user has multiple invalid dates
 *
 */
export const getCrewPartialDays = ({ timecard }) => {
  const activeDays = {};
  if (!timecard) return {};

  if (timecard.days && Array.isArray(timecard.days)) {
    timecard.days.forEach(day => {
      const dateStr = day.date.split('T')[0];
      const dateMoment = moment.utc(dateStr);
      const date = dateMoment.format('MM-DD-YYYY');
      activeDays[date] = !!day.isActive;
    });
  }

  const timecardUsers = timecard.users;
  if (!timecardUsers) return {};

  const partialDays = {};
  Object.keys(activeDays).forEach(currentDay => {
    //day not active in calendar, skip
    if (activeDays[currentDay] === false) return;
    const currMoment = moment.utc(currentDay);
    timecardUsers.forEach(user => {
      const memoStart = moment.utc(user.dealMemo.startsOn);
      const memoEnd = moment.utc(user.dealMemo.endsOn);

      if (memoStart.isAfter(currMoment) || memoEnd.isBefore(currMoment)) {
        partialDays[currentDay] = user.userId;
        partialDays[user.userId] = currentDay;
      }
    });
  });

  return partialDays;
};

export const checkTCSelection = (
  tc,
  userLevel,
  bulkAction,
  isUPM,
  isPA,
  isDealInvalidOrInstant,
) => {
  let selection = false;
  if (
    (isUPM && tc.approvalFlowLevel === userLevel) ||
    (isPA &&
      !isDealInvalidOrInstant &&
      tc.workflowStatus === 'pending_pa_review' &&
      !tc.workflowAction &&
      ((tc.hasTimecardErrors && bulkAction !== 'approve') ||
        !tc.hasTimecardErrors) &&
      ((tc.emergencyType && bulkAction !== 'reject') || !tc.emergencyType))
  ) {
    selection = true;
  }

  return selection;
};
