import React from 'react';
import _ from 'lodash';
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Switch,
  FormControlLabel,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BatchRow from './BatchRow';

import MoveTimecardsModal from '../Modal/MoveTimecardsModal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    paddingTop: 0,
  },
  tableHead: {
    fontWeight: 700,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableFirstCell: {
    fontWeight: 700,
    paddingLeft: 75,
    width: '44.7%',
  },
  blankSlate: {
    fontSize: '2.14285714em',
    background: palette.background.default,
    color: palette.text.disabled,
    fontWeight: 700,
    marginTop: 2,
    padding: 10,
    textAlign: 'center',
  },
  paging: {
    marginRight: 30,
    fontSize: '14px',
  },
  showPaid: {
    color: palette.text.primary,
    fontSize: 14,
    float: 'right',
  },
}));

export default function Listing({
  batches,
  timecardLoadingByBatch,
  onApproveBatch,
  onWTCApproveBatch,
  onBulkEditBatch,
  onDownloadTimecardsReport,
  onRefreshPage,
  onFetchBatchTimecards,
  page,
  pageSize,
  projectId,
  projectDetails,
  reviewType,
  settings,
  onSetBatchLockStatus,
  onNavToWTC,
  loading,
  showPaid,
  onTogglePaid,
}) {
  const classes = useStyles();
  let totalCount = Object.keys(batches).length;

  const hasUnsignedOfferCount = Object.keys(batches).some(
    batchKey => batches[batchKey].details.unsignedOfferCount > 0,
  );
  const hasUnsignedOffer = reviewType === 'open' && hasUnsignedOfferCount;

  if (!!batches && totalCount > 0) {
    const details = batches[Object.keys(batches)[0]].details;
    totalCount =
      details && details.pagination ? details.pagination.total : totalCount;
  }

  const isPAHistoryTab = reviewType === 'complete';

  function handleChangeRowsPerPage(event) {
    onRefreshPage(1, event.target.value);
  }

  function handleChangePage(event, newPage) {
    onRefreshPage(newPage + 1, pageSize);
  }

  function renderBatches() {
    let batchRows = null;

    if (totalCount <= 0) {
      const noBatchText = loading ? 'Batches loading...' : 'No batches';
      batchRows = (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} className={classes.blankSlate}>
                {isPAHistoryTab && (
                  <section className={classes.showPaid}>
                    <FormControlLabel
                      label="Show paid"
                      labelPlacement="start"
                      control={
                        <Switch
                          color="primary"
                          onChange={onTogglePaid}
                          checked={showPaid}
                        />
                      }
                    />
                  </section>
                )}

                {noBatchText}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      batchRows = (
        <div>
          {loading && <LinearProgress />}
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableFirstCell}>
                  Batch Name
                </TableCell>
                {hasUnsignedOffer && (
                  <TableCell className={classes.tableHead}></TableCell>
                )}
                <TableCell className={classes.tableHead}>
                  # of Timecards
                </TableCell>
                <TableCell>
                  {isPAHistoryTab && (
                    <section className={classes.showPaid}>
                      <FormControlLabel
                        label="Show paid"
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            onChange={onTogglePaid}
                            checked={showPaid}
                          />
                        }
                      />
                    </section>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.sortBy(batches, batch => batch.endsOn)
                .reverse()
                .map(batch => (
                  <BatchRow
                    key={`${batch.details.htgId}_${batch.details.id}`}
                    batch={batch}
                    projectId={projectId}
                    reviewType={reviewType}
                    onApproveBatch={onApproveBatch}
                    onWTCApproveBatch={onWTCApproveBatch}
                    onBulkEditBatch={onBulkEditBatch}
                    onNavToWTC={onNavToWTC}
                    onFetchBatchTimecards={onFetchBatchTimecards}
                    esignatureEnabled={
                      !!settings ? settings.esignatureEnabled : false
                    }
                    onDownloadTimecardsReport={onDownloadTimecardsReport}
                    onSetBatchLockStatus={onSetBatchLockStatus}
                    timecardLoadingByBatch={timecardLoadingByBatch}
                    page={page}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            className={classes.paging}
            component="div"
            rowsPerPageOptions={[20, 50, 100]}
            count={totalCount}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <MoveTimecardsModal />
        </div>
      );
    }

    return batchRows;
  }

  return (
    <Paper elevation={0} square className={classes.root}>
      {renderBatches()}
    </Paper>
  );
}
