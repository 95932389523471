import PropTypes from 'prop-types';

export const capModal = 'CapTrackingModal';
export const deleteCapModal = 'CapTrackingDeleteModal';

export const wtcCapVar = 'wtcCapVar';
export const dtsCapVar = 'dtsCapVar';
export const myTcCapVar = 'myTcCapVar';

//props
export const capItemProps = PropTypes.shape({
  projectId: PropTypes.string.isRequired,
  employeeId: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  runningTotal: PropTypes.number,
  paidAmount: PropTypes.number.isRequired,
  capAmount: PropTypes.number.isRequired,
  capModifiedBy: PropTypes.string.isRequired,
  capModifiedDate: PropTypes.string.isRequired,
  modifiedBy: PropTypes.string.isRequired,
  modifiedDate: PropTypes.string.isRequired,
});
