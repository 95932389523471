import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import TimecardRow from './TimecardRow';
import TableList from 'components/Shared/TableList/TableList';
import { TableRow, TableCell, Typography } from '@mui/material';
import { useIsMobile } from 'utils/customHooks';
import { DH, UPM } from 'components/props/profiles';

const useStyles = makeStyles(({ palette }) => ({
  emptyRow: {
    color: palette.text.disabled,
    padding: 24,
  },
}));

export default function TimecardSection({
  timecards,
  readOnly = false,
  enableGross = false,
  canSubmitAsRejected = false,
  showTimecard,
  selected = [],
  handleSelect,
  handleSelectAll,
  onViewTimecard,
  onViewApprovalFlow,
  reviewType,
  role,
  batch,
  displayComment,
  orderBy,
  setSortBy,
  direction,
  setDirection,
  timecardLoadingByBatch,
  hasUnsignedOffer,
}) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const sortParams = useMemo(() => {
    return { orderBy, direction };
  }, [orderBy, direction]);

  const showCheck = reviewType === 'ready' || canSubmitAsRejected;
  const showApprovers =
    (reviewType === 'history' || reviewType === 'pending') &&
    (role === DH || role === UPM);
  function onApplySorting(property, dir) {
    setSortBy(property);
    setDirection(dir);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  const headers = [
    {
      columnId: 'emergencyType',
      label: '',
      compact: true,
      sortable: false,
    },
    { columnId: 'selectAll', label: '', isCheck: true },
    {
      columnId: 'userAccountCode',
      label: 'Account Code',
      compact: true,
      sortable: true,
    },
    { columnId: 'employee', label: 'Employee', compact: true, sortable: true },
    {
      columnId: 'occupationCode',
      label: 'Occupation',
      compact: true,
      sortable: true,
    },
    {
      columnId: 'department',
      label: 'Departments',
      compact: true,
      sortable: true,
    },
    {
      columnId: 'status',
      label: 'Status',
      compact: true,
      sortable: true,
      hidden: !readOnly,
    },
    {
      columnId: 'currentApprover',
      label: 'Current Approver',
      compact: true,
      hidden: !showApprovers,
    },
    {
      columnId: 'totalAllowances',
      label: 'Allowances',
      compact: true,
      sortable: true,
      align: 'center',
    },
    {
      columnId: 'totalHours',
      label: 'Total Hours',
      compact: true,
      sortable: true,
      align: 'center',
    },
    {
      columnId: 'totalGrossWage',
      label: 'Total Gross Wages',
      compact: true,
      sortable: true,
      hidden: !enableGross,
    },
    { columnId: 'actions', label: 'Actions', compact: true, align: 'center' },
  ];
  function showReviewTimecard(batch, record) {
    if (reviewType === 'ready') {
      onViewApprovalFlow(batch, record);
    } else {
      onViewTimecard(batch, record);
    }
  }

  function onShowComment(record) {
    displayComment(record);
  }
  let filteredRowsLength = (timecards || []).filter(
    n => n.status !== 'draft',
  ).length;

  let contentRows = timecards.map(record => {
    const rowSelected = isSelected(record.entryHeaderId);
    return (
      <TimecardRow
        key={record.id}
        record={record}
        readOnly={readOnly}
        enableGross={enableGross}
        showTimecard={showTimecard}
        onSelect={() => handleSelect(record.entryHeaderId)}
        isSelected={rowSelected}
        role={role}
        canSubmitAsRejected={canSubmitAsRejected}
        reviewTimecard={() => showReviewTimecard(batch, record)}
        showComment={() => onShowComment(record)}
        showApprovers={showApprovers}
        batch={batch}
        reviewType={reviewType}
      />
    );
  });

  const areTimecardsLoading =
    timecardLoadingByBatch && timecardLoadingByBatch[batch.id];

  if (contentRows.length === 0) {
    const contentText = areTimecardsLoading
      ? 'Loading timecards...'
      : 'There are currently no timecards in this batch';
    contentRows = (
      <TableRow>
        <TableCell colSpan={headers.length} align="center">
          <Typography variant="h4" className={classes.emptyRow}>
            {contentText}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {!isMobile && (
        <React.Fragment>
          <TableList
            headers={headers}
            onApplySorting={onApplySorting}
            sortParams={sortParams}
            checkIndeterminate={
              selected.length > 0 && selected.length < filteredRowsLength
            }
            showCheck={showCheck}
            selectAll={selected.length === filteredRowsLength}
            onSelectAll={handleSelectAll}
            overflow
            hasUnsignedOffer={hasUnsignedOffer}
          >
            {contentRows}
          </TableList>
        </React.Fragment>
      )}
      {isMobile && (
        <React.Fragment>
          <TableList headers={[]} overflow>
            {contentRows}
          </TableList>
        </React.Fragment>
      )}
    </>
  );
}

TimecardSection.propTypes = {
  timecards: PropTypes.array,
  readOnly: PropTypes.bool,
  enableGross: PropTypes.bool,
  canSubmitAsRejected: PropTypes.bool,
  showTimecard: PropTypes.func,
  selected: PropTypes.array,
  handleSelect: PropTypes.func,
  handleSelectAll: PropTypes.func,
  onViewTimecard: PropTypes.func,
  onViewApprovalFlow: PropTypes.func,
  reviewType: PropTypes.string,
  role: PropTypes.string,
  batch: PropTypes.object,
  displayComment: PropTypes.func,
  orderBy: PropTypes.string,
  setSortBy: PropTypes.func,
  direction: PropTypes.string,
  setDirection: PropTypes.func,
  timecardLoadingByBatch: PropTypes.object,
};
