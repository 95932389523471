import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  Box,
  Divider,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import CapTrackingLegend from './CapTrackingLegend';

import { capItemProps } from './capUtils';

const useStyles = makeStyles(({ palette }) => ({
  CapTrackingItem: {
    height: '78px',
    width: '270px',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 12px',
    gap: '4px',
  },
  menuList: {
    '& .MuiPaper-root': {
      borderRadius: '7px',
    },
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  menuItem: {
    paddingLeft: '5px',
    fontSize: '14px',
    fontWeight: '500',
  },
  amountTextField: {
    width: '60px',
    borderRadius: '30px',
    marginLeft: '3px',
    marginRight: '3px',
    border: `${palette.gray['+8']} 1px solid`,
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },
    '& .MuiInputBase-input': {
      fontSize: '11px',
      padding: '0px',
      textAlign: 'center',
      fontWeight: '500',
    },
  },
  bottomHalf: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  gaugeBox: {
    flexGrow: 0,
    flexShrink: 0,
    height: '30px',
    width: '65px',
    display: 'flex',
    alignItems: 'center',
  },
  actionIcon: {
    display: 'flex',
    gap: '4px',
  },
}));

const CapTrackingItem = props => {
  const classes = useStyles();
  const {
    capItem = {},
    canEdit = true,
    onSave,
    onShowDeleteModal,
    actionDisabled,
    gaugeOnly,
  } = props;

  const { capAmount, paidAmount, category } = capItem;

  const categoryLabel = category.replace(/([a-z])([A-Z])/g, '$1 $2');

  const [value, setValue] = React.useState(-1);
  const [valueMax, setValueMax] = React.useState(100);
  const [percentage, setPercentage] = React.useState(0);
  const [overPaid, setOverPaid] = React.useState(0);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [editValue, setEditValue] = React.useState(`${capAmount}`);

  const menuRef = React.useRef(null);
  const textRef = React.useRef(null);
  const theme = useTheme();

  const isOverPaid = paidAmount > capAmount;
  const isWarning = percentage >= 80;
  const capLabel = capAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  React.useEffect(() => {
    if (isOverPaid) {
      setValue(capAmount);
      setValueMax(paidAmount);
      setOverPaid(paidAmount - capAmount);
      if (capAmount === 0) {
        const percent = paidAmount.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        setPercentage(percent);
      } else {
        const percent = ((paidAmount / capAmount) * 100).toLocaleString(
          'en-US',
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        );
        setPercentage(percent);
      }
    } else {
      setValue(paidAmount);
      setValueMax(capAmount);
      if (capAmount === 0) {
        const percent = paidAmount.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        setPercentage(percent);
      } else {
        const percent = ((paidAmount / capAmount) * 100).toLocaleString(
          'en-US',
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        );
        setPercentage(percent);
      }
    }
    setEditing(false);
  }, [capAmount, isOverPaid, overPaid, paidAmount]);

  const colors = React.useMemo(() => {
    return {
      lightGray: theme.palette.grey[200],
      blue: theme.palette.primary['+3'],
      red: theme.palette.badge.red.main,
      lightRed: theme.palette.badge.red.light,
      warning: theme.palette.warning.dark,
    };
  }, [theme]);

  const handleEdit = React.useCallback(() => {
    setEditValue(`${capAmount}`);
    setEditing(true);
    setMenuOpen(false);
    setTimeout(() => {
      textRef.current.select();
    }, 150);
  }, [capAmount]);

  const handleDelete = React.useCallback(() => {
    onShowDeleteModal(capItem);
    setMenuOpen(false);
  }, [capItem, onShowDeleteModal]);

  const handleSave = React.useCallback(() => {
    const updatedCap = Number(editValue) || 0;

    const updatedItem = { ...capItem, capAmount: updatedCap };

    onSave(updatedItem);
  }, [capItem, editValue, onSave]);

  const savingRef = React.useRef(null);
  React.useEffect(() => {
    if (!actionDisabled && editing && savingRef.current) {
      setEditing(false);
    }

    savingRef.current = actionDisabled;
  }, [editing, actionDisabled]);

  const handleCancel = React.useCallback(() => {
    setEditing(false);
  }, []);

  const handleAmountChange = React.useCallback(e => {
    const regex = /^\d{0,5}(\.\d{0,2})?$/;

    if (regex.test(e.target.value)) {
      setEditValue(e.target.value);
    }
  }, []);
  const paid = paidAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const gaugeBox = (
    <Tooltip
      title={gaugeOnly ? `${paid} of ${capLabel}` : null}
      placement="top"
      arrow
    >
      <Box className={classes.gaugeBox}>
        <Gauge
          value={value}
          valueMax={valueMax}
          text={`${percentage}%`}
          sx={{
            [`& .${gaugeClasses.root}`]: {
              padding: 0,
            },
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 10,
              fontWeight: 'bold',
              transform: 'translate(0px, -8px)',
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: isOverPaid
                ? colors.red
                : isWarning
                ? colors.warning
                : colors.blue,
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: isOverPaid
                ? colors.lightRed
                : isWarning
                ? colors.lightGray
                : colors.lightGray,
            },
          }}
          cornerRadius="50%"
          height={55}
          startAngle={-90}
          endAngle={90}
        />
      </Box>
    </Tooltip>
  );

  if (gaugeOnly) {
    return gaugeBox;
  }

  return (
    <Paper className={classes.CapTrackingItem}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>{categoryLabel}</Box>
          <Box sx={{ display: 'flex', fontSize: 12 }}>
            {`Cap:`}{' '}
            {editing ? (
              <TextField
                className={classes.amountTextField}
                value={editValue || ''}
                inputProps={{ ref: textRef, name: 'capAmount' }}
                onChange={handleAmountChange}
                disabled={actionDisabled}
                onKeyUp={e => {
                  if (e.key === 'Enter' && editValue !== capAmount.toString()) {
                    handleSave();
                  } else if (e.key === 'Escape') {
                    handleCancel();
                  }
                }}
              />
            ) : (
              <Box>{`${capLabel}`}</Box>
            )}
          </Box>
        </Box>
        {canEdit && (
          <>
            {editing ? (
              <Box className={classes.actionIcon}>
                <IconButton
                  disabled={actionDisabled}
                  sx={{
                    color: 'badge.red.main',
                    backgroundColor: 'badge.red.light',
                    border: 'solid 1px',
                    borderColor: 'badge.red.main',
                    p: 0,
                    height: 24,
                    width: 24,
                  }}
                  onClick={handleCancel}
                >
                  <ClearIcon sx={{ height: 20, width: 20 }} />
                </IconButton>
                <IconButton
                  disabled={
                    actionDisabled ||
                    !editValue ||
                    editValue === capAmount.toString()
                  }
                  sx={{
                    color: 'badge.green.main',
                    backgroundColor: 'badge.green.light',
                    border: 'solid 1px',
                    borderColor: 'badge.green.main',
                    p: 0,
                    height: 24,
                    width: 24,
                  }}
                  onClick={handleSave}
                >
                  <CheckIcon sx={{ height: 20, width: 20 }} />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                className={'PENDO_cap_tracking_kebab_menu'}
                sx={{ p: 0 }}
                disabled={actionDisabled}
                onClick={() => {
                  setMenuOpen(true);
                }}
                ref={menuRef}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              anchorEl={menuRef.current}
              className={classes.menuList}
            >
              <MenuItem
                className={clsx(classes.menuItem, 'PENDO_cap_tracking_edit')}
                sx={{
                  borderBottom: 'solid 1px',
                  borderColor: 'gray.background',
                }}
                onClick={handleEdit}
              >
                <EditOutlinedIcon />
                <Box sx={{ ml: '5px' }}>Edit</Box>
              </MenuItem>
              <MenuItem
                className={clsx(classes.menuItem, 'PENDO_cap_tracking_delete')}
                sx={{ color: 'error.main' }}
                onClick={handleDelete}
              >
                <DeleteOutlineOutlinedIcon />
                <Box sx={{ ml: '5px' }}>Delete</Box>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <Divider />
      <Box className={classes.bottomHalf}>
        {gaugeBox}
        <Box sx={{ display: 'flex', gap: '12px' }}>
          {!isWarning && !isOverPaid && (
            <>
              <CapTrackingLegend
                label={'Paid'}
                amount={paidAmount}
                color={colors.blue}
              />
              <CapTrackingLegend
                label={'Remaining'}
                amount={capAmount - paidAmount}
                color={colors.lightGray}
              />
            </>
          )}
          {isWarning && !isOverPaid && (
            <>
              <CapTrackingLegend
                label={'Paid'}
                amount={paidAmount}
                color={colors.warning}
              />
              <CapTrackingLegend
                label={'Remaining'}
                amount={capAmount - paidAmount}
                color={colors.lightGray}
              />
            </>
          )}
          {isOverPaid && (
            <>
              <CapTrackingLegend
                label={'Paid'}
                amount={paidAmount}
                color={colors.red}
              />
              <CapTrackingLegend
                label={'Overpaid'}
                amount={overPaid}
                color={colors.lightRed}
              />
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

CapTrackingItem.propTypes = {
  capItem: capItemProps,
  canEdit: PropTypes.bool,
  onSave: PropTypes.func,
  onShowDeleteModal: PropTypes.func,
  actionDisabled: PropTypes.bool,
  gaugeOnly: PropTypes.bool,
};

export default CapTrackingItem;
