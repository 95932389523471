import { produce } from 'immer';
import _ from 'lodash';
import * as actions from 'actions/searchTimecards';
import {
  INITIAL_FILTERS,
  INITIAL_PAGE_SIZE,
  EMPTY_FILTERS,
  setVisibleSelected,
  searchDateFormat,
  DEFAULT_STATUS_SORT,
} from 'components/Employees/Reviews/SearchTimecards/searchUtils';

import { getFilteredDropdown } from 'selectors/searchTimecards';

const initialState = {
  data: [],
  totalCount: 0,
  pageSize: INITIAL_PAGE_SIZE,
  pages: 1,
  selectedApprovalFlows: [],
  /**
   * Each Filter obj will have
   * @param {int} index - index in the array (needed so checkbox knows which is which)
   * @param {string} label - Display label
   * @param {string} value - ID or val we're sending to API
   * @param {bool} selected - Is filter active (checked)?
   */

  filters: INITIAL_FILTERS,
  loading: false,
  sortBy: [], //user sort, default sort is attached in saga
  searchFilters: {
    //text for filtering displayed options
    employee: '',
    weekEndingDate: '',
    department: '',
    invoice: '',
    union: '',
    accountCodeHeader: '',
    batch: '',
    offerStatus: '',
  },
  submittingTimecards: false,
  removedSelectedOption: false,
  selectAllFlag: false,
  jobInfo: {},
  deletingTimecards: false,
  expandFilters: { batch: false, weekEndingDate: false }, //expand filters on initial load
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.onSelect}`: {
        const selected =
          !draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = selected;
        break;
      }

      case `${actions.onSelectAll}`: {
        const fullList = draft.filters[action.filterName]; //todo flip this back to DRAFT

        const mockState = { searchTimecards: state };
        const visibleList = getFilteredDropdown(mockState, action.filterName);
        const visibleSelectedCount = visibleList.filter(
          f => f.selected === true,
        ).length;

        let newValue;
        if (visibleSelectedCount === visibleList.length) {
          //unselect all visible
          newValue = false;
        } else {
          //select all visible
          newValue = true;
        }
        setVisibleSelected({ fullList, visibleList, newValue });
        draft.filters[action.filterName] = fullList;
        break;
      }

      case `${actions.setInitialFilters}`: {
        const newFilters = action.filters;

        Object.keys(newFilters).forEach(filterName => {
          if (
            filterName !== 'weekEndingDate' &&
            filterName !== 'batch' &&
            filterName !== 'status'
          ) {
            console.error(
              'Only status,weekEndingDate, batch filters are supported for setInitialFilters',
              filterName,
            );
            return;
          }
          const filter = draft.filters[filterName];
          const updatedValues = newFilters[filterName];

          updatedValues.forEach((val, index) => {
            filter.push({
              value: val,
              index,
              label: val,
              selected: true,
            });
          });
          draft.expandFilters[filterName] = true;
        });

        break;
      }
      case `${actions.setExpandFilters}`: {
        Object.keys(draft.expandFilters).forEach(filterName => {
          draft.expandFilters[filterName] = false;
        });
        break;
      }
      case `${actions.setSearchFilter}`: {
        draft.searchFilters[action.filterName] = action.value;
        break;
      }

      case `${actions.store}`: {
        draft.data = action.data.timecards ?? [];
        draft.totalCount = action.data.count;
        break;
      }
      case `${actions.loading}`: {
        draft.loading = action.loading;
        break;
      }
      case `${actions.storeSort}`: {
        draft.sortBy = action.sortBy;
        break;
      }

      /**
     * Accept new filter options.  If this list contains filters which are NOT in the list already:
     * If any filters in list are selected, select new ones
     * If no  filters in list are selected, do NOT select any
     * if no  old selected filters are in new list, do NOT select any
     * SortBy label, set index accordingly
     * @param {string} filterName - The string containing two comma-separated numbers.

     */
      case `${actions.storeFilterOptions}`: {
        const filterName = action.filterName;
        // const filters = action.data;
        const filters =
          action.data && action.data.options ? action.data.options : [];
        const prevFilters = draft.filters[filterName];

        const selectNewFilters = !!prevFilters.find(f => f.selected === true);

        let prevSelected = [],
          setAllToUnselected = true;
        // see if we need to set any filters to be selected
        if (selectNewFilters) {
          prevSelected = prevFilters
            .filter(prevFilter => prevFilter.selected === true)
            .map(prevFilter => prevFilter.value);

          const prevSelectedInNew = prevSelected.filter(
            prevSel => !!filters.find(f => f.id === prevSel),
          );

          if (prevSelectedInNew.length > 0) setAllToUnselected = false;

          if (prevSelectedInNew.length < prevSelected.length) {
            //If we removed an active filter, set this flag to re-fetch data
            draft.removedSelectedOption = true;
          }
        }

        const mappedFilters = filters.map(filter => {
          let selected = false;
          if (setAllToUnselected === false) {
            //if filter was in prev set, set to same selected as before
            selected = !!prevSelected.includes(filter.id);
          }
          if (filterName === 'status') {
            return {
              value: filter.id,
              label: filter.description,
              count: filter.numTimecards,
              selected: selected,
            };
          }

          return {
            value: filter.id,
            label: filter.name,
            selected,
          };
        });

        //sort alphabetically by label,except for weekending
        const sortedFilters =
          filterName === 'batch' //skip sorting for batches
            ? mappedFilters
            : filterName === 'status'
            ? mappedFilters.sort((a, b) => {
                return (
                  DEFAULT_STATUS_SORT.indexOf(a.label) -
                  DEFAULT_STATUS_SORT.indexOf(b.label)
                );
              })
            : mappedFilters.sort((a, b) => {
                const compare = a.label.toLowerCase() < b.label.toLowerCase();
                if (filterName === 'weekEndingDate') {
                  return compare ? 1 : -1;
                } else {
                  return compare ? -1 : 1;
                }
              });

        if (filterName === 'weekEndingDate') {
          sortedFilters.forEach(option => {
            option.label = searchDateFormat(option.label);
          });
        }

        //add index
        sortedFilters.forEach((sf, index) => (sf.index = index));

        draft.filters[filterName] = sortedFilters;
        break;
      }
      case `${actions.loadMore}`: {
        draft.pages = draft.pages + 1;
        break;
      }
      case `${actions.setRemovedSelectedOption}`: {
        // Note: this action isn't called to set this flag
        // its only in the storeFilterOptions
        draft.removedSelectedOption = action.removedSelectedOption;
        break;
      }

      case `${actions.clearFilters}`: {
        draft.filters = EMPTY_FILTERS;
        draft.searchFilters = initialState.searchFilters;
        draft.sortBy = initialState.sortBy;
        draft.pages = 1;
        break;
      }

      //called on comp unmount
      case `${actions.cleanup}`: {
        draft.searchFilters = initialState.searchFilters;
        draft.data = initialState.data;
        draft.totalCount = initialState.totalCount;
        break;
      }
      case `${actions.setInvoiceFilter}`: {
        if (!!action.invoice) {
          let invoice2Add = {
            value: action.invoice,
            label: action.invoice,
            selected: true,
            index: 0,
          };
          draft.filters.invoice = [invoice2Add];
        }
        break;
      }
      case `${actions.setDraftFilters}`: {
        const newFilters = _.cloneDeep(EMPTY_FILTERS);
        const { employees, weekEnding } = action;

        const employeeFilter = employees.map(id => ({
          value: id,
          selected: true,
        }));

        const weekEndingFilter = [
          {
            value: weekEnding,
            label: searchDateFormat(weekEnding),
            selected: true,
          },
        ];

        newFilters.employee = employeeFilter;
        newFilters.weekEndingDate = weekEndingFilter;

        newFilters.status = [
          {
            value: 'draft',
            label: 'Draft',
            selected: true,
            index: 0,
          },
        ];

        draft.filters = newFilters;
        break;
      }
      case `${actions.submittingTimecards}`: {
        draft.submittingTimecards = action.submittingTimecards;
        break;
      }
      case `${actions.deletingTimecards}`: {
        draft.deletingTimecards = action.deletingTimecards;
        break;
      }
      case `${actions.setSelectAllFlag}`: {
        draft.selectAllFlag = action.selectAllFlag;
        break;
      }

      case `${actions.setSelectedFlows}`: {
        draft.selectedApprovalFlows = action.selectedApprovalFlows;
        break;
      }
      case `${actions.storeJobInfo}`: {
        draft.jobInfo = { jobId: action.jobId, jobLength: action.jobLength };
        break;
      }
      case `${actions.deleteJobInfo}`: {
        draft.jobInfo = {};
        break;
      }
      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }

        break;
      }

      default:
    }
  });
