import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// actions
import { fetch as fetchUserInfo } from 'actions/userInfo';
import { fetch as fetchSettings } from 'actions/settings';
import { fetchDetails } from 'actions/project';

import { useDidMount } from 'utils/customHooks';

const mapDispatchToProps = dispatch => ({
  onFetchUserInfo: () => {
    dispatch(fetchUserInfo());
  },
  onFetchProject: () => {
    dispatch(fetchDetails());
  },
  onFetchSettings: () => dispatch(fetchSettings()),
});

/**
 * Make the initial fetch calls for the page.
 * Should match calls from projectHeader but when no header is needed.
 */
const BlankHeader = ({ onFetchUserInfo, onFetchProject, onFetchSettings }) => {
  useDidMount(() => {
    onFetchUserInfo();
    onFetchProject();
    onFetchSettings();
  });

  return null;
};

BlankHeader.propTypes = {
  onFetchUserInfo: PropTypes.func.isRequired,
  onFetchProject: PropTypes.func.isRequired,
  onFetchSettings: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(BlankHeader);
