import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { isDirty } from 'redux-form';
import { styled, Box, Tooltip } from '@mui/material';
import WeekPicker from '../Shared/WeekPicker';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { SubmitButton, Button } from '../Shared/styledComponents';

import CopyFromPrevList from '../Shared/CopyPrevList';
import * as actions from '../actions';
import * as sel from '../selectors';
import { getFormValues } from 'selectors/formValues';

import { show as showModal } from 'actions/modalDialog';
import { NEW_TC_ID, checkCopyToAllMatch } from '../empTimecardUtils';

import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';

import {
  CONFIRM_EDIT_MODAL,
  DEALMEMO_DIALOG,
  SIGNATURE_MODAL,
  DELETE_TC_DIALOG,
  CONFIRM_COPY_TO_ALL_DAYS_MODAL,
} from '../Modals/modalNames';

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '30px',
  fontWeight: 'bold',
  alignItems: 'center',
  marginRight: '24px',
}));

const mapState = state => ({
  loading: sel.getLoading(state, 'timecard'),
  timecardId: sel.getTimecardId(state),
  comment: sel.getNewComment(state),
  tcStatus: sel.getTimecardStatus(state),
  disableBtns: sel.getDisabledBtns(state),
  timecard: getFormValues(sel.FORM_NAME)(state),
  isDirty: isDirty(sel.FORM_NAME)(state),
});

const mapDispatch = dispatch => ({
  onShowDealModal: () => {
    dispatch(showModal({ dialog: DEALMEMO_DIALOG }));
  },
  onSaveTimecard: () => {
    dispatch(actions.saveTimecard());
  },
  onSubmit: (showCopyToAllModal, isDirty) => {
    if (isDirty && showCopyToAllModal) {
      dispatch(showModal({ dialog: CONFIRM_COPY_TO_ALL_DAYS_MODAL }));
    } else {
      dispatch(showModal({ dialog: SIGNATURE_MODAL }));
    }
  },
  onShowDeleteModal: () => dispatch(showModal({ dialog: DELETE_TC_DIALOG })),
  onShowEditModal: () => dispatch(showModal({ dialog: CONFIRM_EDIT_MODAL })),
  onPrintTimecard: () => dispatch(actions.printTimecard()),
});

const Header = props => {
  const {
    onShowDealModal,
    onSaveTimecard,
    onSubmit,
    onPrintTimecard,
    loading,
    onShowDeleteModal,
    timecardId,
    tcStatus,
    onShowEditModal,
    disableBtns,
    timecard,
    isDirty,
  } = props;

  const {
    disableOnLoading,
    disableSubmit,
    disableSave,
    disableTopActions,
    submitTooltip,
  } = disableBtns || {};

  const isNewTc = timecardId === NEW_TC_ID;

  const deleteButton = (
    <Button
      disabled={disableOnLoading}
      variant="outlined"
      color="error"
      onClick={onShowDeleteModal}
      tabIndex={-1} // to prevent focus on delete button after modal close
    >
      Delete
    </Button>
  );

  const handleSubmit = () => {
    const showCopyToAllModal = checkCopyToAllMatch(timecard);
    onSubmit(showCopyToAllModal, isDirty);
  };
  const submitButton = (
    <Tooltip title={submitTooltip}>
      <Box>
        <Button disabled={disableSubmit} onClick={handleSubmit}>
          {TIMECARD_PENDING_EMP_REVIEW === tcStatus ? 'Approve' : 'Submit'}
        </Button>
      </Box>
    </Tooltip>
  );

  let headerButtons;
  if (tcStatus === TIMECARD_INCOMPLETE) {
    headerButtons = (
      <>
        {timecardId !== NEW_TC_ID && deleteButton}
        <SubmitButton
          disabled={disableSave}
          variant="outlined"
          onClick={onSaveTimecard}
        >
          Save
        </SubmitButton>
        {submitButton}
      </>
    );
  } else if (tcStatus === TIMECARD_PENDING_EMP_REVIEW) {
    headerButtons = (
      <>
        <Button
          onClick={onShowEditModal}
          disabled={disableOnLoading}
          variant="outlined"
        >
          Edit
        </Button>
        {submitButton}
      </>
    );
  } else if (!loading) {
    headerButtons = (
      <Button
        color="primary"
        key="print"
        disabled={disableOnLoading}
        onClick={onPrintTimecard}
      >
        {'Print'}
      </Button>
    );
  }
  return (
    <HeaderBox>
      <ButtonBox>
        <TitleBox>Timecard</TitleBox>
        <WeekPicker disabled={disableTopActions || !isNewTc} label={''} />
        <ButtonBox sx={{ mt: '18px' }}>
          {timecardId === NEW_TC_ID && (
            <CopyFromPrevList disabled={disableTopActions} />
          )}

          <Button
            variant="outlined"
            startIcon={<DescriptionOutlinedIcon />}
            onClick={onShowDealModal}
          >
            Deal
          </Button>
        </ButtonBox>
      </ButtonBox>
      <ButtonBox>{headerButtons}</ButtonBox>
    </HeaderBox>
  );
};

Header.propTypes = {
  onShowDealModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSaveTimecard: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrintTimecard: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  timecardId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([NEW_TC_ID]),
  ]),
  tcStatus: PropTypes.string,
  onShowEditModal: PropTypes.func.isRequired,
  disableBtns: PropTypes.shape({
    disableOnLoading: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    disableSave: PropTypes.bool,
    disableActions: PropTypes.bool,
    submitTooltip: PropTypes.string,
  }),
  timecard: PropTypes.object,
};

export default compose(connect(mapState, mapDispatch))(Header);
