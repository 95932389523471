import React from 'react';
import _ from 'lodash';
import {
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Button,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { AutoCompletePlain as AutoComplete } from 'components/Shared/AutoComplete';
import makeStyles from '@mui/styles/makeStyles';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { EMPLOYEE, DH } from 'components/props/profiles';
import Message from 'components/Message';
import { isAutoAllowanceCheck, validateAllowanceInput } from 'utils/weekUtils';
import {
  normalizeStrNumber,
  fileNameChange,
  onBlurNumber,
  canadianLabel,
} from 'utils/helperFunctions';
// import { fileEncryptionCheckHandler } from 'utils/allowancesUtils';
import {
  ALLOWANCE_DOC_MAX_SIZE,
  PERMITTED_FILE_UPLOAD_TYPE,
} from 'components/Shared/constants';
//components
import AlloFlagBadge from 'components/Shared/Text/AlloFlagBadge';

export const useStyles = makeStyles(theme => ({
  actionColumn: { width: '15%' },
  defaultColumn: { width: '20%' },
  reimbursementColumn: { width: '25%' },
  combineCheckCodeColumn: { width: '15%' },
  button: {
    '& > *': { fontWeight: 500 },
  },
  input: {
    display: 'none',
  },
  icon: {
    padding: theme.spacing(1),
  },
  fileSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: '1px dashed',
    borderColor: theme.palette.getDefaultColor(),
    padding: 5,
  },
  flexGrow: { flexGrow: 1 },
  iconButton: {
    fontSize: '1.2em',
  },
  required: { color: 'red', fontSize: '1.2em' },
}));

export default function AllowanceRow({
  // data
  index,
  timecardId,
  allowance = {},
  allowanceInput,
  timecardAllowances = [],
  // methods
  onDeleteAllowance,
  onDownloadDocument,
  onSaveAllowance,
  showAlert,
  onSelectAllowanceType,
  onSelectAllowanceAmount,
  onSelectAllowanceCombineCheckCode,
  onUploadDocument,
  onResetAllowance,
  setAllowanceInProgress,
  // flags
  canEdit,
  isTeamTimecard = false,
  isViewOnly = false,
  dhAllowanceDisabled,
  isReviewing,
  empAllowanceDisableAddedByPA,
  loading,
  role,
  editReview,
  onSelectAllowanceUnits,
  onSelectAllowanceRate,
  onShowAlert,
  timecard,
}) {
  const classes = useStyles();
  const isEmpty = allowance ? _.isEmpty(allowance) : _.isEmpty(allowanceInput);
  const allowancePath = allowanceInput ? allowanceInput : allowance;

  const allowanceType = (function () {
    const htgAllowanceTypeId = allowancePath.htgAllowanceTypeId;
    return (
      _.find(timecardAllowances, type => type.id === htgAllowanceTypeId) ||
      allowancePath.allowanceType ||
      {}
    );
  })();
  const amount = allowancePath.amount;
  const rate = allowancePath.rate;
  const units = allowancePath.units;
  const fileName = allowancePath.filename;
  const mandatoryUpload = allowanceType.employeeMandatory || false;

  const combineCheckCode = allowancePath.combineCheckCode || false;

  const reimbursement =
    allowancePath.isDefaultAllowance && !isViewOnly
      ? allowancePath.defaultAllowanceId
      : allowancePath.htgAllowanceTypeId;

  const options = _.chain(timecardAllowances)
    .map(item => ({
      value: item.id || item.ref.id,
      label: item.description || item.ref.name,
      isDefaultAllowance: item.isDefaultAllowance || false,
    }))
    .value();
  const isAutoAllo = isAutoAllowanceCheck(allowancePath?.allowanceTypeFlag);

  const fileInputId = `text-button-file-${allowance.id || index}`;
  const amountFieldId = `allowanceType-amount-${allowance.id || index}`;
  const rateFieldId = `allowanceType-rate-${allowance.id || index}`;
  const unitsFieldId = `allowanceType-units-${allowance.id || index}`;
  const [editable, setEditable] = React.useState(isEmpty);
  const [editing, setEditing] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [localInProgress, setLocalInProgress] = React.useState(false);
  const [showSubmitReminder, setShowSubmitReminder] = React.useState(false);
  const [payCodeOptions, setPayCodeOptions] = React.useState(options);
  const [isPayCodeDisabled, setIsPayCodeDisabled] = React.useState(isAutoAllo);

  const isRoleDH = role === DH;
  const flagType =
    allowance?.allowanceTypeFlag === 'A'
      ? allowance?.isEdited
        ? 'modified'
        : 'deal'
      : 'manual';
  React.useEffect(() => {
    if (!isAutoAllo) return;

    if (!isRoleDH) return;

    const dealMemo = timecard?.dealMemo || {};

    const dealAllo = dealMemo?.dealMemoAllowances?.find(dmA =>
      allowance.sequenceNumber > 0
        ? dmA.sequenceNumber === allowance.sequenceNumber
        : allowance.sequenceGuid === dmA.id,
    );

    if (!dealAllo) return;

    const payCode1 = timecardAllowances.find(
      payCode => payCode.value === dealAllo?.payCode1?.id,
    );
    const payCode2 = timecardAllowances.find(
      payCode => payCode.value === dealAllo?.payCode2?.id,
    );

    if (!payCode1 || !payCode2) return;

    const newOptions = [payCode1, payCode2].map(item => ({
      value: item.id || item.ref.id,
      label: item.description || item.ref.name,
      isDefaultAllowance: item.isDefaultAllowance || false,
    }));

    setPayCodeOptions(newOptions);
    setIsPayCodeDisabled(false);
  }, [
    allowance?.sequenceNumber,
    allowance?.sequenceGuid,
    isAutoAllo,
    isRoleDH,
    timecard?.dealMemo,
    timecardAllowances,
  ]);

  React.useEffect(() => {
    if (editable && (reimbursement || amount || fileName)) {
      setAllowanceInProgress(true);
      setLocalInProgress(true);
    } else {
      setAllowanceInProgress(false);
      setLocalInProgress(false);
    }
  }, [reimbursement, amount, fileName, editable, setAllowanceInProgress]);

  React.useEffect(() => {
    if (!editReview && isViewOnly) {
      setEditing(false);
      setEditable(false);
    }
  }, [editReview, isViewOnly]);

  let disableEdit = false;
  if (empAllowanceDisableAddedByPA) {
    disableEdit = _.isEmpty(
      _.find(options, opt => {
        return opt.value === reimbursement;
      }),
    );
  }

  const handleSave = () => {
    const errors = validateAllowanceInput(allowancePath, mandatoryUpload);

    if (errors.valid) {
      onSaveAllowance(index, allowanceInput || allowance, timecardId);
      setErrors({});
      if (editing) setEditing(false);
      if (editable && index >= 0) setEditable(false);
      setAllowanceInProgress(false);
    } else if (showAlert) {
      if (editing) setEditing(true);
      showAlert({ message: errors.messages });
    }
  };

  const deleteFile = () => {
    onUploadDocument({ index, timecardId, document: null, isTeamTimecard });
  };

  const onUploadFile = async event => {
    const file = event.target.files ? event.target.files[0] : null;
    const fileType = file?.type;
    const newFile = fileNameChange(file);

    // HOUR-8062 - removing file encryption check
    // const isFileEncrypted = await fileEncryptionCheckHandler(file);
    // if (isFileEncrypted) {
    //   errors.file = 'File is encrypted/esigned';
    //   setErrors(_.cloneDeep(errors));
    //   onShowAlert({
    //     variant: 'warning',
    //     message:
    //       'The uploaded document cannot be attached, due to an e-signature on the document.',
    //   });
    // } else
    if (!PERMITTED_FILE_UPLOAD_TYPE.includes(fileType)) {
      errors.file = 'File type invalid, please use PDF, JPG, PNG formats';
      setErrors(_.cloneDeep(errors));
    } else if (file && file.size > ALLOWANCE_DOC_MAX_SIZE) {
      errors.file = 'File must be less than 10 Mb';
      setErrors(_.cloneDeep(errors));
    } else {
      errors.file = null;
      setErrors(_.cloneDeep(errors));
      if (file) {
        onUploadDocument({
          index,
          timecardId,
          document: newFile,
          isTeamTimecard,
        });
      }
    }
  };

  const onReset = () => {
    setLocalInProgress(false);
    setShowSubmitReminder(false);
    setErrors({});
    onResetAllowance({ index, timecardId, isTeamTimecard });
    //clear amount field
    const amountField = document.getElementById(amountFieldId);
    amountField.value = '';
  };

  // normalize amount to always be a number
  // allow decimal point as end character
  const onChangeAmount = e => {
    const value = e.target.value;
    const amount = normalizeStrNumber(value, { decimalPrecision: 2 });
    onSelectAllowanceAmount({
      index,
      timecardId,
      amount,
      isTeamTimecard,
    });
  };

  const onChangeRate = e => {
    const value = e.target.value;
    const newRate = normalizeStrNumber(value, { decimalPrecision: 4 });
    let amount = parseInt(units || 1) * parseFloat(newRate || 0);
    amount = onBlurNumber(amount);
    onSelectAllowanceRate({
      index,
      timecardId,
      rate: newRate,
      isTeamTimecard,
    });
    if (!units || units === 0) {
      onSelectAllowanceUnits({
        index,
        timecardId,
        units: 1,
        isTeamTimecard,
      });
    }
    onSelectAllowanceAmount({
      index,
      timecardId,
      amount,
      isTeamTimecard,
    });
  };

  const onBlurRate = e => {
    const value = e.target.value;
    const rate = onBlurNumber(value, 4, 0);
    onSelectAllowanceRate({
      index,
      timecardId,
      rate,
      isTeamTimecard,
    });
  };

  const onChangeUnits = e => {
    let value = e.target.value;
    value = normalizeStrNumber(value, { decimalPrecision: 2 });
    const amount = onBlurNumber(value * rate);
    onSelectAllowanceAmount({
      index,
      timecardId,
      amount,
      isTeamTimecard,
    });
    onSelectAllowanceUnits({
      index,
      timecardId,
      units: value,
      isTeamTimecard,
    });
  };

  const onBlurUnits = e => {
    let value = e.target.value;
    value = onBlurNumber(value, 2, 0);
    onSelectAllowanceUnits({
      index,
      timecardId,
      units: value,
      isTeamTimecard,
    });
  };

  const onBlurAmount = e => {
    const value = e.target.value;

    const amount = onBlurNumber(value);

    onSelectAllowanceAmount({
      index,
      timecardId,
      amount,
      isTeamTimecard,
    });
  };

  const onChangeReimbursement = item => {
    if (item) {
      const value = item.value;
      const allowanceType =
        _.find(timecardAllowances, type => type.id === value) || item;
      onSelectAllowanceType({
        index,
        timecardId,
        allowanceType,
        isTeamTimecard,
      });
    } else {
      const allowanceType = {};
      onSelectAllowanceType({
        index,
        timecardId,
        allowanceType,
        isTeamTimecard,
      });
    }
  };

  const onChangeCombineCheck = combineCheckCode => {
    onSelectAllowanceCombineCheckCode({
      index,
      timecardId,
      allowanceType,
      combineCheckCode,
      isTeamTimecard,
    });
  };

  const deleteAllowance = () => {
    onDeleteAllowance(allowance.worksightId, index);
    errors.file = null;
    setErrors(_.cloneDeep(errors));
  };

  function renderSelectedFileSection() {
    return (
      <div className={classes.fileSection}>
        <div className={classes.flexGrow}>{fileName}</div>
        <div>
          <label
            htmlFor={fileInputId}
            disabled={dhAllowanceDisabled && isReviewing}
          >
            <IconButton
              size="small"
              component="span"
              color="default"
              disabled={dhAllowanceDisabled && isReviewing}
            >
              <EditIcon className={classes.iconButton} />
            </IconButton>
          </label>
          <IconButton
            size="small"
            disabled={dhAllowanceDisabled && isReviewing}
            onClick={() => deleteFile()}
          >
            <DeleteIcon className={classes.iconButton} />
          </IconButton>
        </div>
      </div>
    );
  }

  const renderEditable = () => {
    const tooltip = `If you’d like to have this allowance on a separate, physical ${canadianLabel(
      'check',
    )}, check this box.`;

    const checkboxLabel = `Send as a ${canadianLabel('Live Check')}`;

    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowSubmitReminder(localInProgress);
        }}
      >
        <TableRow>
          <TableCell className={classes.reimbursementColumn}>
            <AutoComplete
              options={payCodeOptions}
              placeholder="Select Reimbursement"
              onChange={value => onChangeReimbursement(value)}
              value={reimbursement}
              helperText={'Allowance Must Be Submitted'}
              error={showSubmitReminder}
              disabled={
                (dhAllowanceDisabled && isReviewing) || isPayCodeDisabled
              }
            />
          </TableCell>
          {isRoleDH && isReviewing && (
            <>
              <TableCell width="10%">
                <AlloFlagBadge variant={'wtc'} flagType={flagType} />
              </TableCell>
              <TableCell width="10%">
                <TextField
                  id={unitsFieldId}
                  value={units || ''}
                  onChange={onChangeUnits}
                  onBlur={onBlurUnits}
                  placeholder="Enter units"
                  disabled={dhAllowanceDisabled && isReviewing}
                />
              </TableCell>
              <TableCell width="10%">
                <TextField
                  id={rateFieldId}
                  value={rate || ''}
                  onChange={onChangeRate}
                  onBlur={onBlurRate}
                  placeholder="Enter rate"
                  disabled={dhAllowanceDisabled && isReviewing}
                />
              </TableCell>
            </>
          )}
          <TableCell width="10%">
            <TextField
              id={amountFieldId}
              value={amount || ''}
              onChange={onChangeAmount}
              onBlur={onBlurAmount}
              placeholder="Enter Amount"
              error={showSubmitReminder}
              disabled={
                (dhAllowanceDisabled && isReviewing) ||
                (isRoleDH && isReviewing)
              }
            />
          </TableCell>
          <TableCell className={classes.combineCheckCodeColumn}>
            <Tooltip arrow title={tooltip}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.selectRow}
                    checked={combineCheckCode}
                    onChange={e => onChangeCombineCheck(e.target.checked)}
                    color="primary"
                  />
                }
                label={checkboxLabel}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={classes.defaultColumn}>
            <input
              className={classes.input}
              id={fileInputId}
              type="file"
              accept="image/png, image/jpeg, .pdf"
              onChange={onUploadFile}
              disabled={dhAllowanceDisabled && isReviewing}
              onClick={e => {
                e.target.value = null;
              }}
            />
            {fileName && renderSelectedFileSection()}
            {!fileName && (
              <label htmlFor={fileInputId}>
                <Button
                  className={classes.button}
                  variant="text"
                  color="primary"
                  component="div"
                  size="small"
                >
                  {' Add Supporting Document '}
                  <CloudUploadIcon className={classes.icon} />
                  {mandatoryUpload && (
                    <span className={classes.required}>*</span>
                  )}
                </Button>
              </label>
            )}
            {errors.file && <Message errorSecondary>{errors.file}</Message>}
          </TableCell>
          <TableCell className={classes.actionColumn}>
            <React.Fragment>
              <IconButton
                size="small"
                onClick={handleSave}
                disabled={
                  (dhAllowanceDisabled && isReviewing) ||
                  loading ||
                  !localInProgress
                }
              >
                <SaveIcon color={!localInProgress ? 'default' : 'primary'} />
              </IconButton>
              {isTeamTimecard ? (
                <IconButton size="small" onClick={() => deleteAllowance()}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (allowanceInput === undefined) setEditable(false);
                    onReset();
                  }}
                  disabled={
                    (dhAllowanceDisabled && isReviewing) || !localInProgress
                  }
                >
                  <CancelIcon />
                </IconButton>
              )}
            </React.Fragment>
          </TableCell>
        </TableRow>
      </ClickAwayListener>
    );
  };

  const renderReadOnly = () => {
    const token = allowance.token;
    const combineCheckCode = allowance.combineCheckCode;
    const disableDeleteAllowance =
      (role === EMPLOYEE || role === DH) && isViewOnly && canEdit
        ? false
        : true;
    const disableEditDeleteAllowance =
      role === EMPLOYEE && isViewOnly ? true : false;
    const description = (function () {
      if (allowancePath.description) return allowancePath.description;
      if (!_.isEmpty(allowanceType)) {
        return allowanceType.name || allowanceType.description || 'default';
      }
      return 'default';
    })();

    const checkboxLabel = `Send as a ${canadianLabel('Live Check')}`;

    return (
      <TableRow>
        <TableCell width="25%">{description}</TableCell>
        {isRoleDH && isReviewing && (
          <>
            <TableCell width="10%">
              <AlloFlagBadge variant={'wtc'} flagType={flagType} />
            </TableCell>
            <TableCell width="10%">{units}</TableCell>
            <TableCell width="10%">{rate}</TableCell>
          </>
        )}
        <TableCell width="10%">{onBlurNumber(amount)}</TableCell>
        <TableCell className={classes.combineCheckCodeColumn}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.selectRow}
                checked={combineCheckCode}
                disabled
                color="primary"
              />
            }
            label={checkboxLabel}
          />
        </TableCell>
        <TableCell className={classes.defaultColumn}>
          {fileName ? (
            <Button
              size="small"
              onClick={() => onDownloadDocument(fileName, token)}
              disabled={!token}
            >
              {fileName}
            </Button>
          ) : (
            '----'
          )}
        </TableCell>
        <TableCell className={classes.actionColumn}>
          {disableDeleteAllowance && (
            <React.Fragment>
              <IconButton
                size="small"
                onClick={() => {
                  if (editing) handleSave();
                  setEditable(!editable);
                  setEditing(!editing);
                }}
                disabled={
                  (dhAllowanceDisabled && isReviewing) ||
                  disableEdit ||
                  disableEditDeleteAllowance
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => deleteAllowance()}
                disabled={
                  (dhAllowanceDisabled && isReviewing) ||
                  disableEdit ||
                  disableEditDeleteAllowance
                }
              >
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return editable ? renderEditable() : renderReadOnly();
}
