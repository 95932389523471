import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from 'feature/EmployeeTimecard/Shared/styledComponents';
import Icon from 'components/images/PieSlice.js';

import { show as showModal } from 'actions/modalDialog';
import { capModal } from './capUtils';

import { getCapTrackingData } from 'feature/CapTracking/selectors';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  ShowCapBtn: {
    display: 'flex',
    gap: '5px',
  },
}));

const mapState = (state, ownProps) => {
  const employeeId = ownProps.employeeId;
  return {
    capTrackingData: getCapTrackingData(state, employeeId),
  };
};

const mapDispatch = dispatch => ({
  onShowModal: () => dispatch(showModal({ dialog: capModal })),
});

const ShowCapBtn = props => {
  const classes = useStyles();
  const {
    onShowModal,
    onClick,
    className,
    capTrackingData = [],
    employeeId,
    disabled = false,
    ...rest
  } = props;
  if (onClick) {
    console.warn('onClick for show cap btn will be ignored.');
  }

  const noData = capTrackingData.length === 0;

  const isDisabled = noData || disabled;

  const title =
    'There are no allowances with caps on this timecard. When allowances with caps appear, this tracker will be available.';

  return (
    <Tooltip placement="top" title={noData ? title : ''}>
      <div>
        <Button
          variant="outlined"
          className={clsx(classes.ShowCapBtn, className)}
          onClick={onShowModal}
          disabled={isDisabled}
          {...rest}
        >
          <Icon color={isDisabled ? 'text.disabled' : 'primary.main'} />
          View Cap Tracker
        </Button>
      </div>
    </Tooltip>
  );
};

ShowCapBtn.propTypes = {
  onShowModal: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  capTrackingData: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default compose(connect(mapState, mapDispatch))(ShowCapBtn);
