import { buildUrl } from 'providers/api';

const URL_SCHEDULED_REPORTS = '/projects/:projectId/reports';

const URL_UPDATE_SCHEDULED_REPORTS = '/projects/:projectId/reports/:reportId';

const URL_GET_USERACCESS_REPORTS_DETAILS =
  '/reports/reportsdetails/:clientCode';

const URL_USERS_SEARCH = '/users/search/:email';

const URL_ADMIN_USERS = '/access-admin';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async scheduledReports({ projectId }) {
    const url = buildUrl(URL_SCHEDULED_REPORTS, {
      projectId,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async addReport({ projectId, report }) {
    const url = buildUrl(URL_SCHEDULED_REPORTS, {
      projectId,
    });

    const response = await clientV2.post(url, { ...report });

    return response.data;
  },

  async updateReport({ projectId, reportId, report }) {
    const url = buildUrl(URL_UPDATE_SCHEDULED_REPORTS, {
      projectId,
      reportId,
    });

    const response = await clientV2.put(url, { ...report });

    return response.data;
  },

  async deleteScheduledReport({ projectId, reportId }) {
    const url = buildUrl(URL_UPDATE_SCHEDULED_REPORTS, {
      projectId,
      reportId,
    });

    const response = await clientV2.delete(url);

    return response.data;
  },

  async getUserAccessReportDetails({ clientCode }) {
    const url = buildUrl(URL_GET_USERACCESS_REPORTS_DETAILS, {
      clientCode,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async getUsersOfAllProjects({ email }) {
    const url = buildUrl(URL_USERS_SEARCH, {
      email,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async getAdminUsers() {
    const url = URL_ADMIN_USERS;
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async saveAdminUser({ payload }) {
    const url = URL_ADMIN_USERS;
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },
  async updateAdminUser({ payload }) {
    const url = URL_ADMIN_USERS;
    const rsp = await clientV2.put(url, payload);
    return rsp.data;
  },
});
