import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
//Redux
import { push } from 'redux-first-history';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/userInfo';
import { getLastFetch } from 'selectors/userInfo';
import { getUserForProject } from 'selectors/session';
import { getProject as getProjectId } from 'selectors/routeParams';

//Utils
import { setupPendoInfo, delayOnValue, db } from 'utils/helperFunctions';
import { ROLE_LABELS, IA, PROJECT_ADMIN } from 'components/props/profiles';
import { addReadableRole } from 'feature/CrewList/CrewListUtils';

export function* fetchUserInfo(api, projectId) {
  yield put(actions.loading({ loading: true }));

  let activeUser = yield select(getUserForProject, projectId);
  const isValid = yield call(isUserDataValid, projectId, activeUser);
  if (isValid) {
    // User data is valid, no need to fetch/update
  } else {
    activeUser = yield call(api.projects.userInfo, { projectId });
    if (activeUser) {
      yield put(actions.store({ projectId, activeUser }));
    }
  }

  if (activeUser) {
    addUserInfoToPendo(activeUser);
  }
  yield put(actions.loading({ loading: false }));
  return activeUser;
}

/**
 * Check if the user data is valid
 * Valid means data has been recently fetched and is not empty
 * @param {number} projectId
 *
 * @returns {boolean} isStale - true if the user data is stale or missing
 */
function* isUserDataValid(projectId, activeUser) {
  const USER_CACHE_MIN = 15;
  const lastFetch = yield select(getLastFetch, projectId);

  if (!lastFetch) {
    db('User Invalid for project: No last fetch for project', projectId);
    return false;
  }

  const now = Date.now();
  const diff = now - lastFetch;
  const cacheInMilliseconds = USER_CACHE_MIN * 60 * 1000;
  if (diff > cacheInMilliseconds) {
    db('User Invalid for project: User data is stale');
    return false;
  }

  const isEmpty = !activeUser || _isEmpty(activeUser);
  if (isEmpty) {
    db('User Invalid for project: User data is empty');
    return false;
  }

  return true;
}

function addUserInfoToPendo(activeUser) {
  const dtoUser = {
    ...activeUser,
    worksightId: activeUser.workSightId,
    roles: activeUser.projectRoles,
  };
  const { readableRole } = addReadableRole(dtoUser);

  let role = readableRole;
  if (activeUser.projectRoles.includes(IA) && role !== ROLE_LABELS[IA]) {
    role += ` | ${ROLE_LABELS[IA]}`;
  }

  if (activeUser.projectRoles.includes(PROJECT_ADMIN)) {
    role += ` | ${ROLE_LABELS[PROJECT_ADMIN]}`;
  }

  //storing User data in session for pendo
  const pendoUser = {
    role: role,
    email: activeUser?.email || '',
  };
  sessionStorage.setItem('pendo_profile', JSON.stringify(pendoUser));
  //setting up pendo parameters
  setupPendoInfo();
}

function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(getProjectId);

    if (projectId) {
      yield call(fetchUserInfo, api, projectId);
    }

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    yield put(push('/projects'));
  }
}

function* fetchPermissions(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(getProjectId);
    const permissions = yield call(api.projects.getPermissions, { projectId });
    yield put(actions.storePermissions({ projectId, permissions }));
    yield put(actions.loading({ loading: false }));
  } catch (error) {
    debug(error);
  }
}

export default function* userInfoFlow({ api, debug }) {
  yield all([
    takeLatest(`${actions.fetch}`, fetch, api, debug),
    takeLatest(`${actions.fetchPermissions}`, fetchPermissions, api, debug),
  ]);
}
