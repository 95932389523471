import { buildUrl } from 'providers/api';

const URL_CAP_GET = '/projects/:projectId/users/:employeeId/reimbursements';
const URL_CAP_PUT =
  '/projects/:projectWorksightId/users/:employeeId/updateCapAmount';
const URL_CAP_DELETE =
  '/projects/:projectWorksightId/users/:employeeId/deleteCap';

const capTrackingApi = ({ clientV2 }) => ({
  async fetchCap({ projectId, employeeId }) {
    const url = buildUrl(URL_CAP_GET, { projectId, employeeId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async updateCap({ projectWorksightId, employeeId, payload }) {
    let url = buildUrl(URL_CAP_PUT, { projectWorksightId, employeeId });
    const rsp = await clientV2.put(url, payload);
    return rsp.data;
  },
  async deleteCap({ projectWorksightId, employeeId, data }) {
    const url = buildUrl(URL_CAP_DELETE, { projectWorksightId, employeeId });
    const rsp = await clientV2.delete(url, { data });
    return rsp.data;
  },
});
export default capTrackingApi;
