import { all, takeLatest, put, select, call } from 'redux-saga/effects';

// actions
import * as actions from 'feature/CapTracking/actions';
import { showAlert } from 'actions/alert';
import { hide as hideModal } from 'actions/modalDialog';

// selectors
import { getProject } from 'selectors/routeParams';
import { getCapTrackingData } from './selectors';
import { currentUser } from 'selectors/session';
import { getCurrentProjectWorkSight } from 'selectors/project';
import { deleteCapModal, capModal } from './capUtils';

export function* fetchCapTracking(api, debug, params) {
  let employeeId;
  try {
    const { deleting = false } = params;
    employeeId = params.employeeId;
    if (!employeeId) {
      throw new Error('Employee ID is required');
    }
    yield put(actions.capTrackingLoader({ loading: true, employeeId }));
    const projectId = yield select(getProject);
    const capTrackingData = yield call(api.capTracking.fetchCap, {
      projectId,
      employeeId,
    });
    //if no cap remaining after delete closing cap modal
    if (deleting && capTrackingData.length === 0) {
      yield put(hideModal({ dialog: capModal }));
    }
    yield put(actions.storeCapTracking({ capTrackingData, employeeId }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.capTrackingLoader({ loading: false, employeeId }));
  }
}

export function* deleteCap(api, debug, params) {
  try {
    yield put(actions.deletingCap({ loading: true }));
    const { category, employeeId } = params;

    const projectWorksightId = yield select(getCurrentProjectWorkSight);
    const activeUser = yield select(currentUser);

    const data = {
      capModifiedBy: activeUser.fullName,
      category: category,
    };
    yield call(api.capTracking.deleteCap, {
      projectWorksightId,
      employeeId,
      data,
    });
    yield put(hideModal({ dialog: deleteCapModal }));
    yield put(actions.fetchCapTracking({ employeeId, deleting: true }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.deletingCap({ loading: false }));
  }
}
export function* saveCap(api, debug, params) {
  try {
    yield put(actions.savingCap({ saving: true }));
    const { category, capAmount, employeeId } = params;
    const projectWorksightId = yield select(getCurrentProjectWorkSight);
    const activeUser = yield select(currentUser);

    const capData = yield select(getCapTrackingData, employeeId);
    const capObj = capData.find(item => item.category === category);

    const payload = {
      capModifiedBy: activeUser.fullName,
      category: category,
      capAmount: parseFloat(capAmount),
      prevCapAmount: capObj.capAmount,
    };
    yield call(api.capTracking.updateCap, {
      projectWorksightId,
      employeeId,
      payload,
    });
    yield put(actions.fetchCapTracking({ employeeId }));
  } catch (e) {
    yield put(showAlert({ message: 'Error saving cap', type: 'warning' }));
    debug(e);
  } finally {
    yield put(actions.savingCap({ saving: false }));
  }
}

export default function* fetchTemplateFlow({ api, debug }) {
  yield all([
    takeLatest(`${actions.fetchCapTracking}`, fetchCapTracking, api, debug),
    takeLatest(`${actions.deleteCap}`, deleteCap, api, debug),
    takeLatest(`${actions.saveCap}`, saveCap, api, debug),
  ]);
}
