import createAction from 'actions/createAction';

export const fetchCapTracking = createAction('capTracking/fetch');
export const storeCapTracking = createAction('capTracking/store');
export const capTrackingLoader = createAction('capTracking/loading');
export const saveCap = createAction('capTracking/saveCap');
export const savingCap = createAction('capTracking/savingCap/loading');
export const deletingCap = createAction('capTracking/deletingCap/loading');
export const deleteCap = createAction('capTracking/deleteCap');

export const reset = createAction('capTracking/clear'); //TODO add to wtc and myTimecard unmount
