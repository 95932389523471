import _ from 'lodash';
import { createSelector } from 'reselect';
import { makeFilterSelectors } from './shared';

export const getSaveMetaData = state => _.get(state, 'dts.saveMetaData', {});

export const getSavedCounts = createSelector([getSaveMetaData], metaData => {
  const { success, error, pending } = metaData;

  const completed = success.length + error.length;
  const total = completed + pending.length;

  return { completed, total };
});

export const getRawData = state => _.get(state, 'dts.rawData', {});

export const getLoadingDetails = state => _.get(state, 'dts.loading', {});

export const getLoading = state => {
  const loadingObj = getLoadingDetails(state);
  let loading = false;
  for (const key in loadingObj) {
    if (Object.hasOwnProperty.call(loadingObj, key)) {
      loading = loading || loadingObj[key];
    }
  }
  return loading;
};

export const getIsSaving = state => {
  const loadingObj = getLoadingDetails(state);

  return !!loadingObj.saving;
};

export const getIsDirty = state => _.get(state, 'dts.isDirty', false);

export const getTableColumns = state => _.get(state, 'dts.tableColumns', []);
export const getTemplateError = state =>
  _.get(state, 'dts.templateError', false);

export const getGroupOptions = state => _.get(state, 'dts.groupOptions', {});

export const getDealMemos = state => _.get(state, 'dts.dealMemos', []);
export const getInvalidDeals = state => _.get(state, 'dts.invalidDeals', {});

export const getStoredSort = state => _.get(state, 'dts.storedSort', []);
export const getEmployeeCount = state =>
  _.get(state, 'dts.rawData.employeeCount', 0);

export const getHiddenColumns = state =>
  _.get(state, 'dts.initialHiddenColumns', []);

export const getEffectiveDate = state =>
  _.get(state, 'dts.effectiveDate', null);

export const {
  getCustomFilter,
  getListByFilterName,
  getFilteredDropdown,
  getSelectedCount,
} = makeFilterSelectors('dts');

export const getFiltersPristine = state =>
  _.get(state, 'dts.filtersPristine', true);

export const getFilters = state => _.get(state, 'dts.filters', {});

export const getAllFilters = createSelector([getFilters], filters => {
  const currentFilters = [];
  for (const filterName in filters) {
    if (Object.hasOwnProperty.call(filters, filterName)) {
      const array = filters[filterName];
      let allOptionsSelected = true;
      const apiFilter = {
        field: filterName,
        type: 'key',
        values: array.reduce((acc, val) => {
          if (val.selected) {
            acc.push(val.id);
          } else {
            allOptionsSelected = false;
          }
          return acc;
        }, []),
      };

      if (apiFilter.field === 'offerStatus') {
        // currently If A is selected then N should also needs to send in the payload
        if (apiFilter.values.includes('A')) {
          apiFilter.values.push('N');
          apiFilter.values.push('');
        } else {
          const findIndex = apiFilter.values.indexOf('N');
          if (findIndex !== -1) {
            apiFilter.values.splice(findIndex, 1);
          }
        }
      }
      apiFilter.selectAll = allOptionsSelected;

      if (apiFilter.values.length > 0) currentFilters.push(apiFilter);
    }
  }

  return currentFilters;
});

export const getModalAction = state =>
  _.get(state, 'dts.modalAction', { func: null, args: null });

export const getPostSaveAction = state =>
  _.get(state, 'dts.postSaveAction', { func: null });

// drop down options

export const getPaySchedulesOptions = state => {
  const schedules = _.get(state, 'dts.groupOptions.schedule', []);
  return schedules.map(s => ({ ...s, type: 'workSchedule' }));
};
export const getEpisodes = state => {
  return _.get(state, 'dts.groupOptions.episode', []);
};

//Solo options selectors
export const getDayTypeOptions = state =>
  _.get(state, 'dts.soloOptions.dayType', []);

export const getLocationTypes = state =>
  _.get(state, 'dts.soloOptions.locationType', []);

export const getOccCodeOptions = state =>
  _.get(state, 'dts.soloOptions.occupationCode', []);

export const getDealMemoOptions = state =>
  _.get(state, 'dts.soloOptions.dealMemo', []);

export const getSoloOptionsLoading = state =>
  _.get(state, 'dts.optionsLoading', {});

export const getRounding = state => _.get(state, 'dts.rounding', {});

export const getDeleteJobId = state => _.get(state, 'dts.deleteJobId', null);
export const getDeletedEmployees = state =>
  _.get(state, 'dts.deletedEmployees', []);

export const getAllDayTypes = state => _.get(state, 'dts.allDayTypes', {});
