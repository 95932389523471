import { produce } from 'immer';
import _ from 'lodash';
import * as actions from './actions';
import {
  COLUMNS,
  // eslint-disable-next-line no-unused-vars
  db,
} from './empTimecardUtils';

const initialState = {
  timecard: {},
  loading: {
    timecard: false,
    dealMemos: false,
    history: false,
    delete: false,
    allowanceDelete: false,
    saveAllowance: false,
    timecardList: false,
    comments: false,
    checkCompatibility: false,
    saving: false,
    submittingEmp: false,
  },
  columns: _.cloneDeep(COLUMNS),
  dealMemos: [],
  useMilitary: true,
  selectedDay: {
    day: null,
    member: null,
    index: null,
  },
  copyToOtherDays: [],
  allowanceTypes: {},
  dayTypes: [],
  workLocations: [],
  episodes: [],
  isMobile: false,
  savedOnce: false, // only used by rejected timecard
  fieldPreReq: {
    timecardReady: false,
    fieldsReady: false,
  },
  comments: [],
  timecardHistory: [],
  newComment: '',
  compatible: null,
  timecardList: [],
  userLoadingStatus: 'loading',
  endsOn: '',
  deletedTimecardId: null,
  selectedCommentDay: {},
  roundTos: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      }
      case `${actions.setLoading}`: {
        const variant = action.variant;
        draft.loading[variant] = action.loading;
        break;
      }
      case `${actions.storeTimecard}`: {
        draft.timecard = action.timecard;
        break;
      }
      case `${actions.storeAllowances}`: {
        draft.timecard.allowances = action.allowances;
        break;
      }
      case `${actions.setUseMilitary}`: {
        draft.useMilitary =
          action.useMilitary === undefined
            ? draft.useMilitary
            : action.useMilitary;
        break;
      }
      case `${actions.storeDealMemos}`: {
        draft.dealMemos = action.dealMemos;
        break;
      }
      case `${actions.storeSelectedDay}`: {
        draft.selectedDay.day = action.day;
        draft.selectedDay.member = action.member;
        draft.selectedDay.index = action.index;
        break;
      }
      case `${actions.setTCCopyToOtherDays}`: {
        draft.copyToOtherDays = action.copyToOtherDays;
        break;
      }
      case `${actions.storeDayTypes}`: {
        draft.dayTypes = action.dayTypes;
        break;
      }
      case `${actions.storeAllowanceTypes}`: {
        if (!action.projectId) {
          console.error('projectId is required');
          return;
        }
        if (Array.isArray(action.allowanceTypes) === false) {
          console.error('allowanceTypes must be an array');
          return;
        }

        const allowanceTypes = action.allowanceTypes.map(t => ({
          id: t.id,
          name: t.description,
          code: t.code,
          dayTypes: t.dayTypes,
          htgAllowanceTypeId: t.id,
          employeeMandatory: t.employeeMandatory || false,
          employeeVisible: t.employeeVisible || false,
          category: t.category,
        }));

        draft.allowanceTypes[action.projectId] = allowanceTypes;
        break;
      }
      case `${actions.storeWorkLocations}`: {
        draft.workLocations = action.workLocations;
        break;
      }
      case `${actions.storeEpisodes}`: {
        draft.episodes = action.episodes;

        break;
      }
      case `${actions.storeIsMobile}`: {
        draft.isMobile = action.isMobile;
        break;
      }
      case `${actions.storeColumns}`: {
        draft.columns = action.columns;
        break;
      }
      case `${actions.updateAdditionalFields}`: {
        const { fields } = action;

        const columns = draft.columns;

        fields.forEach(field => {
          const column = columns.find(c => c.accessor === field.accessor);
          if (column) {
            column.display = field.display;
            column.isDescriptionVisible = field.isDescriptionVisible;
          }
          if (column.pairName) {
            const pairColumn = columns.find(
              c => c.accessor === column.pairName,
            );
            if (pairColumn) {
              pairColumn.display = field.display;
            }
          }
        });

        break;
      }
      case `${actions.setFieldVisibilityPreReq}`: {
        draft.fieldPreReq = action.fieldPreReq;
        break;
      }
      case `${actions.updateFieldVisibility}`: {
        const columns = draft.columns; // columns data already parsed with Settings
        const timecard = Object.freeze(state.timecard); //Read only
        const hiddenColIndexes = [];
        columns.forEach((column, index) => {
          if (
            (column.display === 'hide' || column.display === 'additional') &&
            column.type === 'time'
          ) {
            hiddenColIndexes.push(index);
          }
        });

        timecard.days.forEach(day => {
          hiddenColIndexes.forEach(index => {
            const column = columns[index];
            if (day[column.accessor]) {
              column.display = 'show';
            }
            if (
              column.pairName &&
              (day[column.pairName] || day[column.accessor])
            ) {
              const pairColumn = columns.find(
                c => c.accessor === column.pairName,
              );
              pairColumn.display = 'show';
              column.display = 'show';
            }
          });
        });

        draft.columns = columns;

        break;
      }
      case `${actions.storeComments}`: {
        draft.comments = action.comments;
        break;
      }
      case `${actions.storeTimecardHistory}`: {
        draft.timecardHistory = action.history;
        break;
      }
      case `${actions.setNewComment}`: {
        draft.newComment = action.newComment;
        break;
      }
      case `${actions.storeCompatibility}`: {
        draft.compatible = action.compatible;
        break;
      }
      case `${actions.storeTimecardList}`: {
        draft.timecardList = action.timecardList;
        break;
      }
      case `${actions.setUserLoadingStatus}`: {
        draft.userLoadingStatus = action.userLoadingStatus;
        break;
      }
      case `${actions.setEndsOn}`: {
        draft.endsOn = action.endsOn;
        break;
      }
      case `${actions.setDeletedTimecardId}`: {
        draft.deletedTimecardId = action.timecardId;
        break;
      }
      case `${actions.setSavedOnce}`:
        if (action.reset) {
          draft.savedOnce = false;
          break;
        }

        draft.savedOnce = action.savedOnce;
        break;
      case `${actions.selectedCommentDay}`: {
        draft.selectedCommentDay = action.selectedCommentDay;
        break;
      }
      case `${actions.storeRoundTos}`: {
        draft.roundTos = action.roundTos;
        break;
      }
      default:
    }
  });
