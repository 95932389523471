import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Box, IconButton, Tooltip } from '@mui/material';
import DealAllowanceInfo from './DealAllowanceInfo';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const CheckboxCell = props => {
  const {
    disabled,
    baseOnChange,
    baseOnBlur,
    localVal,
    toggleCTA,
    id,
    column: { id: columnId },
    row,
    toggleCapTrack,
  } = props;

  const isAutoAllowance = columnId === 'isAutoDailyAllowance';

  const hasDailyAllowances =
    row?.original?.dealMemoTC?.dealMemoAllowances?.filter(
      a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
    )?.length > 0;

  const hasWeeklyAllowances =
    row?.original?.dealMemoTC?.dealMemoAllowances?.filter(
      a => a.frequency === 'F' && (a.payCode1 || a.payCode2),
    )?.length > 0;

  let showCheckBox = true;

  const status = row.original.userFriendlyTimecardStatus;
  if (
    isAutoAllowance &&
    (!hasDailyAllowances ||
      (status !== '' && status !== 'Draft' && localVal === null))
  ) {
    showCheckBox = false;
  }

  const capTrackerVisible = row.original.showCapTrack;

  const boxSX = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    width: '95%',
  };

  return (
    <Box sx={boxSX}>
      <Box>
        {showCheckBox && (
          <Checkbox
            id={id}
            sx={{ p: 0 }}
            disabled={disabled || (isAutoAllowance && !hasDailyAllowances)}
            color={'primary'}
            checked={localVal || false}
            onChange={e => {
              baseOnChange(e.target.checked);
              if (window.navigator.userAgent.includes('Safari')) {
                e?.target?.focus();
              }
            }}
            onFocus={() => toggleCTA(true)}
            onBlur={() => {
              toggleCTA(false);
              baseOnBlur();
            }}
          />
        )}
      </Box>

      <Box>
        {isAutoAllowance && (hasWeeklyAllowances || hasDailyAllowances) && (
          <Box sx={{ display: 'flex', gap: '14px', alignItems: 'center' }}>
            <DealAllowanceInfo row={row} />
            <Tooltip
              placement="top"
              title={`${capTrackerVisible ? 'Hide' : 'View'} Cap Tracker`}
            >
              <IconButton
                className={'PENDO_cap_dts_eye_icon'}
                sx={{ p: 0 }}
                onClick={() => {
                  toggleCapTrack();
                }}
              >
                {capTrackerVisible ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <RemoveRedEyeOutlinedIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CheckboxCell.propTypes = {
  disabled: PropTypes.bool,
  baseOnChange: PropTypes.func.isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  localVal: PropTypes.bool,
  toggleCTA: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  row: PropTypes.object.isRequired,
  toggleCapTrack: PropTypes.func,
};

export default CheckboxCell;
