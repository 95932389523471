import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';

import { Box, LinearProgress, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Button, TabHeader, TabPaper } from '../Shared/styledComponents';
import AllowanceCard from '../Shared/AllowanceCard';
import ShowCapBtn from 'feature/CapTracking/ShowCapBtn';
import { show as showModal } from 'actions/modalDialog';
import { ALLOWANCE_DIALOG } from '../Modals/modalNames';
import { NEW_ALLO_ID } from 'feature/EmployeeTimecard/empTimecardUtils';

import * as sel from '../selectors';
import { getCapTrackingSettings } from 'selectors/settings';

const mapState = (state, ownProps) => {
  return {
    allowances: sel.getVisibleAllowances(state),
    loading: sel.getLoading(state, 'allowances'),
    disabledBtns: sel.getDisabledBtns(state),
    timecardStatus: sel.getTimecardStatus(state),
    capTrackingEnabled: getCapTrackingSettings(state),
  };
};

const mapDispatch = dispatch => ({
  onAddAllowance: params => {
    dispatch(showModal({ dialog: ALLOWANCE_DIALOG, modalParams: params }));
  },
});

const AllowanceTab = props => {
  const {
    onAddAllowance,
    loading,
    allowances = [],
    disabledBtns,
    timecardStatus,
    capTrackingEnabled = false,
    employeeId,
  } = props;

  const { disableActions } = disabledBtns;

  const isReadyForMe = timecardStatus === 'pending_employee_review';
  return (
    <TabPaper>
      {loading && <LinearProgress />}
      <TabHeader>
        <Box sx={{ fontWeight: 'bold' }}>Allowances</Box>
        <Tooltip
          title={`${
            disableActions && isReadyForMe
              ? 'Click Edit to enable changes to this timecard'
              : ''
          }`}
          placement="top"
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            {capTrackingEnabled && employeeId && (
              <ShowCapBtn employeeId={employeeId} />
            )}
            <Button
              disabled={disableActions}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => onAddAllowance({ worksightId: NEW_ALLO_ID })}
            >
              Add Allowances
            </Button>
          </Box>
        </Tooltip>
      </TabHeader>

      <Box sx={{ display: 'flex', gap: 2, p: 2, flexWrap: 'wrap' }}>
        {!loading && allowances.length === 0 && (
          <Box sx={{ color: 'text.secondary' }}>No allowances</Box>
        )}
        {allowances.map(allowance => (
          <AllowanceCard key={allowance.worksightId} allowance={allowance} />
        ))}
      </Box>
    </TabPaper>
  );
};

AllowanceTab.propTypes = {
  allowances: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onAddAllowance: PropTypes.func.isRequired,
  disabledBtns: PropTypes.object.isRequired,
  timecardStatus: PropTypes.string.isRequired,
  capTrackingEnabled: PropTypes.bool.isRequired,
  employeeId: PropTypes.string,
};

export default compose(connect(mapState, mapDispatch))(AllowanceTab);
