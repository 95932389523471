import React from 'react';
import clsx from 'clsx';
import { TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  headerRow: {
    backgroundColor: palette.misc.tableHeader,
    whiteSpace: 'nowrap',
  },
  headerCell: {
    color: palette.text.secondary,
    padding: '20px 8px 0px',
    border: 'none',
  },
  rowHeaderIcon: {
    backgroundColor: palette.misc.tableHeader,
    position: 'sticky',
    left: -1,
    top: 0,
  },
  rowHeader: {
    backgroundColor: palette.misc.tableHeader,
    position: 'sticky',
    left: 45,
    top: 0,
  },
  //Mobile only CSS
  [breakpoints.down('lg')]: {
    headerRow: {
      whiteSpace: 'normal',
    },
    rowHeader: {
      whiteSpace: 'normal',
    },
    tableCell: {
      fontSize: 11,
      // maxWidth: 25,
    },
  },
}));

const SelectTableHeaderRow = ({ fields, hasProvisionalDeal }) => {
  const classes = useStyles();
  const headerCells = fields.map((field, index) => {
    const cellClasses = clsx(classes.headerCell, {
      [classes.rowHeaderIcon]: index === 0,
      [classes.rowHeader]: hasProvisionalDeal && index === 1,
    });

    return (
      <TableCell
        key={`${field.label}-${JSON.stringify(field.dataLoc)}`}
        className={cellClasses}
      >
        {field.label}
      </TableCell>
    );
  });

  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.headerRow}>{headerCells}</TableRow>
    </TableHead>
  );
};

SelectTableHeaderRow.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default SelectTableHeaderRow;
