import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import clsx from 'clsx';

import { TableCell } from '@mui/material';
import { Checkbox } from 'components/Shared/redux';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    verticalAlign: 'middle',
    padding: 4,
  },
  input: {
    '& div': {
      width: 'auto',
      height: 'auto',
    },
  },
}));

const CheckboxCell = props => {
  const classes = useStyles();
  const {
    field: { columnId },
    wtcDisabled,
    disableCellClass = '',
    member = '',
    isPartialDealMemo = false,
    isUnusedDay = false,
    onChange = () => {},
    isMasterRow = false,
    cellCoordClass,
  } = props;

  const name = isMasterRow ? columnId : `${member}.${columnId}`;

  const isUnusedDisabled = isUnusedDay && columnId !== 'dayType';

  const disabled = wtcDisabled || isUnusedDisabled || isPartialDealMemo;

  return (
    <TableCell
      key={name}
      className={clsx(classes.tableCell, {
        [disableCellClass]: isPartialDealMemo || isUnusedDay,
      })}
    >
      <Field
        className={classes.input}
        component={Checkbox}
        name={name}
        color="primary"
        disabled={disabled}
        onChange={onChange}
        inputProps={{
          className: cellCoordClass,
        }}
      />
    </TableCell>
  );
};

CheckboxCell.propTypes = {
  field: PropTypes.object.isRequired,
  isMasterRow: PropTypes.bool,
  member: PropTypes.string,
  wtcDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  isPartialDealMemo: PropTypes.bool,
  isUnusedDay: PropTypes.bool,
  disableCellClass: PropTypes.string,
};

export default React.memo(CheckboxCell);
