import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Bell from 'components/images/Bell';
import { isInstantDealTimecard } from 'utils/wtcWeekUtils';

const style = ({ palette }) => ({
  root: {
    maxWidth: 375,
    minWidth: 375,
    marginTop: '16px !important',
  },
  hasError: {
    border: `2px solid ${palette.error.main}`,
  },
  collapsed: {
    maxWidth: 170,
    minWidth: 170,
  },
  notificationTitle: {
    fontSize: 18,
    fontWeight: 500,
    paddingLeft: 16,
  },
  notificationsCount: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 6,
    marginRight: 10,
    padding: '1px 3px 1px 2px',
    borderRadius: 3,
  },
  expansionSummary: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    color: palette.text.secondary,
    fontSize: 18,
    marginBottom: 8,
  },
  notificationTitleArea: {
    display: 'flex',
    alignItems: 'center',
  },
  expandedTitle: {},
  collapsedTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    height: '100%',
  },
  summaryCollapsed: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 0,
    '& .MuiAccordionSummary-content': {
      paddingLeft: 0,
      justifyContent: 'center',
    },
  },
  AccordionDetails: {
    padding: 0,
  },
  container: {
    width: '100%',
  },
  emptyNotifications: {
    border: `1px dashed ${palette.gray['+7']} `,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 400,
    color: palette.primary.main,
    fontWeight: 500,
    '& section': {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-around',
      alignItems: 'center',
    },
  },
  allNotifications: {
    minHeight: 512,
    overflow: 'auto',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bodyGroup: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
    gap: 8,
  },
  icon: {
    alignSelf: 'center',
  },
  message: {
    lineHeight: 0,
  },
  errors: {
    // red
    color: palette.error.main,
  },
  warnings: {
    // orange
    color: palette.warning.main,
  },
  infos: {
    // blue
    color: palette.info.main,
  },
});

const Notifications = ({
  classes,
  warnings = [],
  errors = [],
  infos = [],
  timecard,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const isInstantDealMemoTC = isInstantDealTimecard(timecard);

  const counts = {
    errors: errors.length,
    warnings: isInstantDealMemoTC ? warnings.length + 1 : warnings.length,
    infos: infos.length,
  };

  let count = warnings.length + errors.length + infos.length;
  if (isInstantDealMemoTC) {
    count += 1;
  }

  let notificationsCount = count >= 10 ? count : '0' + count;

  const addDupCount = msgArray => {
    const obj = {};
    msgArray.forEach(msg => {
      obj[msg] = obj[msg] === undefined ? 1 : obj[msg] + 1;
    });
    return Object.keys(obj).map(msg => {
      const count = obj[msg] === 1 ? '' : ` (${obj[msg]})`;
      return `${msg}${count}`;
    });
  };

  errors = addDupCount(errors);
  warnings = addDupCount(warnings);
  infos = addDupCount(infos);
  let isInstantDealMemoText = ['Offer Pending Approval'];

  const renderNotes = (array, setting) => {
    return array.map((message, i) => {
      let strippedMessage = message.replace(/(<([^>]+)>)/gi, '');
      return (
        <div
          key={strippedMessage}
          className={`${classes.bodyGroup} ${classes[setting]}`}
        >
          <div className={classes.icon}>
            {setting === 'infos' ? <AccessTimeIcon /> : <WarningIcon />}
          </div>
          <Typography>{strippedMessage}</Typography>
        </div>
      );
    });
  };

  const renderIcon = setting => {
    if (counts[setting] === 0) return null;

    return (
      <div key={setting} className={`${classes.bodyGroup} ${classes[setting]}`}>
        <div className={classes.icon}>
          {setting === 'infos' ? <AccessTimeIcon /> : <WarningIcon />}
        </div>
        <Typography>{counts[setting]}</Typography>
      </div>
    );
  };

  const notificationErrors = [
    renderNotes(errors, 'errors'),
    renderNotes(infos, 'infos'),
    renderNotes(warnings, 'warnings'),
  ];
  if (isInstantDealMemoTC) {
    notificationErrors.unshift(renderNotes(isInstantDealMemoText, 'warnings'));
  }

  return (
    <Accordion
      className={clsx(classes.root, {
        [classes.collapsed]: !expanded,
        [classes.hasError]: errors.length > 0,
      })}
      expanded={expanded}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ height: 20, width: 20 }} />}
        variant="iconOnRight"
        classes={{
          root: clsx(classes.expansionSummary, {
            [classes.summaryCollapsed]: !expanded,
          }),
        }}
      >
        {expanded && (
          <Box className={classes.expandedTitle}>
            <span className={classes.notificationTitle}>Notifications</span>

            <span className={classes.notificationsCount}>
              {notificationsCount}
            </span>
          </Box>
        )}
        {!expanded && (
          <Box className={classes.collapsedTitle}>
            <Box sx={{ px: 1, fontSize: '18px', fontWeight: '500' }}>
              Notifications
            </Box>
            <Box
              className={classes.notificationsCount}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {notificationsCount}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                px: 1,
              }}
            >
              {[
                renderIcon('errors'),
                renderIcon('infos'),
                renderIcon('warnings'),
              ]}
            </Box>
          </Box>
        )}
      </AccordionSummary>

      <AccordionDetails className={classes.AccordionDetails}>
        <div className={classes.container}>
          <div className={classes.allNotifications}>
            {count > 0 ? (
              notificationErrors
            ) : (
              <div className={classes.emptyNotifications}>
                <section>
                  <Bell />
                  <Typography>You have no notifications</Typography>
                </section>
              </div>
            )}
          </div>
        </div>
        <div />
      </AccordionDetails>
    </Accordion>
  );
};

Notifications.propTypes = {
  warnings: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  infos: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Notifications);
