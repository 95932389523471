import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
//components
import { useTable, useSortBy, useRowSelect } from 'react-table';
import Header from './Header';
import HeaderRow from './HeaderRow';
import StatusFilter from './StatusFilter';
import Row from './Row';
import EmptyState from 'containers/Employees/Reviews/Shared/EmptyState';
import EmergencyTimecard from '../../../../containers/Employees/CrewTimecard/Modals/EmergencyTimecard';
import { DH } from 'components/props/profiles';
import SubmitButton from 'components/Shared/SubmitButton';
//constants + utils
import {
  DEFAULT_HIDDEN_COLUMNS,
  TABLE_COLUMNS,
  canTimecardBeSelected,
} from './searchUtils';
import LoadingSquare from 'containers/Employees/Reviews/Shared/LoadingSquare';

const useStyles = makeStyles(theme => ({
  emptyState: {
    margin: 'auto',
  },
  showTableContainer: {
    marginTop: 20,
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 'calc((100vh - 440px) * .93)',
    overflowX: 'scroll',
    overflowY: 'scroll',
    transition: 'width 0.3s;',
  },
  withFilters: {
    maxWidth: 'calc(99vw - 360px)',
  },
  withoutFilters: {
    maxWidth: 'calc(99vw - 133px)',
  },
  windowWithFilters: {
    width: 'calc(99vw - 360px)',
  },
  windowWithoutFilters: {
    width: 'calc(99vw - 133px)',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0px',
  },
  loadMoreBtn: {
    marginBottom: 4,
    marginLeft: 0,
    width: 115,
    height: 40,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
}));

/**
 * React-table for searchTimecard
 * @param {array} data - Array of timecards, sorted by backend
 * @param {num} totalCount - Total timecards that match current filters
 * @param {bool} loading - Are we loading new data?
 * @param {bool} showFilters - Are filters displayed in the left panel?
 * @param {array} sortBy - saved sort in Redux.  Will be [] for default sort
 *
 * Dispatch Functions
 * @param {func} onSearchFetch - only fetch data, no filter change.
 * @param {func} onLoadMore
 * @param {func} onStoreSort - save current sort in redux if user navigates away from page
 * @param {func} onClickTimecard

 */
const SearchTable = props => {
  const {
    currentUserRole,
    data,
    totalCount,
    sortBy,
    loading,
    isModalVisible,
    showFilters,
    onStoreSort,
    onSearchFetch,
    onLoadMore,
    onClickTimecard,
    onShowSubmitModal,
    onSubmitEmergencyTimecards,
    onShowDeleteModal,
    onOpenTimecards,
    permissions,
    selectAllFlag,
    onSetSelectAllFlag,
    submitting,
    dhGrossEnabled,
    bulkTimecardSubmission,
    deleting,
  } = props;
  const classes = useStyles();
  const showCount = data.length;
  const isEmpty = showCount === 0;
  const showLoadMore = showCount < totalCount;

  const [filteredColumns, setFilteredColumns] = useState(TABLE_COLUMNS);

  if (totalCount < showCount) {
    console.error(
      `Search Error. Data count (${showCount}) should not be more than total: ${totalCount}`,
    );
  }

  useEffect(() => {
    //
    // Columns based on current user roll
    //
    if (!dhGrossEnabled && currentUserRole === DH) {
      const dhColumns = TABLE_COLUMNS.filter(c => {
        if (c.id === 'laborTotal' || c.id === 'grossTotal') return false;
        return true;
      });
      setFilteredColumns(dhColumns);
    }
  }, [currentUserRole, dhGrossEnabled]);

  const reactTable = useTable(
    {
      columns: filteredColumns,
      data,
      initialState: {
        hiddenColumns: DEFAULT_HIDDEN_COLUMNS,
        sortBy,
        currentUserRole,
      },
      disableMultiSort: true,
      manualSortBy: true,
      currentUserRole,
    },
    useSortBy,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setSortBy: setSortReactTable,
    state: { hiddenColumns },
    selectedFlatRows,
    setHiddenColumns,
    toggleAllRowsSelected,
  } = reactTable;

  const [selectedRows, setSelectedRows] = useState([]);

  //pass only timecards that can be selected, select all puts all in by default
  useEffect(() => {
    const newRows = selectedFlatRows.filter(tc =>
      canTimecardBeSelected(currentUserRole, tc.values.status),
    );
    setSelectedRows(newRows);
  }, [selectedFlatRows, currentUserRole]);

  // setSortBy in Redux, then useEffect to watch that and set react-table accordingly
  const setSortBy = newSort => {
    onStoreSort(newSort.slice());
    onSearchFetch();
  };
  useEffect(() => {
    setSortReactTable(sortBy.slice());
  }, [setSortReactTable, sortBy]);

  useEffect(() => {
    if (selectAllFlag && rows.length > 0) {
      toggleAllRowsSelected(true);
      onSetSelectAllFlag({ selectAllFlag: false });
    }
    disableLoadMoreRef.current = false;
  }, [onSetSelectAllFlag, rows, selectAllFlag, toggleAllRowsSelected]);

  const disableLoadMoreRef = useRef(false);

  return (
    <div>
      <div
        className={clsx({
          [classes.overlay]: deleting,
        })}
      ></div>
      {/* //Main Table Display here and below */}
      <div
        className={clsx(classes.showTableContainer, {
          [classes.windowWithFilters]: showFilters,
          [classes.windowWithoutFilters]: !showFilters,
        })}
      >
        <Header
          currentUserRole={currentUserRole}
          bulkTimecardSubmission={bulkTimecardSubmission}
          rows={rows}
          selectedRows={selectedRows}
          onShowSubmitModal={onShowSubmitModal}
          permissions={permissions}
          setHiddenColumns={setHiddenColumns}
          hiddenColumns={hiddenColumns}
          onSubmitEmergencyTimecards={onSubmitEmergencyTimecards}
          onShowDeleteModal={onShowDeleteModal}
          filteredColumns={filteredColumns}
          submitting={submitting}
          showCount={showCount}
          onOpenTimecards={onOpenTimecards}
        />
        <StatusFilter />
        {submitting && !isModalVisible && <LinearProgress />}
        {isEmpty ? (
          loading ? (
            <LoadingSquare />
          ) : (
            <EmptyState className={classes.emptyState} variant="noResults" />
          )
        ) : (
          <>
            <main
              className={clsx(classes.tableContainer, {
                [classes.withFilters]: showFilters,
                [classes.withoutFilters]: !showFilters,
              })}
            >
              <table {...getTableProps()} className={classes.table}>
                <HeaderRow headerGroups={headerGroups} setSortBy={setSortBy} />
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => (
                    <Row
                      prepareRow={prepareRow}
                      row={row}
                      rowIndex={rowIndex}
                      key={row?.original?.timecardEntryHeaderId}
                      onClickTimecard={onClickTimecard}
                      currentUserRole={currentUserRole}
                    />
                  ))}
                </tbody>
              </table>
            </main>
            <footer className={classes.footer}>
              {showLoadMore && (
                <SubmitButton
                  color="primary"
                  variant="contained"
                  className={classes.loadMoreBtn}
                  onClick={() => {
                    disableLoadMoreRef.current = true;
                    onLoadMore();
                  }}
                  disabled={loading || disableLoadMoreRef.current}
                >
                  Load more
                </SubmitButton>
              )}
              <div />
              Showing {`${showCount} out of ${totalCount}`}
            </footer>
          </>
        )}
      </div>

      <EmergencyTimecard
        selectedRowIds={selectedRows
          .filter(row => row.values.status === 'Draft')
          .map(row => row.original.timecardId)}
        searchTCEmergency
      />
    </div>
  );
};

SearchTable.propTypes = {
  data: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  sortBy: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  onSearchFetch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onClickTimecard: PropTypes.func.isRequired,
  dhGrossEnabled: PropTypes.bool,
  currentUserRole: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onShowSubmitModal: PropTypes.func.isRequired,
  onSubmitEmergencyTimecards: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  onOpenTimecards: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  selectAllFlag: PropTypes.bool.isRequired,
  onSetSelectAllFlag: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  bulkTimecardSubmission: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};
export default SearchTable;
