import React from 'react';
import { useTheme } from '@mui/material';

const InstantDealInfoAlert = () => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <rect
          x="5.6665"
          y="5.66663"
          width="24.6667"
          height="24.6667"
          rx="12.3333"
          stroke={palette.primary['+6']}
          strokeWidth="2"
        />
      </g>
      <g opacity="0.1">
        <rect
          x="1.5"
          y="1.5"
          width="33"
          height="33"
          rx="16.5"
          stroke={palette.primary['+6']}
          strokeWidth="2"
        />
      </g>
      <g clipPath="url(#clip0_5979_1802)">
        <path
          d="M17.9998 21.3333V18M17.9998 14.6666H18.0082M9.6665 15.1022V20.8977C9.6665 21.1015 9.6665 21.2034 9.68953 21.2993C9.70994 21.3843 9.74361 21.4656 9.7893 21.5402C9.84084 21.6243 9.9129 21.6964 10.057 21.8405L14.1593 25.9428C14.3034 26.0869 14.3755 26.159 14.4596 26.2105C14.5342 26.2562 14.6154 26.2899 14.7005 26.3103C14.7964 26.3333 14.8983 26.3333 15.1021 26.3333H20.8976C21.1014 26.3333 21.2033 26.3333 21.2992 26.3103C21.3842 26.2899 21.4655 26.2562 21.5401 26.2105C21.6242 26.159 21.6962 26.0869 21.8404 25.9428L25.9426 21.8405C26.0868 21.6964 26.1588 21.6243 26.2104 21.5402C26.2561 21.4656 26.2897 21.3843 26.3101 21.2993C26.3332 21.2034 26.3332 21.1015 26.3332 20.8977V15.1022C26.3332 14.8984 26.3332 14.7965 26.3101 14.7006C26.2897 14.6156 26.2561 14.5343 26.2104 14.4597C26.1588 14.3756 26.0868 14.3036 25.9426 14.1594L21.8404 10.0572C21.6962 9.91302 21.6242 9.84096 21.5401 9.78943C21.4655 9.74374 21.3842 9.71006 21.2992 9.68965C21.2033 9.66663 21.1014 9.66663 20.8976 9.66663H15.1021C14.8983 9.66663 14.7964 9.66663 14.7005 9.68965C14.6154 9.71006 14.5342 9.74374 14.4596 9.78943C14.3755 9.84096 14.3034 9.91302 14.1593 10.0572L10.057 14.1594C9.9129 14.3036 9.84084 14.3756 9.7893 14.4597C9.74361 14.5343 9.70994 14.6156 9.68953 14.7006C9.6665 14.7965 9.6665 14.8984 9.6665 15.1022Z"
          stroke={palette.primary['+6']}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5979_1802">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstantDealInfoAlert;
