import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Paper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';

import {
  ContentBox,
  TitleBox,
  FooterBox,
  Button,
} from 'feature/EmployeeTimecard/Shared/styledComponents';

import { deleteCap } from './actions';
import { hide as hideModal } from 'actions/modalDialog';

import withModalDialog from 'decorators/withModalDialog';

import { getCapDeleting } from './selectors';
import { getModalParams } from 'selectors/modalDialog';

import { deleteCapModal } from './capUtils';
import CapTrackingItem from './CapTrackingItem';

const mapState = state => ({
  modalParams: getModalParams(state, deleteCapModal),
  deleting: getCapDeleting(state),
});
const mapDispatch = dispatch => ({
  onHideModal: () => dispatch(hideModal({ dialog: deleteCapModal })),
  onDelete: (cap, employeeId) => dispatch(deleteCap(cap, employeeId)),
});

const useStyles = makeStyles(({ palette }) => ({
  CapDelete: {},
  WarningText: {
    fontSize: '14px',
    width: '300px',
    textAlign: 'justify',
    marginBottom: '10px',
  },
  button: {
    width: '140px',
    marginLeft: '0px',
  },
}));

const CapTrackingDeleteModal = props => {
  const { onHideModal, modalParams, onDelete, employeeId, deleting } = props;
  const classes = useStyles();

  const isNonEmptyCapItem = !_.isEmpty(modalParams);
  return (
    <Paper className={classes.CapDelete}>
      <TitleBox>
        <Box sx={{ fontWeight: 600, fontSize: '18px' }}>Remove Cap</Box>
        <Box>
          <ClearIcon
            onClick={onHideModal}
            sx={{ color: 'text.secondary', cursor: 'pointer' }}
          />
        </Box>
      </TitleBox>
      <ContentBox>
        <Box className={classes.WarningText}>
          You are about to permanently remove this allowance cap. This action
          can not be undone and another cap can not be added.
        </Box>
        {isNonEmptyCapItem && (
          <CapTrackingItem capItem={modalParams} canEdit={false} />
        )}
      </ContentBox>
      <FooterBox>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <Button
            disabled={deleting}
            className={classes.button}
            variant="outlined"
            onClick={onHideModal}
          >
            Cancel
          </Button>
          <Button
            disabled={deleting}
            className={clsx(classes.button, 'PENDO_cap_delete_confirm')}
            onClick={() => onDelete(modalParams, employeeId)}
          >
            Remove
          </Button>
        </Box>
      </FooterBox>
    </Paper>
  );
};

CapTrackingDeleteModal.propTypes = {
  onHideModal: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  deleting: PropTypes.bool.isRequired,
};
export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({ dialog: deleteCapModal, roundedCorners: true }),
)(CapTrackingDeleteModal);
