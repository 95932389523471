import {
  all,
  takeEvery,
  call,
  put,
  select,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { get, cloneDeep } from 'lodash';
import moment from 'moment';
import { push, replace } from 'redux-first-history';
import camelCase from 'camelcase-keys';
import * as _find from 'lodash/find';
import { formatDateUTC } from 'utils/formatDate';
// import snakeCase from 'snakecase-keys';
import {
  startSubmit,
  stopSubmit,
  reset,
  initialize,
  isPristine,
} from 'redux-form';
// selectors
import { getProject as project } from 'selectors/routeParams';
import { currentUser } from 'selectors/session';
import {
  getTimecardAllowances,
  getWorkLocations,
} from 'selectors/timecard/common';
import { getTimecardAllowances as getDHAllowances } from 'selectors/timecard/form';
import {
  getTimecardDetails,
  getCopyPreviousFlag,
  getLocalNotes,
  getLocalAllowances,
  getUpdatingAutoAllowances,
} from 'selectors/timecards';
import {
  getCurrentTimecard,
  getFromURI,
  getDetails,
} from 'selectors/timecard/common';
import { getTimecardFormValues } from 'selectors/timecard/form';
import {
  getCurrentProjectWorkSight,
  getProjectDetails,
} from 'selectors/project';
import {
  getTimecardHtgContractId,
  getTimecardHtgUnionId,
  getInitialValues,
} from 'selectors/timecard/form';
import { getProjectAllowances } from 'selectors/projectAllowances';
import { getDefaultPayCodes } from 'selectors/flags';

//actions
import { showAlert } from 'actions/alert';
import * as actions from 'actions/timecards';
import { hide as hideModal } from 'actions/modalDialog';
import { initialize as enablePagination } from 'actions/pagination';
import { setDeletedTimecardId } from 'feature/EmployeeTimecard/actions';

//utils
import { DH } from 'components/props/profiles';
import { defaultTimecard, defaultDay } from 'utils';
import {
  convertToFormData,
  composeAllowanceV1,
  TIME_FIELD_ORDER,
  makeNewDHAllowance,
} from 'utils/weekUtils';
import { DEFAUL_PAGE_SIZE } from 'components/Shared/constants';
import {
  isRegionCanada,
  removeNoValueFromTimecard,
  delayOnValue,
  delayOnValueObj,
  setWorkLocationForCanada,
} from 'utils/helperFunctions';
import TimeValidator from 'utils/TimeValidator';

import { ERROR_MESSAGES } from 'components/Shared/constants/ErrorMessages';
import { getSettings } from 'selectors/settings';
import { getDeletedTimecardId } from 'feature/EmployeeTimecard/selectors';

import { db } from 'feature/EmployeeTimecard/empTimecardUtils';

export function* fetchTimecards(api, projectId) {
  const projectDetails = yield delayOnValueObj(getProjectDetails);
  let { worksightId, dbCode } = projectDetails;
  const payload = {
    filters: [
      { field: 'project.id', type: 'key', values: [`${worksightId}`] },
      { field: 'batch.dbCode', type: 'key', values: [`${dbCode}`] },
    ],
  };
  const data = yield call(api.timecards.list, projectId, payload);
  let timecards = camelCase(data, { deep: true });

  const deletedTimecardId = yield select(getDeletedTimecardId);

  if (deletedTimecardId) {
    let beforeLength = timecards.length;
    timecards = timecards.filter(t => t.timecardId !== deletedTimecardId);
    yield put(setDeletedTimecardId({ deletedTimecardId: null }));
    if (beforeLength !== timecards.length) {
      db('Removing deleted timecard from timecards list');
    }
  }

  yield put(actions.store({ timecards }));
}

export function* fetch(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(project);

    yield call(fetchTimecards, api, projectId);

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ timecards: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchWeekendings(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekendings = yield call(api.timecards.weekendings, { projectId });

    yield put(actions.storeWeekendings({ weekendings }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeWeekendings({ weekendings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchWeekendingTimecards(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const { weekEndingdate, departmentId } = params;
    const data = yield call(api.timecards.weekendingTimecard, {
      projectId,
      weekEndingdate,
      departmentId,
    });
    const weekendingTimecards = camelCase(data, { deep: true });

    yield put(actions.storeWeekendingTimecards({ weekendingTimecards }));
    yield put(
      enablePagination({
        grid: 'crew-weekending-timecard',
        totalCount: weekendingTimecards.length,
        pageSize: DEFAUL_PAGE_SIZE, // set to 50
      }),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeWeekendingTimecards({ weekendingTimecards: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

// only get the base level timecard details, without all the supporting data
export function* fetchDetailsOnly(api, debug, params) {
  try {
    const timecardId = params.timecardHeaderId;

    yield put(actions.loadingDetails({ loading: true }));
    const projectId = yield select(project);
    const timecard = yield call(api.timecards.details, {
      projectId,
      timecardId,
    });
    yield put(actions.storeCurrentDetails({ timecard }));

    yield put(actions.loadingDetails({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loadingDetails({ loading: false }));
    yield put(showAlert());
  }
}
export function* fetchDetails(api, debug, params) {
  try {
    const timecardId = params.timecardId;

    yield put(actions.loadingDetails({ loading: true }));
    const projectId = yield select(project);
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const timecard = yield call(api.timecards.details, {
      projectId,
      timecardId,
    });
    if (timecard && !timecard.dealMemo) {
      yield put(
        showAlert({
          message:
            'The deal memo that was used to create this timecard is no longer valid. Please contact support.',
          variant: 'warning',
        }),
      );
    }

    const htgContractId = timecard?.dealMemo?.htgContract?.id;
    const htgUnionId = get(timecard, 'dealMemo.htgUnion.id', '');
    const pensionUnionId = get(timecard, 'dealMemo.pensionUnion.id', '');
    yield call(fetchDayTypes, api, debug, {
      pageSize: -1,
      type: 'dayType',
      parentValue: htgContractId,
    });

    yield call(fetchWorkLocations, api, debug, {
      initialLoad: true,
      options: {
        htgContractId,
        htgUnionId,
      },
    });

    const commentParams = {
      timecardEntryHeaderId: timecard.entryHeaderId,
      timecardId: timecard.id,
    };
    yield call(fetchComments, api, debug, commentParams);

    // convert times to decimals
    convertTimesToDec(timecard, { convert24Plus: true });

    yield checkDayTypes({ api, debug, timecard });
    yield addRoundingToDeal(api, debug, timecard.dealMemo);

    yield put(actions.storeDetails({ timecard }));

    const activeUser = yield select(currentUser);
    const role = activeUser.role;

    if (role === DH) {
      const payCodes = yield call(api.wtc.searchByType, {
        type: 'allowancePayCodeInclude',
        params: {
          options:
            projectWorkSightId && pensionUnionId
              ? { project: projectWorkSightId, union: pensionUnionId }
              : {},
          pageSize: 100,
        },
      });
      const employeeAllowancePayCodes = camelCase(payCodes, { deep: true });
      yield put(
        actions.storeEmployeeAllowancePayCodes({ employeeAllowancePayCodes }),
      );
    } else {
      yield call(fetchEmployeeAllowancePayCodes, api, debug);
    }

    yield put(actions.loadingDetails({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loadingDetails({ loading: false }));
    const errors = e.data ? e.data : null;
    if (errors && errors.Message) {
      yield put(
        showAlert({
          message: errors.Message,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

function* checkDayTypes({ api, debug, timecard }) {
  const days = timecard && timecard.days;

  const results = [];
  //Do any days have htgdaytype id but no day type?
  if (days && days.length > 0) {
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      if (day.dayType === null && day.htgDayTypeId) {
        results[i] = day.htgDayTypeId;
      }
    }
  }

  if (results.length === 0) return;
  const dayTypes = [];
  // Replace ID with dayType object in array
  for (let i = 0; i < results.length; i++) {
    const htgDayTypeId = results[i];
    if (htgDayTypeId) {
      const dayType = yield call(api.timecards.getDayTypeFromHTGId, {
        htgDayTypeId,
      });
      dayTypes[i] = camelCase(dayType);
    }
  }

  for (let i = 0; i < dayTypes.length; i++) {
    const dayType = dayTypes[i];
    if (dayType) {
      timecard.days[i].dayType = cloneDeep(dayType);
    }
  }
}

function* addRoundingToDeal(api, debug, dealMemo) {
  try {
    const projectId = yield select(project);

    if (dealMemo?.id && !dealMemo?.roundTo) {
      const rounding = yield call(api.employees.roundTo, {
        projectId,
        dealMemoIds: [dealMemo.id],
      });
      if (rounding.length > 0) {
        const roundTo = rounding[0].roundTo;
        dealMemo.roundTo = roundTo;
      }
    }
  } catch (error) {
    debug(error);
  }
}

function* checkPrevTcRoundTo(api, data) {
  const { sourceTimecardDealMemoId, htgDealmemoId } = data;

  const projectId = yield select(project);

  if (!sourceTimecardDealMemoId) {
    throw new Error('Missing deal memo id on source timecard');
  }
  if (!htgDealmemoId) {
    throw new Error('Missing deal memo id on new timecard');
  }

  const rounding = yield call(api.employees.roundTo, {
    projectId,
    dealMemoIds: [sourceTimecardDealMemoId, htgDealmemoId],
  });

  const roundTo = rounding.find(r => r.id === htgDealmemoId).roundTo;
  const prevRoundTo = rounding.find(
    r => r.id === sourceTimecardDealMemoId,
  ).roundTo;

  const returnValue = { isRoundToDifferent: false, roundTo };

  if (prevRoundTo !== roundTo) {
    returnValue.isRoundToDifferent = true;
  }
  return returnValue;
}

const reRoundTimecard = timecard => {
  const tv = new TimeValidator(timecard.dealMemo.roundTo);
  let didRoundingChangeValues = false;
  timecard.days.forEach(day => {
    TIME_FIELD_ORDER.forEach(field => {
      const value = day[field];
      if (value) {
        const newValue = tv.parse(`${value}`);
        if (newValue !== value) {
          day[field] = newValue;
          didRoundingChangeValues = true;
        }
      }
    });
  });
  return didRoundingChangeValues;
};

export function* createTimecard(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);

    const formName = params.formName || 'createTimecard';

    const data = yield select(getTimecardDetails(formName));

    const activeUser = yield select(currentUser);
    const projectDetails = yield select(getProjectDetails);
    const region = projectDetails.region;
    let timecard = {};
    timecard.region = region;
    if (data.sourceTimecardId) {
      const settings = yield select(getSettings);
      data.wtcLayoutName =
        activeUser.department?.wtcLayoutName ||
        settings?.wtcLayout?.label ||
        null;

      // Copy from Previous
      const { isRoundToDifferent, roundTo } = yield checkPrevTcRoundTo(
        api,
        data,
      );

      const newTimecard = camelCase(data, { deep: true });

      removeNoValueFromTimecard(newTimecard);
      const formData = new FormData();
      for (const key in newTimecard) {
        if (Object.hasOwnProperty.call(newTimecard, key)) {
          const element = newTimecard[key];
          formData.append(key, JSON.stringify(element));
        }
      }

      const result = yield call(api.employeeTimecard.createTimecard, {
        projectId,
        timecard: formData,
      });
      timecard = camelCase(result, { deep: true });
      timecard.dealMemo.roundTo = roundTo;

      /*
       *  If rounding is different between the two time cards + the new rounding value
       *  we need to update the values on the BE
       */

      if (isRoundToDifferent) {
        convertTimesToDec(timecard, { convert24Plus: true });
        const didChangeValues = reRoundTimecard(timecard);
        if (didChangeValues) {
          convertTimesToMil(timecard, { convert24Plus: true });
          yield call(api.timecards.saveTimecard, {
            projectId,
            timecardId: timecard.id,
            timecard: timecard,
          });
          yield put(
            actions.setCopyFromPrevRoundFlag({ copyFromPrevRoundFlag: true }),
          );
        }
      }
    } else {
      const {
        department,
        batchTemplate: {
          htgCountryId,
          htgStateId,
          htgCityId,
          htgCountyId,
          htgSubdivisionId,
        },
      } = activeUser;

      const countries = yield call(api.locations.countriesV1);
      const country =
        cloneDeep(countries.find(c => c.id === htgCountryId)) || null;

      let countryId = null;
      if (!htgCountryId) {
        const caRegion = isRegionCanada(localStorage.getItem('region'));
        if (caRegion) {
          const ctry = _find(countries, country => country.code === 'CA');
          countryId = ctry?.id;
        } else {
          const ctry = _find(countries, country => country.code === 'US');
          countryId = ctry?.id;
        }
      }

      //fetch states based on htgCountry.  If null get CA if region set ot CA and US states by default
      const states = yield call(api.locations.statesV1, {
        projectId,
        countryId: htgCountryId ?? countryId,
      });

      const state = states.find(state => state.id === htgStateId) || null;

      let cities = [];
      if (htgStateId) {
        cities = yield call(api.locations.citiesV1, { stateId: htgStateId });
      }
      const city = cities?.find(city => city.id === htgCityId) || null;

      let counties = [];
      if (htgCountyId) {
        counties = yield call(api.locations.countiesV1, {
          stateId: htgStateId,
        });
      }
      const county =
        counties?.find(county => county.id === htgCountyId) || null;

      let subdivisions = [];
      if (htgSubdivisionId) {
        subdivisions = yield call(api.locations.subdivisionsV1, {
          pageSize: -1,
        });
      }
      const subdivision =
        subdivisions?.find(
          subdivision => subdivision.id === htgSubdivisionId,
        ) || null;

      const { startsOn, endsOn, htgDealmemoId } = data;
      // dealMemo Call
      const dealMemoList = yield call(api.employees.dealMemos, {
        projectWorkSightId,
        employeeId: activeUser.workSightId,
      });

      const dealMemo = dealMemoList.find(
        dealMemo => dealMemo.id === htgDealmemoId,
      );

      // DayType Call
      const htgContractId = get(dealMemo, 'htgContract.id', '');

      yield call(fetchDayTypes, api, debug, {
        pageSize: -1,
        type: 'dayType',
        parentValue: htgContractId,
      });

      // Work Location Call
      const htgUnionId = get(dealMemo, 'htgUnion.id', '');
      yield call(fetchWorkLocations, api, debug, {
        initialLoad: true,
        options: {
          htgUnionId,
          htgContractId,
        },
      });
      // create timecard
      let id = `fresh-0`;
      const settings = yield select(getSettings);
      timecard = (function () {
        return {
          ...defaultTimecard,
          id,
          htgCountryId,
          country,
          htgStateId,
          state,
          htgCityId,
          county,
          htgCountyId,
          subdivision,
          htgSubdivisionId,
          htgContractId,
          city,
          startsOn,
          endsOn,
          projectId: Number(projectId),
          dealMemo,
          isExempt: !!dealMemo.exempt,
          departmentId: department.id,
          departmentName: department.name,
          wtcLayoutName:
            department?.wtcLayoutName || settings?.wtcLayout?.label || null,
          days: (function () {
            return new Array(7).fill().map((day, i) => {
              return cloneDeep({
                ...defaultDay,
                htgCountryId,
                subdivision,
                htgSubdivisionId,
                country,
                date: `${moment(startsOn)
                  .add(i, 'day')
                  .format('YYYY-MM-DD')}T00:00:00`,
              });
            });
          })(),
        };
      })();
      yield addRoundingToDeal(api, debug, timecard.dealMemo);
    }

    yield put(actions.setCurrentTimecard({ timecardId: timecard.id }));
    yield put(actions.storeDetails({ timecard }));
    yield call(fetchEmployeeAllowancePayCodes, api, debug);
    yield put(actions.loading({ loading: false }));
    yield put(
      push(
        `/projects/${projectId}/me/timecardsv1/${timecard.id}?finishTimecard=true`,
      ),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    const activeUser = yield select(currentUser);
    if (activeUser.batchTemplate === null) {
      yield put(
        showAlert({
          message:
            'This department has not been assigned to a batch template. Contact Plus Support for assistance.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}
export function* fetchWorkLocations(api, debug, params) {
  try {
    const type = 'locationType';

    if (!params.initialLoad) {
      const htgContractId = yield select(getTimecardHtgContractId('timecard'));
      const htgUnionId = yield select(getTimecardHtgUnionId('timecard'));
      params.options = {
        htgUnionId,
        htgContractId,
      };
    }

    const data = yield call(api.timecards.searchByTypes, {
      type,
      params,
    });
    const workLocations = camelCase(data, { deep: true });
    yield put(actions.storeWorkLocations({ workLocations }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchDayTypes(api, debug, params) {
  try {
    const type = 'dayType';
    const parentValue = yield select(getTimecardHtgContractId('timecard'));
    const data = yield call(api.timecards.searchByTypes, {
      type,
      params: parentValue ? { ...params, parentValue, pageSize: -1 } : params,
    });

    const dayTypes = camelCase(data, { deep: true });
    yield put(actions.storeDayTypes({ dayTypes }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchEpisodes(api, debug) {
  try {
    const projectId = yield select(project);

    const data = yield call(api.timecards.getEpisodes, { projectId });
    const episodes = camelCase(data, { deep: true });
    yield put(actions.storeEpisodes({ episodes }));
  } catch (e) {
    debug(e);
  }
}

//new call for note/comments uses .NET
export function* fetchComments(api, debug, params) {
  try {
    const timecardEntryHeaderId = params.timecardEntryHeaderId;
    const projectId = yield select(project);
    const timecardId = params.timecardId;
    const data = yield call(api.reviews.getTimecardComments, {
      projectId,
      timecardEntryHeaderId,
    });
    const notes = camelCase(data, { deep: true });
    yield put(actions.storeTimecardNotes({ notes, timecardId }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchHistory(api, debug, params) {
  try {
    const timecardId = params.timecardId;
    const projectId = yield select(project);
    const history = yield call(api.timecards.history, {
      projectId,
      timecardId,
    });
    yield put(actions.storeTimecardHistory({ history, timecardId }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* saveTimeCardNotes(api, debug, params) {
  try {
    yield put(actions.processingNotes({ loading: true }));
    const { note, type, timecardId, referenceDate = null } = params.value;
    const projectId = yield select(project);
    const isFreshTimecard = !Number(timecardId);
    const data = {
      comment: note,
      type: type,
      referenceDate: referenceDate || formatDateUTC(new Date()),
    };
    if (isFreshTimecard) {
      yield put(actions.storeLocalTCNote({ note: data }));
    } else {
      yield call(api.employeeTimecard.createTimeCardNotes, {
        projectId,
        timecardId,
        data,
      });

      const timecard = yield delayOnValueObj(getDetails, {
        args: [timecardId],
      });

      const timecardEntryHeaderId = timecard.entryHeaderId;
      yield put(
        actions.fetchTimeCardComments({ timecardId, timecardEntryHeaderId }),
      );
    }

    yield put(actions.processingNotes({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.processingNotes({ loading: false }));
    yield put(showAlert());
  }
}

export function* onTakeMeBack(debug) {
  try {
    const projectId = yield select(project);
    let fromURI = yield select(getFromURI);
    fromURI = fromURI.replace(':projectId', projectId);

    yield put(push(fromURI));
    yield put(actions.setFromURL({ fromURI: '' }));
  } catch (e) {
    debug(e);
  }
}

export function* onDelete(api, debug, params) {
  const { formName, comment } = params;
  try {
    yield put(actions.loading({ loading: true }));
    if (formName) yield put(startSubmit(formName));
    const fromFinish = params.fromFinish;
    const timecardId = yield select(getCurrentTimecard);

    const projectId = yield select(project);
    const copyPrevious = yield select(getCopyPreviousFlag);

    if (Number(timecardId)) {
      yield call(api.timecards.deleteTimecard, {
        projectId,
        timecardId,
        comment,
      });
      //delay to allow for the delete to complete
      yield delay(1250);
      yield call(fetch, api, debug, {
        grid: 'pending-timecards',
      });
    }
    if (copyPrevious) yield put(actions.setFlagCopyPrevious({ flag: false }));

    yield put(hideModal({ dialog: 'DeleteTimecard' }));
    if (!fromFinish) {
      yield call(onTakeMeBack, debug);
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    if (formName) yield put(stopSubmit(formName));
    yield put(actions.loadingDetails({ loading: false }));
  }
}

export const convertTimesToMil = (timecard, options = {}) => {
  const tv = new TimeValidator(null, { noRounding: true });

  const days = timecard.days;
  days.forEach(day => {
    TIME_FIELD_ORDER.forEach(field => {
      if (day[field] || day[field] === 0) {
        let parsedTime = tv.parse(`${day[field]}`);
        day[field] = tv.parseFloatToMilitary(parsedTime);
      }
    });
  });
};

export const convertTimesToDec = (timecard, options = {}) => {
  const tv = new TimeValidator(null, { noRounding: true });
  const days = timecard.days;
  days.forEach(day => {
    TIME_FIELD_ORDER.forEach(field => {
      if (day[field] || day[field] === 0) {
        day[field] = tv.parse(`${day[field]}`);
      }
    });
  });
};

export function* onSave(api, debug, params) {
  const formName = params.formName || 'timecard';
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    yield put(startSubmit(formName));

    const projectId = yield select(project);
    const id = yield select(getCurrentTimecard);
    let timecard = yield select(getTimecardFormValues, id, formName);
    timecard = cloneDeep(timecard);
    const projectDetails = yield select(getProjectDetails);
    timecard.region = projectDetails?.region;
    const isCaRegion = projectDetails.region === 'Canada';
    if (isCaRegion) {
      const workLocations = yield select(getWorkLocations);
      timecard = setWorkLocationForCanada(timecard, workLocations);
    }

    let createTimecardData;
    delete timecard.user;
    delete timecard.createdDate;
    delete timecard.createdBy;
    delete timecard.createdAt;
    convertTimesToMil(timecard, { convert24Plus: true });

    if (!Number(id)) {
      const { dealMemo } = timecard;

      const data = new FormData();
      const localAllowances = yield select(getLocalAllowances);
      const timecardAllowances = localAllowances?.map((allowance, index) => {
        if (allowance.document) {
          const key = `document${index}`;
          data.append(key, allowance.document);
          return {
            ...allowance,
            document: key,
          };
        }
        return allowance;
      });
      createTimecardData = {
        ...timecard,
        timecardAllowances: timecardAllowances || [],
        htgDealmemoId: dealMemo.id,
        id: null,
      };

      removeNoValueFromTimecard(createTimecardData);

      for (const key in createTimecardData) {
        if (Object.hasOwnProperty.call(createTimecardData, key)) {
          const element = createTimecardData[key];
          data.append(key, JSON.stringify(element));
        }
      }
      const newTimecard = yield call(api.employeeTimecard.createTimecard, {
        projectId,
        timecard: data,
      });

      yield put(actions.clearLocalTimecardAllowance());

      const localNotes = yield select(getLocalNotes);
      for (let i = 0; i < localNotes.length; i++) {
        const note = localNotes[i];
        const value = {
          note: note.comment,
          type: note.type,
          timecardId: newTimecard.id,
          date: note.referenceDate,
        };
        yield put(actions.saveTimeCardNotes({ value }));
      }
      yield put(actions.clearLocalTCNotes());

      yield put(actions.setCurrentTimecard({ timecardId: newTimecard.id }));

      timecard = camelCase(newTimecard, { deep: true });

      convertTimesToDec(timecard, { convert24Plus: true });

      yield addRoundingToDeal(api, debug, timecard.dealMemo);

      yield put(actions.storeDetails({ timecard }));
      yield put(
        push(
          `/projects/${projectId}/me/timecardsv1/${newTimecard.id}?finishTimecard=true`,
        ),
      );
      yield put(reset(formName));
      yield call(fetchTimecard, api, debug, { timecardId: newTimecard.id });
    } else {
      const allowances = yield select(getTimecardAllowances, id);
      timecard.allowances = allowances;
      const data = timecard;
      removeNoValueFromTimecard(data);
      const result = yield call(api.timecards.saveTimecard, {
        projectId,
        timecardId: data.id,
        timecard: data,
      });
      timecard = camelCase(result, { deep: true });
      // yield put(actions.updateDailyAutoAllowances({ timecard }));

      convertTimesToDec(timecard, { convert24Plus: true });
      yield addRoundingToDeal(api, debug, timecard.dealMemo);
      yield put(actions.storeDetails({ timecard }));
      yield put(initialize(formName, timecard));
      yield put(reset(formName));
    }

    yield put(stopSubmit(formName));
    yield put(hideModal({ dialog: 'ConfirmSubmit' }));
    yield put(actions.savingTimecard({ savingTimecard: false }));
  } catch (e) {
    debug(e);
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(actions.savingTimecard({ savingTimecard: false }));
    if (errors && errors.days) {
      yield put(
        showAlert({
          message: 'Timecard validation failed.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
    }
    yield put(stopSubmit(formName, { _error: { ...errors } }));
    yield put(hideModal({ dialog: 'ConfirmSubmit' }));
    return errors?.days; //return day error when called from reviews
  }
}

export function* submitToEmployees(api, debug, params) {
  const formName = 'timecard';
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    yield put(startSubmit(formName));

    const projectId = yield select(project);
    const projectDetails = yield select(getProjectDetails);
    const { timecardId } = params;
    let data = yield select(getTimecardFormValues, timecardId, formName);
    data = cloneDeep(data);
    data.region = projectDetails?.region;
    const allowances = yield select(getTimecardAllowances, timecardId);
    data.allowances = allowances;
    convertTimesToMil(data, { convert24Plus: true });
    const pristine = yield select(isPristine(formName));
    if (pristine === false) {
      removeNoValueFromTimecard(data);

      data = yield call(api.timecards.saveTimecard, {
        projectId,
        timecardId,
        timecard: data,
      });
      yield addRoundingToDeal(api, debug, data.dealMemo);
      yield put(actions.storeDetails({ timecard: data }));
    }

    data = {
      comment: params && params.notes ? params.notes : null,
      emergencyType:
        params && params.emergencyType ? params.emergencyType : null,
      timecardIds: [timecardId],
    };
    yield call(api.timecards.submitDraftToEmployees, {
      projectId,
      data,
    });
    yield put(reset(formName));
    yield put(push(`/projects/${projectId}/review/search-timecards`));
    yield put(stopSubmit(formName));
    yield put(actions.savingTimecard({ savingTimecard: false }));
  } catch (e) {
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(actions.savingTimecard({ savingTimecard: false }));
    if (errors && errors.timecard) {
      debug(e);
      yield put(
        showAlert({
          message: ERROR_MESSAGES[errors.timecard],
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
      debug(e);
    }
    yield put(stopSubmit(formName, { _error: { ...errors } }));
  }
}

export function* onSubmit(api, debug, params) {
  const formName = 'timecard';
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    yield put(startSubmit(formName));

    const projectId = yield select(project);
    const settings = yield select(getSettings);
    const id = yield select(getCurrentTimecard);
    let timecard = yield select(getTimecardFormValues, id, formName);
    timecard = cloneDeep(timecard);
    const projectDetails = yield select(getProjectDetails);
    timecard.region = projectDetails?.region;
    const isCaRegion = projectDetails.region === 'Canada';
    if (isCaRegion) {
      const workLocations = yield select(getWorkLocations);
      timecard = setWorkLocationForCanada(timecard, workLocations);
    }

    let createTimecardData;
    let newTimecard;
    delete timecard.user;
    delete timecard.createdDate;
    delete timecard.createdBy;
    delete timecard.createdAt;
    timecard.wtcLayoutName =
      timecard?.wtcLayoutName || settings?.wtcLayout?.label || null;
    convertTimesToMil(timecard, { convert24Plus: true });

    if (!Number(id)) {
      const { dealMemo } = timecard;
      const data = new FormData();
      const localAllowances = yield select(getLocalAllowances);
      const timecardAllowances = localAllowances?.map((allowance, index) => {
        if (allowance.document) {
          const key = `document${index}`;
          data.append(key, allowance.document);
          return {
            ...allowance,
            document: key,
          };
        }
        return allowance;
      });
      createTimecardData = {
        ...timecard,
        timecardAllowances: timecardAllowances || [],
        htgDealmemoId: dealMemo.id,
        id: null,
      };
      removeNoValueFromTimecard(createTimecardData);

      for (const key in createTimecardData) {
        if (Object.hasOwnProperty.call(createTimecardData, key)) {
          const element = createTimecardData[key];
          data.append(key, JSON.stringify(element));
        }
      }

      newTimecard = yield call(api.employeeTimecard.createTimecard, {
        projectId,
        timecard: data,
      });

      yield put(actions.clearLocalTimecardAllowance());

      const localNotes = yield select(getLocalNotes);
      for (let i = 0; i < localNotes.length; i++) {
        const note = localNotes[i];
        const value = {
          note: note.comment,
          type: note.type,
          timecardId: newTimecard.id,
          date: note.referenceDate,
        };
        yield put(actions.saveTimeCardNotes({ value }));
      }

      yield put(actions.clearLocalTCNotes());

      yield put(actions.setCurrentTimecard({ timecardId: newTimecard.id }));

      timecard = camelCase(newTimecard, { deep: true });

      convertTimesToDec(timecard, { convert24Plus: true });
      yield addRoundingToDeal(api, debug, timecard.dealMemo);
      yield put(actions.storeDetails({ timecard }));

      yield put(
        push(
          `/projects/${projectId}/me/timecardsv1/${newTimecard.id}?finishTimecard=true`,
        ),
      );
      yield put(reset(formName));
    } else {
      const data = newTimecard || timecard;
      removeNoValueFromTimecard(data);

      const result = yield call(api.timecards.saveTimecard, {
        projectId,
        timecardId: data.id,
        timecard: data,
      });

      convertTimesToDec(result, { convert24Plus: true });
      yield addRoundingToDeal(api, debug, result.dealMemo);
      yield put(actions.storeDetails({ timecard: result }));
    }

    yield put(stopSubmit(formName));
    yield put(hideModal({ dialog: 'ConfirmSubmit' }));
    yield put(actions.enableESignature({ enableESignature: true }));
    yield put(actions.savingTimecard({ savingTimecard: false }));
    yield put(actions.reloadTimecard({ reload: true }));
  } catch (e) {
    debug(e);
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(actions.savingTimecard({ savingTimecard: false }));
    yield put(actions.reloadTimecard({ reload: true }));
    yield put(stopSubmit(formName, { _error: { ...errors } }));
    yield put(hideModal({ dialog: 'ConfirmSubmit' }));
    if (errors && errors.timecard) {
      yield put(
        showAlert({
          message: ERROR_MESSAGES[errors.timecard],
          variant: 'error',
        }),
      );
    } else if (errors && errors.days) {
      yield put(
        showAlert({
          message: 'Timecard validation failed.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

export function* onApprove(api, debug, params) {
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));

    const projectId = yield select(project);
    const timecardId = yield select(getCurrentTimecard);
    const data = {
      AgreeToTerms: params.agreed,
      ElectronicSignature: params.esignature,
    };
    yield call(api.timecards.approvalFlow, { projectId, timecardId, data });

    yield put(actions.savingTimecard({ savingTimecard: false }));
    yield put(actions.enableESignature({ enableESignature: false }));
    yield put(
      push(
        `/projects/${projectId}/me/timecards?approvedTimecardId=${timecardId}`,
      ),
    );
  } catch (e) {
    debug(e);
    yield put(actions.savingTimecard({ savingTimecard: false }));
    yield put(actions.enableESignature({ enableESignature: false }));
    yield put(showAlert());
  }
}

export function* printTimecard(api, debug) {
  try {
    const projectId = yield select(project);
    const timecardId = yield select(getCurrentTimecard);

    let endpoint = [
      `/projects/${projectId}`,
      `/timecards/${timecardId}`,
      '/print',
    ].join('');

    const filename = `Timecard-${timecardId}.pdf`;
    const response = yield call(api.downloader.downloadFromURI, {
      endpoint,
      filename,
    });
    if (response?.data?.fileStatus === 'Failed') {
      yield put(
        showAlert({
          message:
            'Some allowance documents cannot be converted to PDF and are not included in the report',
          variant: 'warning',
        }),
      );
    }
  } catch (e) {
    debug(e);
  }
}

export function* fetchTimecard(api, debug, params) {
  // need to use some params to indicate load incomplete TC from DB
  const { timecardId } = params;

  yield put(actions.clearLocalTCNotes());
  yield put(actions.clearLocalTimecardAllowance());

  if (Number(timecardId)) {
    yield all([
      call(fetchEpisodes, api, debug),
      call(fetchDetails, api, debug, params),
      call(fetchHistory, api, debug, params),
      call(fetchTCAllowances, api, debug, params),
      call(fetchTimecardAllowances, api, debug, params),
    ]);
  } else {
    yield all([
      call(fetchEpisodes, api, debug),
      // fetchWorkLocation dropdown need some params to work
      call(fetchTimecardAllowances, api, debug, params),
    ]);
  }
}

export function* fetchTCAllowances(api, debug, params) {
  try {
    const timecardId = params.timecardId;

    yield put(actions.storeTCAllowances({ tcAllowances: null, timecardId }));

    const projectId = yield select(project);
    const data = yield call(api.timecards.loadTCAllowances, {
      projectId,
      timecardId,
    });

    const tcAllowances = camelCase(data, { deep: true });
    yield put(actions.storeTCAllowances({ tcAllowances, timecardId }));
  } catch (e) {
    debug(e);
  }
}

export function* fetchTimecardAllowances(api, debug) {
  try {
    const projectId = yield select(project);
    const data = yield call(api.timecards.loadTimecardAllowances, {
      projectId,
    });
    const timecardAllowances = camelCase(data, { deep: true });
    yield put(
      actions.storeTimecardAllowances({ timecardAllowances, projectId }),
    );
  } catch (e) {
    debug(e);
  }
}

export function* downloadSupportingDocument(api, debug, params) {
  try {
    const { token, file } = params;
    const projectId = yield select(project);
    const endpoint = [
      `projects/${projectId}/allowances/${token}/downloadDocument`,
    ].join('');
    const fileName = `${file}`;
    yield call(api.downloader.downloadFromURI, {
      endpoint,
      fileName,
    });
  } catch (e) {
    debug(e);
  }
}

export function* removeTimecardAllowance(api, debug, params) {
  try {
    yield put(actions.processingAllowances({ loading: true }));
    const { timecardId, worksightId, skipRefresh = false } = params;
    const projectId = yield select(project);
    if (!Number(timecardId)) {
      yield put(
        actions.removeLocalTimecardAllowance({ index: params.allowanceId }),
      );
    } else {
      yield call(api.employeeTimecard.deleteTimecardAllowance, {
        projectId,
        timecardId,
        worksightId,
      });

      if (!skipRefresh) {
        yield call(fetchTCAllowances, api, debug, params);
      }
    }

    yield put(hideModal({ dialog: 'DeleteTimecardAllowance' }));
    yield put(actions.processingAllowances({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.processingAllowances({ loading: false }));
  }
}

export function* saveTimecardAllowance(api, debug, params) {
  try {
    yield put(actions.processingAllowances({ loading: true }));
    const {
      index,
      allowance: incomingAllowance,
      timecardId,
      skipRefresh = false,
    } = params;
    const projectId = yield select(project);
    const allowance = cloneDeep(incomingAllowance);
    const formName = 'timecard';
    const timecard = yield select(getTimecardFormValues, timecardId, formName);
    const { allowanceLocationTypeId } = timecard;
    let data = {};
    allowance.locationTypeId = allowanceLocationTypeId || null;
    const allowanceType = {
      Code: allowance.isDefaultAllowance
        ? allowance.defaultAllowanceId
        : allowance.htgAllowanceTypeId || allowance.htg_allowance_type_id,
      isDefaultAllowance: allowance.isDefaultAllowance || false,
    };
    const dealMemo = timecard.dealMemo;
    const allowanceId = allowance.id || null;
    let updatedData = { ...allowance };
    if (!allowanceId) {
      const dealAllowance = dealMemo.dealMemoAllowances;
      const dealAllowObj = dealAllowance?.find(
        da =>
          da.payCode1?.id === allowance.htgAllowanceTypeId ||
          da.payCode2?.id === allowance.htgAllowanceTypeId,
      );
      updatedData = {
        ...updatedData,
        freeField1: dealAllowObj?.customField1 || dealMemo.customField1 || null,
        freeField2: dealAllowObj?.customField2 || dealMemo.customField2 || null,
        freeField3: dealAllowObj?.customField3 || dealMemo.customField3 || null,
        freeField4: dealAllowObj?.customField4 || dealMemo.customField4 || null,
        accountCode: dealAllowObj?.account || dealMemo.wageAccount || null,
        series: dealAllowObj?.series || dealMemo.series || null,
        location: dealAllowObj?.location || dealMemo.location || null,
        set: dealAllowObj?.set || dealMemo.set || null,
        insurance: dealAllowObj?.insurance || dealMemo.insurance || null,
        sequenceNumber: 0, //send sequence number as 0 for new allowances
        sequenceGuid:
          allowance?.allowanceTypeFlag === 'A'
            ? dealAllowObj?.id
            : '00000000-0000-0000-0000-000000000000',
      };
    }
    data = composeAllowanceV1(updatedData);
    if (!Number(timecardId)) {
      yield put(
        actions.storeLocalTCAllowance({
          index,
          allowance: data,
        }),
      );
    } else {
      data = convertToFormData(data, allowanceType);

      if (allowance.id) {
        yield call(api.employeeTimecard.updateTimecardAllowance, {
          projectId,
          timecardId,
          worksightId: allowance.worksightId,
          data,
        });
      } else {
        yield call(api.employeeTimecard.saveTimecardAllowance, {
          projectId,
          timecardId,
          data,
        });
      }

      if (!skipRefresh) {
        yield call(fetchTCAllowances, api, debug, params);
      }
    }

    yield put(actions.resetAllowanceInput());
    yield put(actions.processingAllowances({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.processingAllowances({ loading: false }));
  }
}

export function* fetchEmployeeAllowancePayCodes(api, debug) {
  try {
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const formName = 'timecard';
    const id = yield select(getCurrentTimecard);
    let timecard = yield select(getTimecardFormValues, id, formName);
    const activeUser = yield select(currentUser);
    const { dealMemo } = timecard;
    // dealMemo Call - avoid for non-employee users
    if (activeUser.workSightId) {
      const dealMemoList = yield call(api.employees.dealMemos, {
        projectWorkSightId,
        employeeId: activeUser.workSightId,
      });
      const htgDealMemo = dealMemoList.find(d => d.id === dealMemo.id);
      // PensionUnion
      const pensionUnion = get(htgDealMemo, 'pensionUnion.id', '');
      const data = yield call(api.timecards.loadEmployeeAllowancePayCodes, {
        type: 'allowancePayCodeInclude',
        params: {
          options: { project: projectWorkSightId, union: pensionUnion },
        },
      });
      const employeeAllowancePayCodes = camelCase(data, { deep: true });
      yield put(
        actions.storeEmployeeAllowancePayCodes({ employeeAllowancePayCodes }),
      );
    }
  } catch (e) {
    debug(e);
  }
}
/** Not referenced/used anywhere */
export function* fetchEmployees(api, debug, params) {
  try {
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const data = yield call(api.employees.fetchEmployees, {
      projectWorkSightId,
    });
    const employees = camelCase(data, { deep: true });
    yield put(actions.storeEmployees({ employees }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.loading({ loading: false }));
  }
}

function* navTo(api, debug, params) {
  try {
    const user = yield select(currentUser);
    const projectId = yield select(project);

    const { timecardEntryHeaderId } = params;

    if (user.role === DH && timecardEntryHeaderId) {
      const timecardData = yield call(api.wtc.getHPlusTimecardInfo, {
        projectId,
        timecardEntryHeaderId,
      });

      const { status, batch, id: timecardId } = timecardData?.timecard;

      let toURL;
      if (status === 'pending_dh_review') {
        toURL = yield makePendingDhApprovalUrl(api, {
          projectId,
          worksightId: batch.worksightId,
          timecardEntryHeaderId,
        });
      } else {
        toURL = `/projects/${projectId}/reviews/timecards/${timecardId}`;
      }
      yield put(actions.setCurrentTimecard({ timecardId: timecardId }));

      yield put(replace(toURL));
    }
  } catch (error) {
    debug(error);
    yield put(push('/'));
  }
}

//Because who doesn't love making 2 additional API calls to get 1 number....
function* makePendingDhApprovalUrl(api, params) {
  const { projectId, worksightId, timecardEntryHeaderId } = params;

  const batchData = yield call(api.wtc.reviewBatch, {
    projectId,
    worksightId,
  });

  const reviewBatchId = batchData.id;

  const timecards = yield call(api.reviews.timecardsByReviewBatchId, {
    projectId,
    type: 'open',
    reviewBatchId,
    showPaid: true,
  });

  const fullTimecard = timecards.find(
    tc => tc.entryHeaderId === timecardEntryHeaderId,
  );

  const { id: approvalFlowId } = fullTimecard;

  return `/projects/${projectId}/reviews/batches/${reviewBatchId}/approval-flows/${approvalFlowId}`;
}

// In order to trigger the validation on a partial timecard, the redux form needs to treat these as new values
// problem is because reinitialize is set to true, any re-render will reset to the stored values
// so here, I'm grabbing the days, clearing out just the time values and then running redux-form change() on them
// Ideally we shouldn't be re-initializing the form every re-render, but thats how this page was designed and its so interconnected I don't want to
// risk bringing down all timecard entry just to get this working properly, so here's a hacky solution.
function* resetValidation(api, debug, params) {
  const { timecardId, change } = params;

  const initialValues = yield select(getInitialValues, timecardId, 'employee');

  const days = cloneDeep(initialValues.days);

  yield put(actions.clearTimecardDetails({ timecardId }));

  yield delay(100);

  days.forEach((day, i) => {
    TIME_FIELD_ORDER.forEach(field => {
      if (day[field] || day[field] === 0) {
        change(`days[${i}].${field}`, day[field]);
      }
    });
  });
}

export function* updateDailyAutoAllowances(api, debug, params) {
  try {
    const updatedAllowances = [];
    const deletedAllowances = [];
    const { timecard } = params;
    if (!timecard) {
      throw new Error('Timecard is missing');
    }

    const isUpdating = yield select(getUpdatingAutoAllowances);

    if (isUpdating) {
      return;
    }
    yield put(
      actions.setUpdatingAutoAllowances({ updatingAutoAllowances: true }),
    );

    const settings = yield select(getSettings);
    const { dtsTimecardAutoAllowances } = settings;
    if (!dtsTimecardAutoAllowances) return;

    const { status, days = [], dealMemo = {} } = timecard;

    if (status !== 'draft' && status !== 'pending_dh_review') return;

    const defaultNonTax = yield delayOnValueObj(getDefaultPayCodes);

    const timecardId = yield select(getCurrentTimecard);
    const allowanceTypes = yield select(getProjectAllowances);
    const dealMemoAllowances = dealMemo?.dealMemoAllowances || [];

    const dailyDealAllowances = dealMemoAllowances.filter(
      a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
    );

    const dayCountObj = days.reduce((acc, day) => {
      if (day.dayType?.id && day.isActive) {
        if (acc[day.dayType.id]) {
          acc[day.dayType.id] += 1;
        } else {
          acc[day.dayType.id] = 1;
        }
      }
      return acc;
    }, {});

    const existingAllowances = yield select(getDHAllowances, timecardId);

    dailyDealAllowances.forEach(dealAllo => {
      const oldAllowance = existingAllowances.find(a =>
        a.sequenceNumber > 0
          ? a.sequenceNumber === dealAllo.sequenceNumber
          : a.sequenceGuid === dealAllo.id,
      );
      let newAllowance = cloneDeep(oldAllowance);
      if (!newAllowance) {
        newAllowance = makeNewDHAllowance(dealAllo, defaultNonTax);
      }

      const allowanceType = allowanceTypes.find(
        at => at.id === newAllowance.htgAllowanceTypeId,
      );

      const dayTypes = allowanceType?.dayTypes || [];
      let dayCount = 0;
      dayTypes.forEach(dt => {
        if (dayCountObj[dt.id]) {
          dayCount += dayCountObj[dt.id];
        }
      });
      newAllowance.units = dayCount;
      let amount = Number(Number(newAllowance.rate) * newAllowance.units);
      if (Number.isNaN(amount)) amount = 0;
      amount = amount.toFixed(2);

      newAllowance.amount = amount;

      if (
        (!oldAllowance && Number(amount) !== 0) ||
        (oldAllowance &&
          Number(oldAllowance.amount) !== Number(newAllowance.amount) &&
          Number(newAllowance.amount) !== 0)
      ) {
        updatedAllowances.push(newAllowance);
      }

      if (oldAllowance && Number(newAllowance.amount) === 0) {
        deletedAllowances.push(oldAllowance);
      }
    });

    const anyToDelete = deletedAllowances.length > 0;

    for (let i = 0; i < updatedAllowances.length; i++) {
      const allowance = updatedAllowances[i];

      yield call(saveTimecardAllowance, api, debug, {
        allowance,
        timecardId,
        skipRefresh: updatedAllowances.length - 1 !== i && !anyToDelete,
      });
      yield delay(100);
    }

    for (let i = 0; i < deletedAllowances.length; i++) {
      const allowance = deletedAllowances[i];
      yield call(removeTimecardAllowance, api, debug, {
        worksightId: allowance.worksightId,
        timecardId,
        skipRefresh: deletedAllowances.length - 1 !== i && anyToDelete,
      });
      yield delay(100);
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(
      actions.setUpdatingAutoAllowances({ updatingAutoAllowances: false }),
    );
  }
}

export default function* timecardFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.fetchWeekendings}`, fetchWeekendings, api, debug),

    takeEvery(`${actions.fetchDetails}`, fetchDetails, api, debug),
    takeEvery(`${actions.fetchDetailsOnly}`, fetchDetailsOnly, api, debug),
    takeEvery(`${actions.createTimecard}`, createTimecard, api, debug),
    takeLatest(`${actions.fetchDayTypes}`, fetchDayTypes, api, debug),
    takeEvery(`${actions.fetchEpisodes}`, fetchEpisodes, api, debug),
    takeEvery(`${actions.fetchWorkLocations}`, fetchWorkLocations, api, debug),
    takeEvery(`${actions.fetchTimeCardComments}`, fetchComments, api, debug),
    takeEvery(`${actions.fetchTimecardHistory}`, fetchHistory, api, debug),
    takeEvery(`${actions.saveTimeCardNotes}`, saveTimeCardNotes, api, debug),
    takeEvery(`${actions.onTakeMeBack}`, onTakeMeBack, debug),
    takeEvery(`${actions.onDelete}`, onDelete, api, debug),
    takeEvery(`${actions.onSave}`, onSave, api, debug),
    takeEvery(`${actions.onSubmit}`, onSubmit, api, debug),
    takeEvery(`${actions.onApprove}`, onApprove, api, debug),
    takeEvery(`${actions.printTimecard}`, printTimecard, api, debug),
    takeEvery(`${actions.fetchTimecardDetails}`, fetchTimecard, api, debug),
    takeEvery(
      `${actions.fetchWeekendingTimecards}`,
      fetchWeekendingTimecards,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchTCAllowances}`, fetchTCAllowances, api, debug),
    takeEvery(
      `${actions.fetchTimecardAllowances}`,
      fetchTimecardAllowances,
      api,
      debug,
    ),
    takeEvery(
      `${actions.downloadSupportingDocument}`,
      downloadSupportingDocument,
      api,
      debug,
    ),
    takeEvery(
      `${actions.saveTimecardAllowance}`,
      saveTimecardAllowance,
      api,
      debug,
    ),
    takeEvery(
      `${actions.removeTimecardAllowance}`,
      removeTimecardAllowance,
      api,
      debug,
    ),
    takeEvery(
      `${actions.fetchEmployeeAllowancePayCodes}`,
      fetchEmployeeAllowancePayCodes,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchEmployees}`, fetchEmployees, api, debug),
    takeLatest(`${actions.submitToEmployees}`, submitToEmployees, api, debug),
    takeLatest(`${actions.navTo}`, navTo, api, debug),
    takeLatest(`${actions.resetValidation}`, resetValidation, api, debug),
    takeLatest(
      `${actions.updateDailyAutoAllowances}`,
      updateDailyAutoAllowances,
      api,
      debug,
    ),
  ]);
}
