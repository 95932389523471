import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, LinearProgress } from '@mui/material';
import pluralize from 'pluralize';
//shared
import { withStyleSheet } from 'shared/theme';
// components
import { timecardsProps } from 'components/props/timecard';

import { getApproverList } from '../Reviews/ApproverList';

import {
  currentApprovers,
  getDeleteIcon,
  EmergencyIcon,
  ForceResubmitIcon,
  getStatusBadgeV2,
} from 'containers/Employees/Reviews/Shared/timecardUtils';

import CreateTimecard from '../../../containers/Nav/Pages/CreateTimecard';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { isInstantDealTimecard } from 'utils/wtcWeekUtils';
import InstantDealIcon from 'components/Shared/InstantDealIcon';
import InstantDealMessage from 'components/Shared/InstantDealMessage';

const style = ({ palette }) => ({
  appBar: {
    justifyContent: 'flex-end',
    top: 'auto',
    bottom: 0,
    backgroundColor: palette.primary.background,
  },
  paper: {
    width: '100%',
    marginTop: 30,
    marginBottom: 50,
  },
  grow: {
    flexGrow: 1,
  },
  gridItem: {
    color: palette.text.secondary,
  },
  tableEmpty: {
    textAlign: 'center',
    height: 70,
    padding: 25,
    backgroundColor: palette.background.default,
  },
  title: {
    marginLeft: 20,
  },

  card: {
    fontSize: 14,
    padding: '24px 14px',
    lineHeight: '24px',
    marginBottom: 2,
  },

  bold: {
    fontWeight: 500,
  },
  cardContainer: {
    '& > div:nth-child(odd)': {
      backgroundColor: palette.primary.table,
    },
    '& > div:nth-child(even)': {
      backgroundColor: palette.background.paper,
    },
    overflowX: 'hidden',
  },
});

class CardList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecards: timecardsProps.isRequired,
    activeUser: PropTypes.object.isRequired,
    finish: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClick(timecard) {
    if (this.props.onClick) {
      this.props.onClick(timecard);
    }
  }

  handleDelete(e, timecardId) {
    e.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete(timecardId);
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.listenForCombo);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listenForCombo);
  }

  render() {
    const {
      classes,
      timecards = [],
      finish,
      loading,
      isUserDeleted,
    } = this.props;
    const self = this;
    let contentRows = null;

    if (timecards && timecards.length <= 0) {
      contentRows = (
        <Grid container>
          <Grid item xs={12} className={classes.tableEmpty}>
            {loading ? 'Loading Timecards...' : 'No Timecards Found'}
          </Grid>
        </Grid>
      );
    } else {
      contentRows = timecards.map(timecard => {
        const isInstantDeal = isInstantDealTimecard(timecard);
        return (
          <Grid
            container
            className={classes.card}
            key={timecard.timecardId}
            onClick={() => self.handleClick(timecard)}
          >
            <Grid container>
              <Grid
                item
                xs={5}
                className={classes.gridItem}
                width={'3%'}
              ></Grid>
              <Grid item xs={7} className={classes.bold}>
                {isInstantDeal ? (
                  <InstantDealIcon />
                ) : timecard.emergencyType ? (
                  <EmergencyIcon />
                ) : timecard.workflowAction === 'resubmitToEmployee' ? (
                  <ForceResubmitIcon />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} className={classes.gridItem}>
                Week Ending:
              </Grid>
              <Grid item xs={7} className={classes.bold}>
                {timecard.endsOn.slice(0, 10)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} className={classes.gridItem}>
                Occupation code:
              </Grid>
              <Grid item xs={7} className={classes.bold}>
                {' '}
                {timecard.occupationCode || 'Pending'}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} className={classes.gridItem}>
                Current Approver:
              </Grid>
              <Grid item xs={7} className={classes.bold}>
                {getApproverList(
                  currentApprovers(timecard.currentApproversStr),
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: '5px' }}>
              <Grid item xs={5} className={classes.gridItem}>
                Status:
              </Grid>
              <Grid item xs={7} className={classes.bold}>
                {getStatusBadgeV2(timecard)}
              </Grid>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {!isUserDeleted &&
                getDeleteIcon(timecard, self.handleDelete, 'Delete')}
            </Grid>
          </Grid>
        );
      });
    }

    const isInstantDealTc = timecards.some(tc => isInstantDealTimecard(tc));
    return (
      <Fragment>
        <Paper className={classes.paper}>
          {loading && timecards.length <= 0 && <LinearProgress />}
          {finish && (
            <h4 className={classes.title}>
              {`You have
               ${pluralize('timecard', timecards.length, true)} to Finish`}
            </h4>
          )}
          {isInstantDealTc && <InstantDealMessage isMobile={true} />}
          <div className={classes.cardContainer}>{contentRows}</div>
        </Paper>

        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className={classes.grow} />
            <CreateTimecard isUserDeleted={isUserDeleted} isMobile={true} />
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyleSheet('CardList', style)(CardList);
