import { createSelector } from 'reselect';
import _ from 'lodash';
import { currentUser } from './session';
import { getProject } from 'selectors/routeParams';

export const MISSING_WORKSIGHT_ACCOUNT = 'MISSING_WORKSIGHT_ACCOUNT';
export const MISSING_DEPARTMENT = 'MISSING_DEPARTMENT';

export const getDealMemos = createSelector([currentUser], activeUser => {
  return activeUser.dealMemos || [];
});

export const getReasonDisabled = createSelector([currentUser], activeUser => {
  const reason =
    !activeUser || !activeUser.hasWorksightAccount
      ? MISSING_WORKSIGHT_ACCOUNT
      : !activeUser.department
      ? MISSING_DEPARTMENT
      : null;

  return reason;
});

const permissions = state => _.get(state, `userInfo.permissions`, {});

export const getPermissions = createSelector(
  [getProject, permissions],
  (projectId, permissions) => {
    if (_.isEmpty(permissions)) return {};
    return permissions[Number(projectId)];
  },
);

const getUserFetchTime = state =>
  _.get(state, 'userInfo.activeUserFetchTime', {});

export const getLastFetch = createSelector(
  [getProject, getUserFetchTime],
  (projectId, userFetchTime) => {
    const fetchTime = userFetchTime[Number(projectId)];

    return fetchTime;
  },
);

// defined in session selector to prevent circular deps
// export const getProjUsers = state => _.get(state, 'userInfo.activeUser', {});
