import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Field } from 'redux-form';
import { Button, Typography, useMediaQuery, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//selectors
import { isProcessingApproval } from 'selectors/reviews';
import {
  getOtherTimecards,
  getLoadingOtherTimecards,
  currentUserLevel,
  isCurrentUserUPM,
  isCurrentUserPA,
} from 'selectors/wtc';
import { getProject } from 'selectors/routeParams';
//actions
import * as actions from 'actions/wtc';
import { showAlert } from 'actions/alert';

//components
import AdditionalFields from 'components/Employees/Reviews/WTC/AdditionalFields/AdditionalFieldsContainer';
import PrintMenu from './WTCPrintMenu';
import WTCAutoV2 from './WTCAutoV2';
import WTCHeaderButtons from './WTCHeaderButtons';
import OtherTimecards from './OtherTimecards';

//icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DescriptionIcon from '@mui/icons-material/Description';
import InstantDealIcon from 'components/Shared/InstantDealIcon';

//utils
import { weekEndingFormat } from 'utils/formatDate';
import {
  isInstantDealTimecard,
  isInvalidDealTimecard,
} from 'utils/wtcWeekUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'block',
    color: palette.text.secondary,
    backgroundColor: palette.background.default,
  },
  backToListButton: {
    color: palette.text.secondary,
    margin: 0,
  },
  backToList: {
    margin: 5,
  },
  empName: {
    fontSize: 26,
    fontWeight: 300,
    color: palette.text.secondary,
  },
  unSignedOffer: {
    display: 'flex',
    width: '100%',
    fontSize: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  instantDealIcon: {
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    color: palette.warning.dark,
    alignItems: 'center',
    padding: '0px',
  },
  briefInfo: {
    fontSize: 12,
    fontWeight: 500,
    width: '100%',
    marginBottom: 5,
  },
  rightBar: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 16,
  },
  additionalField: {
    width: 184,
  },
  producerField: {
    marginRight: 24,
  },
  employeeField: {
    marginRight: 16,
    width: 165,
  },
  viewDealMemos: {
    marginLeft: '-3px',
    display: 'flex',
    cursor: 'pointer',
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    '& > span': {
      marginTop: '4px',
      marginLeft: '5px',
    },
  },
  isInvalidDealMemos: {
    marginLeft: '-3px',
    display: 'flex',
    cursor: 'pointer',
    color: palette.error.main,
    fontSize: 14,
    fontWeight: 500,
    '& > span': {
      marginTop: '4px',
      marginLeft: '5px',
    },
  },
  multiBatchTitle: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  gridSpace: {
    marginBottom: 10,
  },
  rightContent: {
    justifyContent: 'flex-end',
  },
  headerContent: {
    marginLeft: 10,
    marginBottom: 10,
  },
}));

const mapState = state => ({
  isBulkApprovalProcessing: isProcessingApproval(state),
  otherTimecards: getOtherTimecards(state),
  loadingOtherTimecards: getLoadingOtherTimecards(state),
  userLevel: currentUserLevel(state),
  isUPM: isCurrentUserUPM(state),
  isPA: isCurrentUserPA(state),
  projectId: getProject(state),
});

const mapDispatch = dispatch => ({
  onProducerWWChange: producerWWChanged => {
    dispatch(actions.onProducersWWChange({ producerWWChanged }));
  },
  onEmployeeWWChange: employeeWWchanged => {
    dispatch(actions.onEmployeesWWChange({ employeeWWchanged }));
  },
  onFetchOtherTimecards: () => dispatch(actions.fetchOtherTimecards()),
  onShowAlert: params => dispatch(showAlert(params)),
});

const WTCHeader = props => {
  const classes = useStyles();
  const {
    timecard,
    canRenderTimecard,
    onProducerWWChange,
    // onEmployeeWWChange,
    isMultiBatch,
    onShowMemoModal,
    dealMemoId,
    wtcDisabled,
    onDirtyFlagCheck,
    //HeaderBtn props
    onForceCommentAction,
    onSaveTimecard,
    onApprovalWithoutChange,
    onSetAutoCoding,
    isBulkApprovalProcessing,
    onFetchOtherTimecards,
    loadingOtherTimecards,
    otherTimecards,
    isUPM,
    isPA,
    userLevel,
    projectId,
    onShowAlert,
  } = props;

  let name, weekEnding, batchCode, batchName;

  const isInstantDealTC = isInstantDealTimecard(timecard);
  const isInvalidDealMemo = isInvalidDealTimecard(timecard);
  const datesOfWeekList = useMemo(() => {
    const list = [];
    for (let i = 0; i < 7; i++) {
      list.push(
        `${moment(timecard.weekStartingDate)
          .add(i, 'day')
          .format('YYYY-MM-DD')}`,
      );
    }
    return list;
  }, [timecard.weekStartingDate]);

  if (canRenderTimecard) {
    name = timecard.employee.name.includes(':')
      ? timecard.employee.name.split(':')[0]
      : timecard.employee.name;
    weekEnding = weekEndingFormat(timecard.weekEndingDate);
    batchCode = timecard.worksightHtgId;
    batchName = timecard.batch.name;
  }

  const isMediumScreen = useMediaQuery('(max-width:1650px)') ? true : false;
  const showDealMemoPopup = dealMemoId => {
    if (!isBulkApprovalProcessing) {
      onShowMemoModal({
        source: 'week',
        currentDeal: dealMemoId,
      });
    }
  };

  const isPopOut = window.location.pathname.toLowerCase().includes('popoutwtc');

  return (
    <div className={classes.root}>
      <Button
        variant="text"
        color="primary"
        className={classes.backToListButton}
        onClick={() =>
          onDirtyFlagCheck({
            actionType: isPopOut ? 'closeWindow' : 'navBackFromWTC',
          })
        }
      >
        <ChevronLeftIcon />
        <span className={classes.backToList}>
          {isPopOut ? 'Close Pop Out' : 'Back to List'}
        </span>
      </Button>
      {canRenderTimecard ? (
        <div className={classes.headerContent}>
          <Grid
            style={{ flexFlow: 'nowrap' }}
            className={classes.gridSpace}
            container
            direction="row"
            alignItems="center"
          >
            <Grid container item xs>
              <Typography className={classes.empName}>{name}</Typography>

              {isInstantDealTC && (
                <Grid className={classes.unSignedOffer}>
                  <Box className={classes.instantDealIcon}>
                    <InstantDealIcon
                      color="warning.dark"
                      label={'Offer Pending Approval'}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid container item xs>
              <WTCHeaderButtons
                onForceCommentAction={onForceCommentAction}
                onSaveTimecard={onSaveTimecard}
                onApprovalWithoutChange={onApprovalWithoutChange}
                onSetAutoCoding={onSetAutoCoding}
                isMediumScreen={isMediumScreen}
                isInstantDealTC={isInstantDealTC}
              />
            </Grid>
            <Grid container item xs>
              <div className={classes.rightBar}>
                <PrintMenu isMediumScreen={isMediumScreen} />
                <div className={classes.additionalField}>
                  <AdditionalFields />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            className={classes.gridSpace}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid container item xs>
              <OtherTimecards
                onFetchOtherTimecards={onFetchOtherTimecards}
                otherTimecards={otherTimecards}
                loading={loadingOtherTimecards}
                userLevel={userLevel}
                isUPM={isUPM}
                isPA={isPA}
                projectId={projectId}
                onDirtyFlagCheck={onDirtyFlagCheck}
                onShowAlert={onShowAlert}
                weekEnding={weekEnding}
              />
              <Typography
                className={clsx(classes.briefInfo, {
                  [classes.multiBatchTitle]: isMultiBatch,
                })}
                onClick={() =>
                  isMultiBatch
                    ? onDirtyFlagCheck({
                        actionType: 'navToBatchWTC',
                        timecardEntryHeaderId: timecard.timecardEntryHeaderId,
                        batchWorksightId: timecard.batch.id,
                      })
                    : {}
                }
              >
                Batch: {`${batchCode} - ${batchName}`}
              </Typography>
            </Grid>
            <Grid container item xs className={classes.rightContent}>
              <div className={classes.producerField}>
                <Field
                  name="producersWorkWeek"
                  component={WTCAutoV2}
                  options={datesOfWeekList}
                  disabled={wtcDisabled}
                  minWidth={120}
                  label="Producer's Week"
                  isClearable={false}
                  stringValue={true}
                  onChange={() => {
                    onProducerWWChange(true);
                  }}
                />
              </div>
              <div className={classes.employeeField}>
                <Field
                  name="employeesWorkWeek"
                  component={WTCAutoV2}
                  options={datesOfWeekList}
                  minWidth={120}
                  label="Employee's Week"
                  isClearable={false}
                  stringValue={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            className={classes.gridSpace}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div
              className={clsx(classes.viewDealMemos, {
                [classes.isInvalidDealMemos]: isInvalidDealMemo,
              })}
              onClick={() => showDealMemoPopup(dealMemoId)}
            >
              <DescriptionIcon />
              <span>View Deal Memos</span>
            </div>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

WTCHeader.propTypes = {
  timecard: PropTypes.object.isRequired,
  canRenderTimecard: PropTypes.bool.isRequired,
  onProducerWWChange: PropTypes.func.isRequired,
  // onEmployeeWWChange: PropTypes.func.isRequired,
  isMultiBatch: PropTypes.bool.isRequired,
  onShowMemoModal: PropTypes.func.isRequired,
  dealMemoId: PropTypes.string,
  wtcDisabled: PropTypes.bool.isRequired,
  onDirtyFlagCheck: PropTypes.func.isRequired,
  onForceCommentAction: PropTypes.func.isRequired,
  onSaveTimecard: PropTypes.func.isRequired,
  onApprovalWithoutChange: PropTypes.func.isRequired,
  onSetAutoCoding: PropTypes.func.isRequired,
  isBulkApprovalProcessing: PropTypes.bool.isRequired,
  onFetchOtherTimecards: PropTypes.func.isRequired,
  otherTimecards: PropTypes.array.isRequired,
  loadingOtherTimecards: PropTypes.bool.isRequired,
  isUPM: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
  userLevel: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  onShowAlert: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(WTCHeader);
