import createAction from './createAction';

export const fetch = createAction('fetch/settings');
export const store = createAction('store/settings');
export const updateAccountCode = createAction('update/settings');
export const edit = createAction('edit/settings');
export const loading = createAction('loading/settings');

export const fetchPayrollSettings = createAction('fetch/PayrollSettings');
export const storePayrollSettings = createAction('store/PayrollSettings');

export const setWtcEditable = createAction('setWtcEditable/settings');
export const setEditOptions = createAction('setEditOptions/settings');
export const setWtcLayout = createAction('setWtcLayout/settings');
export const toggleAutoCoding = createAction('setAutoCoding/settings');

//time settings
export const updateTimeEntrySettings = createAction(
  'updateTimeEntrySettings/settings',
);

//e-signature settings
export const updateESignature = createAction('updateESignature/settings');

//email configuration
export const fetchEmailSettings = createAction('fetch/emailSettings');
export const storeEmailSettings = createAction('store/emailSettings');
export const submitEmailSettings = createAction('email/submitSettings');
export const loadingEmailSettings = createAction('email/loadingSettings');

//studio+ destination
export const fetchStudioPlusDirectory = createAction(
  'fetch/StudioPlusDirectory',
);
export const storeStudioPlusDirectory = createAction(
  'store/StudioPlusDirectory',
);
export const studioPlusButtonDisabled = createAction(
  'store/StudioPlusBtnDisabled',
);
export const editStudioPlusGroup = createAction('edit/editStudioPlusGroup');
export const updateStudioPlusSettings = createAction(
  'updateStudioPlusSettings',
);

//dts
export const updateDtsSettings = createAction('updateDTSSetting/settings');

export const storeTimecardTemplates = createAction(
  'storeTimecardTemplates/settings',
);
export const fetchTimecardTemplates = createAction(
  'fetchTimecardTemplates/settings',
);
export const fetchRecipients = createAction('fetch/settings/recipients');
export const storeRecipients = createAction('store/settings/recipients');
export const saveRecipients = createAction('save/settings/recipients');
export const removeRecipient = createAction('remove/settings/recipient');

//WTC
export const updateWTCSetting = createAction('update/settings/wtcSettings');

//crew member timecard settings
export const initCrewMemberVisibleFields = createAction(
  'init/settings/crewMemberVisibleFields',
);
export const updateCrewMemberFieldValues = createAction(
  'update/settings/crewMemberFieldValues',
);
export const updateCrewMemberVisibleFields = createAction(
  'update/settings/crewMemberVisibleFields',
);
export const saveCrewMemberTCFieldsSettings = createAction(
  'save/settings/crewMemberTCFields',
);
export const updateCapSettings = createAction('update/settings/capSettings');
