import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Tooltip, styled } from '@mui/material';
import { Button } from 'feature/EmployeeTimecard/Shared/styledComponents';
//icons

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ALLOWANCE_DIALOG } from '../Modals/modalNames';

//comps
import AllowanceCard from '../Shared/AllowanceCard';

//redux
import { show as showModal } from 'actions/modalDialog';

import * as sel from '../selectors';
import { getCapTrackingSettings } from 'selectors/settings';

import { NEW_ALLO_ID } from 'feature/EmployeeTimecard/empTimecardUtils';
import ShowCapBtn from 'feature/CapTracking/ShowCapBtn';

const mapState = (state, ownProps) => {
  return {
    allowances: sel.getVisibleAllowances(state),
    loading: sel.getLoading(state, 'allowances'),
    disabledBtns: sel.getDisabledBtns(state),
    timecardStatus: sel.getTimecardStatus(state),
    capTrackingEnabled: getCapTrackingSettings(state),
  };
};

const mapDispatch = dispatch => ({
  onShowAllowanceModal: params => {
    dispatch(showModal({ dialog: ALLOWANCE_DIALOG, modalParams: params }));
  },
});

const AllowancesTab = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `solid 1px ${theme.palette.gray['+9']}`,
  borderRadius: '10px',
  background: theme.palette.gray['+10'],
  marginTop: '10px',
}));
const AllowanceSection = styled(Box)(({ theme }) => ({
  margin: '15px',
  padding: '5px',
  borderRadius: '10px',
  backgroundColor: `${theme.palette.gray['+9']}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const AllowanceButton = styled(Button)(({ palette }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px',
  fontWeight: '500',
  fontSize: '16px',
  '& > svg': {
    fontSize: '18px',
  },
}));
const Allowances = props => {
  const {
    onShowAllowanceModal,
    loading,
    allowances = [],
    disabledBtns,
    timecardStatus,
    capTrackingEnabled = false,
    employeeId,
  } = props;

  const { disableActions } = disabledBtns;
  const isReadyForMe = timecardStatus === 'pending_employee_review';
  return (
    <AllowancesTab>
      <AllowanceSection>
        {capTrackingEnabled && employeeId && (
          <Box sx={{ p: 2 }}>
            <ShowCapBtn variant="text" employeeId={employeeId} />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            p: 2,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {allowances.map(allowance => (
            <AllowanceCard key={allowance.worksightId} allowance={allowance} />
          ))}
          {!loading && allowances.length === 0 && <Box>{'No Allowances'}</Box>}
        </Box>
        <Tooltip
          title={`${
            disableActions && isReadyForMe
              ? 'Press Edit to enable changes to this timecard'
              : ''
          }`}
          placement="top"
        >
          <Box sx={{ margin: 'auto' }}>
            <AllowanceButton
              disabled={disableActions}
              onClick={() => onShowAllowanceModal({ worksightId: NEW_ALLO_ID })}
              startIcon={<AddCircleOutlineIcon />}
              variant="text"
            >
              Add Allowances
            </AllowanceButton>
          </Box>
        </Tooltip>
      </AllowanceSection>
    </AllowancesTab>
  );
};

Allowances.propTypes = {
  allowances: PropTypes.array.isRequired,
  onShowAllowanceModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabledBtns: PropTypes.object.isRequired,
  timecardStatus: PropTypes.string.isRequired,
  capTrackingEnabled: PropTypes.bool.isRequired,
  employeeId: PropTypes.string,
};

export default connect(mapState, mapDispatch)(Allowances);
