import { produce } from 'immer';
import * as actions from './actions';

export const initialState = {
  capTrackingData: {},
  loading: {},
  saving: false,
  deleting: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.storeCapTracking}`: {
        let data = action.capTrackingData;
        const employeeId = action.employeeId || null;
        const capDisplayOrder = [
          'BoxRental',
          'ComputerRental',
          'CarAllowance',
          'MobilePhoneAllowance',
          'PerDiem',
          'HousingAllowance',
        ];
        data.sort((a, b) => {
          return (
            capDisplayOrder.indexOf(a.category) -
            capDisplayOrder.indexOf(b.category)
          );
        });
        if (employeeId) {
          draft.capTrackingData[employeeId] = data;
        }
        break;
      }
      case `${actions.capTrackingLoader}`: {
        const { employeeId } = action;
        if (employeeId) {
          draft.loading[employeeId] = action.loading;
        }

        break;
      }
      case `${actions.savingCap}`: {
        draft.saving = action.saving;
        break;
      }
      case `${actions.deletingCap}`: {
        draft.deleting = action.deleting;
        break;
      }
      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      }
      default:
        break;
    }
  });
