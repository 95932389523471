import React from 'react';
import { useTheme } from '@mui/material';

const InvalidDealIcon = () => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Notification Type Icon">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.66699C5.40001 1.66699 1.66667 5.40033 1.66667 10.0003C1.66667 14.6003 5.40001 18.3337 10 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 10 1.66699ZM10.8333 14.167H9.16667V12.5003H10.8333V14.167ZM10.8333 10.8337H9.16667V5.83366H10.8333V10.8337Z"
          fill={palette.error.main}
        />
      </g>
    </svg>
  );
};

export default InvalidDealIcon;
