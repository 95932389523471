import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Typography, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { compose } from 'utils/helperFunctions';
import withModalDialog from 'decorators/withModalDialog';
//actions
import {
  fetchDealMemos,
  fetchInvalidDeals,
  storeDealMemos,
  fetchRounding,
  setRounding,
} from 'actions/dts';
import { showAlert } from 'actions/alert';
//selectors
import {
  getEffectiveDate,
  getDealMemos,
  getRounding,
  getRawData,
  getInvalidDeals,
} from 'selectors/dts';
//components
import SelectTable from 'components/Shared/SelectTable/SelectTable';
import { ConfirmModal } from 'components/Shared/Modals';

//utils
import { getDealMemoFields } from 'components/Shared/constants/DMTableFields';
import { DATE_FORMAT_LONG } from 'feature/DTS/dtsUtils';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    fontSize: 14,
  },
  aboveTableBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  section: {
    display: 'flex',
    '& > span': {
      padding: 2,
    },
  },

  title: {
    color: palette.text.secondary,
  },
  textGray: {
    color: palette.text.disabled,
  },
}));

const modalDialog = 'DtsDealMemoModal';
const mapStateToProps = state => ({
  effectiveDate: getEffectiveDate(state),
  dealMemos: getDealMemos(state),
  rounding: getRounding(state),
  rawData: getRawData(state),
  invalidDeals: getInvalidDeals(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchDealMemos: (employeeId, effectiveDate) => {
    dispatch(fetchDealMemos({ employeeId, effectiveDate, withPayroll: true }));
    dispatch(fetchInvalidDeals({ employeeId, effectiveDate }));
  },

  onShowAlert: () => dispatch(showAlert()),
  onCleanUp: () => dispatch(storeDealMemos({ data: [] })),
  onFetchRounding: dealMemoId => dispatch(fetchRounding({ dealMemoId })),
  onSetRounding: () => dispatch(setRounding()), //not used yet.  use to reset rounding?? not sure if needed
});

const DtsDealMemoModal = ({
  //mappedProps
  dealMemos,
  rounding,
  onFetchDealMemos,
  onFetchRounding,
  onShowAlert,
  onCleanUp,
  rawData,
  //passedProps
  effectiveDate,
  original,
  customOnModalHide,
  updateSelectVal,
  invalidDeals,
}) => {
  const classes = useStyles();

  const {
    employeeId,
    dealMemo,
    firstName,
    lastName,
    timecardEntryHeaderId,
    invoice,
  } = original;

  const isDealUnpayable =
    dealMemo.provisional === 'I' ||
    dealMemo.provisional === 'R' ||
    dealMemo.provisional === 'X';

  const isDraftCandidate = React.useMemo(() => {
    const timecard = rawData.timecards.find(
      t => t.timecardEntryHeaderId === timecardEntryHeaderId,
    );
    return timecard.draftCandidate;
  }, [rawData?.timecards, timecardEntryHeaderId]);

  const [loading, setLoading] = useState(true);

  const didMountRef = useRef(false);
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (didMountRef.current === false) {
      onFetchDealMemos(employeeId, effectiveDate.format(DATE_FORMAT_LONG));

      //interim loading removal
      timeoutRef.current = setTimeout(() => {
        if (dealMemos.length === 0) setLoading(false);
      }, 3000);
      didMountRef.current = true;
    }
  }, [
    dealMemos.length,
    effectiveDate,
    employeeId,
    onCleanUp,
    onFetchDealMemos,
  ]);

  const willUnmountRef = useRef(false);
  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        onCleanUp();
        clearTimeout(timeoutRef.current);
      }
    };
  }, [onCleanUp]);

  let anyDisabled = false;
  const list = _.sortBy(_.cloneDeep(dealMemos), dm => Number(dm.code));

  const currentDeal = list.find(dm => dm.id === original?.dealMemoTC?.id);
  const prCompanyId = currentDeal?.payrollCompany?.id;
  list.forEach(item => {
    const itemPrCompanyId = item?.payrollCompany?.id;

    if (
      !isDraftCandidate &&
      prCompanyId &&
      itemPrCompanyId &&
      itemPrCompanyId !== prCompanyId
    ) {
      item.isDisabled = true;
      anyDisabled = true;
    }
  });

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const submitRef = useRef(false);
  const submitWithRounding = React.useCallback(
    rounding => {
      const selectedId = list[selectedIndex].id;
      if (!selectedId) throw new Error(`Submit Error`);

      const result = dealMemos.find(dm => dm.id === selectedId);
      const newDeal = _.cloneDeep(result);
      const roundTo = rounding[0].roundTo;
      newDeal.roundTo = roundTo;
      updateSelectVal(newDeal);
    },
    [dealMemos, list, selectedIndex, updateSelectVal],
  );

  useEffect(() => {
    if (
      rounding &&
      _.isEmpty(rounding) === false &&
      submitRef.current === true
    ) {
      submitRef.current = false;
      submitWithRounding(rounding);
      customOnModalHide();
    }
  }, [customOnModalHide, rounding, submitWithRounding]);

  useEffect(() => {
    if (list.length > 0) setLoading(false);
    if (selectedIndex === -1 && list.length > 0) {
      const index = _.findIndex(list, l => l.id === dealMemo?.id);
      const initialIndex = index !== -1 ? index : 0;
      setSelectedIndex(initialIndex);
    }
  }, [dealMemo?.id, list, selectedIndex]);

  /**
   *  Need to fetch the rounding for the new deal after the new one has been chosen
   *  So this starts the fetch for the deal then the full update is handled by the
   *  submitWithRounding() function called above.
   */
  const onSubmit = () => {
    const selectedId = list[selectedIndex].id;
    if (!selectedId) throw new Error(`Submit Error`);

    const newDeal = dealMemos.find(dm => dm.id === selectedId);
    if (!newDeal) throw new Error(`Selected dealmemo not found`);

    submitRef.current = true;
    onFetchRounding(newDeal.id);
  };

  const hasProvisionalDeal = list.some(dm => dm.provisional === 'I');

  const title = (
    <Typography variant="h5" color="inherit">
      <span className={classes.title}>{`Deal memo`}</span>
    </Typography>
  );

  const content = (
    <div className={classes.container}>
      <div className={classes.aboveTableBar}>
        <section className={classes.section}>
          <span className={classes.textGray}>Crew Member:</span>
          <span>
            {lastName}, {firstName}
          </span>
        </section>
      </div>
      {loading ? (
        <>
          <LinearProgress />
          <h3>Loading...</h3>
        </>
      ) : list.length > 0 ? (
        <div
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              onSubmit();
            }
          }}
        >
          <SelectTable
            list={list}
            fields={getDealMemoFields('dts', false, hasProvisionalDeal)}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            // readOnly={readOnly}
            hasProvisionalDeal={hasProvisionalDeal}
            currentDeal={currentDeal}
            isDealUnpayable={isDealUnpayable || false}
            invoiceType={invoice?.invoiceType || null}
          />
          {anyDisabled && (
            <span>
              Some deal memos have been disabled because their invoice type
              doesn't match the invoice type on the timecard.
            </span>
          )}
          {invalidDeals?.invalidDealCount > 0 && (
            <div style={{ marginTop: '10px' }}>
              {`In addition, ${invalidDeals.invalidDealCount} more invalid deal exist for this user`}
            </div>
          )}
        </div>
      ) : (
        <div>Error loading dealmemos</div>
      )}
    </div>
  );

  return (
    <form>
      <ConfirmModal
        content={content}
        title={title}
        buttonText="Apply Changes"
        buttonType="secondary"
        cancelText="Cancel"
        raisedButton
        onModalHide={customOnModalHide}
        hasSubmitAction
        customSubmit
        customOnSubmit={e => {
          e.preventDefault();
          try {
            onSubmit();
          } catch (error) {
            onShowAlert();
            console.error('Error while changing deal memo.');
          }
        }}
      />
    </form>
  );
};

DtsDealMemoModal.propTypes = {
  dealMemos: PropTypes.array.isRequired,
  onFetchDealMemos: PropTypes.func.isRequired,
  onShowAlert: PropTypes.func,
  onCleanUp: PropTypes.func.isRequired,
  effectiveDate: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  customOnModalHide: PropTypes.func.isRequired,
  updateSelectVal: PropTypes.func.isRequired,
  rounding: PropTypes.object,
  onFetchRounding: PropTypes.func,
  rawData: PropTypes.object,
  invalidDeals: PropTypes.object,
};

export default compose(
  withModalDialog({ dialog: modalDialog, maxWidth: 'lg' }),
  connect(mapStateToProps, mapDispatchToProps),
)(DtsDealMemoModal);
