import _ from 'lodash';
import { currentUser } from 'selectors/session';
import { createSelector } from 'reselect';
import { getFormValues } from 'selectors/formValues';
import { getIsProjectArchived } from 'selectors/project';

export const getUserReports = createSelector(
  [currentUser, getIsProjectArchived],
  (activeUser, isProjectArchived) => {
    let reports = _.filter(REPORTS, report =>
      _.find(report.roles, role => role === activeUser.role),
    );
    if (isProjectArchived) {
      reports = reports.filter(reports => reports.yep === true);
    }
    return reports;
  },
);

export const getLoading = state => _.get(state, 'reports.loading', false);
export const getScheduledReports = state =>
  _.get(state, 'reports.scheduledReports', []);
export const getReportName = reportKey => {
  let report = _.find(REPORTS, report => report.key === reportKey);
  return report ? report.name : '';
};

export const getClientName = report => {
  return report.projects.map(project => {
    const projectClientName = project.client && project.client.name;
    return (
      project.name + (!!projectClientName ? ' / ' + projectClientName : '')
    );
  });
};

export const getEditingScheduledReport = state => {
  const editing = _.get(state, 'reports.editingScheduledReport', {});
  const reportCopy = { ...editing };
  let report = _.find(REPORTS, report => report.key === reportCopy.reportType);
  const reportName = report ? report.name : '';

  let end = null;
  let frequency = reportCopy.frequency;
  if (!reportCopy || !reportCopy.id) {
    end = 'endDate';
    frequency = 'daily';
  }
  if (!!reportCopy.endDate) {
    end = 'endDate';
  }
  if (!!reportCopy.endNever) {
    end = 'endNever';
  }
  if (!!reportCopy.endOccurences) {
    end = 'endOccurences';
  }

  return { ...editing, reportName, end, frequency };
};

export const getScheduledReportFormValues = formName =>
  createSelector(
    [getEditingScheduledReport, getFormValues(formName)],
    (initial, updates) => {
      return updates || initial;
    },
  );

const REPORTS = [
  {
    key: 'hot-cost',
    name: 'Hot Cost Report',
    roles: ['payroll_accountant', 'upm'],
    from: null,
    requiresTimes: true,
    yep: true,
  },
  {
    key: 'spot-audit',
    name: 'Spot Audit Report',
    roles: ['department_head', 'payroll_accountant', 'upm'],
    from: null,
    to: null,
    requiresTimes: true,
    yep: true,
  },
  {
    key: 'open-batch',
    name: 'Open Batch Report',
    roles: ['department_head', 'payroll_accountant', 'upm'],
    requiresTimes: false,
    yep: false,
  },
  {
    key: 'batch-line',
    name: 'Batch Line Item Report',
    roles: ['upm'],
    from: null,
    to: null,
    requiresTimes: true,
    yep: true,
  },
  {
    key: 'pending-timecards',
    name: 'Pending Timecards',
    roles: ['department_head', 'payroll_accountant', 'upm'],
    requiresTimes: false,
    yep: false,
  },
];

export const getIncorrectEmailProjects = state =>
  _.get(state, 'reports.incorrectEmailProjects', []);
export const getCorrectEmailProjects = state =>
  _.get(state, 'reports.correctEmailProjects', []);

export const getSortedAdminUsers = state =>
  _.get(state, 'reports.sortedAdminUsers', []);

export const getSelectedUser = state =>
  _.get(state, 'reports.selectedUser', {});

export const getAdminUserInitialValues = createSelector(
  [getSelectedUser],
  editUser => {
    const user = {
      firstName: editUser?.firstName || '',
      lastName: editUser?.lastName || '',
      email: editUser?.email || '',
      roles: editUser?.roles?.split(', ') || [],
    };
    return user;
  },
);
export const getHotCostParams = state =>
  _.get(state, 'reports.hotCostParams', {});
