import React, { useState } from 'react';
import { Table } from '@mui/material';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import SelectTableHeaderRow from './SelectTableHeaderRow';
import SelectTableRows from './SelectTableRows';

const useStyles = makeStyles(theme => ({
  tableContainer: maxHeight => ({
    overflow: 'auto',
    maxHeight: maxHeight || '',
  }),
}));

const SelectTable = ({
  list,
  fields,
  selectedIndex,
  setSelectedIndex,
  readOnly = false,
  dmScaleRatesLoading,
  maxHeight,
  hasProvisionalDeal,
  currentDeal,
  invoiceType,
  isDealUnpayable,
}) => {
  const classes = useStyles(maxHeight);
  const [isScrolling, setIsScrolling] = useState(false);

  const checkScrollAtStart = () => {
    const table = document.getElementsByClassName(classes.tableContainer)[0];
    const scrollAmount = table && table.scrollLeft;
    if (scrollAmount === 0) {
      setIsScrolling(false);
    } else {
      setIsScrolling(true);
    }
  };

  const scrollCheck = () => {
    checkScrollAtStart();
  };

  const fieldSet = new Set();

  list.forEach(item => {
    fields.forEach((field, index) => {
      try {
        const text = field.renderFunc(item, field.dataLoc);
        if ((text && text !== '') || field.dataLoc === 'icon') {
          fieldSet.add(index);
        }
      } catch (error) {
        console.error('Error during parsing deal memo');
      }
    });
  });

  const validIndex = Array.from(fieldSet).sort((a, b) => a - b);
  const validFields = validIndex.map(index => fields[index]);

  return (
    <div
      id="selectTable"
      className={classes.tableContainer}
      onScroll={scrollCheck}
    >
      <Table className={classes.table}>
        <SelectTableHeaderRow
          isScrolling={isScrolling}
          fields={validFields}
          hasProvisionalDeal={hasProvisionalDeal}
        />
        <SelectTableRows
          list={list}
          fields={validFields}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          isScrolling={isScrolling}
          readOnly={readOnly}
          dmScaleRatesLoading={dmScaleRatesLoading}
          hasProvisionalDeal={hasProvisionalDeal}
          currentDeal={currentDeal}
          invoiceType={invoiceType}
          isDealUnpayable={isDealUnpayable}
        />
      </Table>
    </div>
  );
};

SelectTable.propTypes = {
  list: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  readOnly: PropTypes.bool,
  dmScaleRatesLoading: PropTypes.bool,
  maxHeight: PropTypes.number,
};

export default SelectTable;
