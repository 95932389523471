import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { getDealMemoFields } from '../constants/DMTableFields';
import SelectTable from 'components/Shared/SelectTable/SelectTable';
import InvalidDealIcon from 'components/images/InvalidDealIcon';

import {
  fetchDealMemos,
  fetchAndSetPayAtScale,
  updateAutoAllowances,
  setDealMemoModal,
} from 'actions/wtc';
import {
  getDealMemos,
  getDMScaleRatesLoading,
  getDealMemoModal,
  isWTCDisabled,
  getFullFormValues,
} from 'selectors/wtc';
import { showAlert } from 'actions/alert';
import {
  handleChangeDealMemoWTCTimecard,
  handleChangeDealMemosWTCDay,
  hasActiveUnpayableDeal,
  isInvalidDealTimecard,
} from 'utils/wtcWeekUtils';

const useStyles = makeStyles(({ palette }) => ({
  WTCDealMemoComp: {
    padding: '20px 24px',
  },
  container: {
    fontSize: 14,
  },
  aboveTableBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  isInvalidDeal: {
    display: 'flex',
    gap: '8px',
    padding: '5px',
    border: 'solid 1px',
    marginBottom: '5px',
    background: '#FFEAED',
    borderLeft: 'solid 6px red',
  },
  scopeAlert: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > div': {
      border: '1px solid #f89b57',
      backgroundColor: '#fdebdd', //hardCoded Colors
      color: '#f89b57',
      padding: 8,
    },
  },
  section: {
    color: palette.text.secondary,
    display: 'flex',
    '& > span': {
      padding: 2,
    },
  },
  textGray: {
    color: palette.text.disabled,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '30px 24px',
  },
}));

const mapState = state => ({
  modalData: getDealMemoModal(state),
  dealMemos: getDealMemos(state),
  dmScaleRatesLoading: getDMScaleRatesLoading(state),
  wtcDisabled: isWTCDisabled(state),
  timecard: getFullFormValues(state),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(setDealMemoModal({ dealMemoModal: null })),
  onFetchDealMemos: (employeeId, startDate, endDate) => {
    dispatch(
      fetchDealMemos({
        employeeId,
        startDate,
        endDate,
        shouldAddRounding: true,
        withPayroll: true,
        shouldAddScale: true,
      }),
    );
  },
  onShowAlert: () => dispatch(showAlert()),
  onFetchAndSetScaleRate: ({ member, payAtScale }) => {
    dispatch(fetchAndSetPayAtScale({ member, payAtScale, showError: true }));
  },
  onShowRoundingMsg: () => {
    dispatch(
      showAlert({
        message:
          'The selected deal memo has different rounding rules than the previous deal memo. Values have been rounded accordingly.',
        variant: 'info',
      }),
    );
  },
  onUpdateAutoAllowances: () => dispatch(updateAutoAllowances()),
});

const WTCDealMemoComp = props => {
  const classes = useStyles();
  const {
    modalData: modalSource,
    dmScaleRatesLoading,
    dealMemos,
    onCloseModal,
    onFetchDealMemos,
    onShowAlert,
    onShowRoundingMsg,
    onUpdateAutoAllowances,
    onFetchAndSetScaleRate,

    change,
    timecard,
    wtcDisabled,
    onSetFieldVisibility,
    masterRowData,
  } = props;

  const {
    details = [],
    allowances = [],
    dealMemo: timecardDealMemo,
    invoice,
  } = timecard;

  const [needToFetchDeals, setNeedToFetchDeal] = React.useState(true);
  const anyFringeEnabledDeal = dealMemos?.some(dm =>
    dm.guarantees.some(g => g.grossIncludesVACFGN === true),
  );

  React.useEffect(() => {
    if (needToFetchDeals) {
      if (modalSource.source === 'day') {
        setNeedToFetchDeal(false);
        onFetchDealMemos(
          timecard.employee.id,
          modalSource.startDate,
          modalSource.endDate,
        );
        return () => {
          onFetchDealMemos(
            //fetch week deal memo on close
            timecard.employee.id,
            timecard.weekStartingDate,
            timecard.weekEndingDate,
          );
        };
      } else if (modalSource.source === 'week') {
        const startDate = timecard.weekStartingDate
          ? timecard.weekStartingDate
          : undefined;
        const endDate = timecard.weekEndingDate
          ? timecard.weekEndingDate
          : undefined;

        if (startDate && endDate) {
          setNeedToFetchDeal(false);
          onFetchDealMemos(timecard.employee.id, startDate, endDate);
        }
      }
    }
  }, [modalSource, needToFetchDeals, onFetchDealMemos, timecard]);

  const [list, setList] = React.useState([]);
  let anyDisabled = React.useRef(false);
  const [invalidDays, setInvalidDays] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  React.useEffect(() => {
    //sorting by dealMemo code so the deal memo display matches the order in the drop down on WTC
    const newList = _.sortBy(_.cloneDeep(dealMemos), dm => Number(dm.code));

    //Disable deals that do not match the timecard deal payroll company
    const dealMemoTC = newList.find(deal => deal.id === timecardDealMemo?.id);
    const prCompanyId = dealMemoTC?.payrollCompany?.id;
    newList.forEach(deal => {
      const currPrCompanyId = deal?.payrollCompany?.id;
      if (prCompanyId && currPrCompanyId && currPrCompanyId !== prCompanyId) {
        deal.isDisabled = true;
        anyDisabled.current = true;
      }
    });

    setList(newList);
    const currentMemoIndex = _.findIndex(newList, {
      id: modalSource.currentDeal,
    });

    const newVal = currentMemoIndex === -1 ? -1 : currentMemoIndex;
    setSelectedIndex(newVal);
  }, [
    dealMemos,
    modalSource.currentDeal,
    modalSource.source,
    timecardDealMemo,
  ]);

  const selectedId = list[selectedIndex]?.id;
  const currentDeal = dealMemos.find(dm => dm.id === selectedId);

  //Scan for invalid days on selected deal
  React.useEffect(() => {
    if (!currentDeal) return;

    const { start, end } = currentDeal;
    const invalidDays = [];
    details.forEach(detail => {
      if (
        !detail.unusedDay &&
        detail.dealMemo &&
        (moment(detail?.effectiveDate).isBefore(start) ||
          moment(detail?.effectiveDate).isAfter(end))
      ) {
        const date = moment(detail.effectiveDate).format('dddd MMM DD');
        invalidDays.push(date);
      }
    });

    setInvalidDays(invalidDays);
  }, [dealMemos, details, currentDeal]);
  const readOnly = wtcDisabled || dealMemos.length === 0;
  const hasProvisionalDeal = dealMemos.some(dm => dm.provisional === 'I');
  const isInvalidDeal = isInvalidDealTimecard(timecard);
  const scopeAlertText =
    modalSource.source === 'day'
      ? 'Changing the deal here will change the deal memo for the only the selected day'
      : 'Changing the deal here will change the deal memo for the whole timecard, including allowances, and all days';
  const isInvalidDealMemoText =
    '  The Deal memo cannot be used because it is no longer valid. Select a valid deal memo to proceed';
  const cancelText = readOnly ? 'Close Window' : 'Cancel, and Close Window';
  const onSubmit = () => {
    try {
      const selectedId = list[selectedIndex].id;
      switch (modalSource.source) {
        case 'week':
          if (!selectedId) {
            throw new Error('Unable to change deal memo for selected day.');
          }
          handleChangeDealMemoWTCTimecard(selectedId, {
            change,
            timecard,
            details,
            dealMemos,
            allowances,
            onFetchAndSetScaleRate,
            onShowRoundingMsg,
            onSetFieldVisibility,
            onUpdateAutoAllowances,
            masterRowData,
          });

          break;
        case 'day': {
          const date = moment(modalSource.startDate).format('YYYY-MM-DD');
          const detailIndex = _.findIndex(details, detail => {
            const detailDate = moment(detail.effectiveDate).format(
              'YYYY-MM-DD',
            );
            if (detailDate === 'Invalid date') return false;
            return date === detailDate;
          });

          const detail = details[detailIndex];
          const member = `details[${detailIndex}]`;

          if (!detail || !selectedId) {
            throw new Error('Unable to change deal memo for selected day.');
          }

          handleChangeDealMemosWTCDay(selectedId, {
            change,
            detail,
            member,
            dealMemos,
            onFetchAndSetScaleRate,
            onShowRoundingMsg,
            onSetFieldVisibility,
          });
          break;
        }

        default:
          throw new Error(`Invalid modal source:  ${modalSource}`);
      }

      onCloseModal();
    } catch (error) {
      console.error('Error when changing deal memo', error);
      onShowAlert();
    }
  };

  const { invoiceType } = invoice;
  const isDealUnpayable = hasActiveUnpayableDeal(timecard);

  return (
    <main className={classes.WTCDealMemoComp}>
      <div>
        {isInvalidDeal && (
          <Box className={classes.isInvalidDeal}>
            <Box>
              <InvalidDealIcon />
            </Box>
            <Box style={{ fontWeight: 'bold' }}> Invalid Deal Memo </Box>
            <Box> {isInvalidDealMemoText} </Box>
          </Box>
        )}
      </div>
      {dealMemos.length === 0 ? (
        <Typography align="center" variant="h6">
          No valid deals exist.
        </Typography>
      ) : (
        <div
          className={classes.container}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit();
              onCloseModal();
            }
          }}
        >
          <div className={classes.scopeAlert}>
            <div>{scopeAlertText}</div>
          </div>
          <div className={classes.aboveTableBar}>
            <section className={classes.section}>
              <span className={classes.textGray}>Crew Member:</span>
              <span>{timecard.employee.name}</span>
            </section>
          </div>
          <SelectTable
            list={list}
            fields={getDealMemoFields(
              'wtc',
              anyFringeEnabledDeal,
              hasProvisionalDeal,
            )}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            readOnly={readOnly}
            dmScaleRatesLoading={dmScaleRatesLoading}
            maxHeight={350}
            hasProvisionalDeal={hasProvisionalDeal}
            invoiceType={invoiceType}
            currentDeal={currentDeal}
            isDealUnpayable={isDealUnpayable}
          />
          {anyDisabled.current && (
            <span>
              Some deal memos have been disabled because their invoice type
              doesn't match the invoice type on the timecard.
            </span>
          )}
          {invalidDays.length > 0 && (
            <Box className={classes.scopeAlert}>
              <div>
                The selected deal is not valid for the following days on this
                timecard:
                <br />
                <b> "{invalidDays.join(', ')}"</b> <br />
                Changing the deal will remove these days from the timecard.
              </div>
            </Box>
          )}
        </div>
      )}
      <footer className={classes.footer}>
        <Button variant="outlined" onClick={onCloseModal}>
          {cancelText}
        </Button>
        {!readOnly && <Button onClick={onSubmit}>Apply Changes</Button>}
      </footer>
    </main>
  );
};

WTCDealMemoComp.propTypes = {
  modalData: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onSetFieldVisibility: PropTypes.func.isRequired,
  onFetchDealMemos: PropTypes.func,
  onShowAlert: PropTypes.func.isRequired,
  onFetchAndSetScaleRate: PropTypes.func.isRequired,
  onShowRoundingMsg: PropTypes.func,
  onUpdateAutoAllowances: PropTypes.func.isRequired,
  timecard: PropTypes.object,
  wtcDisabled: PropTypes.bool.isRequired,
  dmScaleRatesLoading: PropTypes.bool.isRequired,
  dealMemos: PropTypes.array.isRequired,
};

export default compose(connect(mapState, mapDispatch))(WTCDealMemoComp);
