import React from 'react';
import PropTypes from 'prop-types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  InstantDeal: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    verticalAlign: 'top',
  },
}));
const InstantDealIcon = props => {
  const {
    tooltipText = 'Offer Pending Approval',
    label = '',
    color = 'warning.main',
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.InstantDeal}>
      <Box>
        <Tooltip title={tooltipText}>
          <span>
            <WarningAmberIcon sx={{ color, width: 20, verticalAlign: 'top' }} />
          </span>
        </Tooltip>
      </Box>
      {label && (
        <Box sx={{ color: color, fontWeight: 500, ml: '10px' }}>{label}</Box>
      )}
    </Box>
  );
};
InstantDealIcon.propTypes = {
  tooltipText: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
};

export default InstantDealIcon;
