import { produce } from 'immer';
import _isEmpty from 'lodash/isEmpty';
import * as actions from 'actions/userInfo';

const initialState = {
  loading: false,
  activeUser: {},
  activeUserFetchTime: {}, //store the last time the user data was fetched for a project
  permissions: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.store}`: {
        const { projectId, activeUser } = action;

        if (Number.isNaN(Number(projectId))) {
          console.error(`StoreProjUser: Invalid projectId: ${projectId}`);
          return;
        }

        if (activeUser && _isEmpty(activeUser) === false) {
          draft.activeUser[projectId] = activeUser;
          draft.activeUserFetchTime[projectId] = Date.now();
        } else {
          draft.activeUser[projectId] = null;
          draft.activeUserFetchTime[projectId] = null;
        }

        break;
      }

      case `${actions.loading}`: {
        draft.loading = action.loading;
        break;
      }

      case `${actions.storePermissions}`: {
        draft.permissions[action.projectId] = action.permissions;
        break;
      }

      default:
    }
  });
