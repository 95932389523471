import React from 'react';

import {
  Header,
  BlankHeader,
  ProjectHeader,
  MaxWidthLayout,
} from 'containers/Layout';

import { SEARCH_INVOICE_PATH } from 'components/Routes/paths';

//components
const SearchInvoices = React.lazy(() =>
  import('feature/SearchInvoices/SearchInvoices'),
);
const DigitalEdits = React.lazy(() =>
  import('feature/DigitalEdits/DigitalEdits'),
);
const CommentWindow = React.lazy(() =>
  import('feature/DigitalEdits/Comments/CommentWindow'),
);

const RedirectComp = React.lazy(() => import('feature/DigitalEdits/Redirect'));

const projectFeatureInvoiceRoutes = [
  {
    title: 'Search Invoices',
    path: SEARCH_INVOICE_PATH,
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: SearchInvoices,
    waitForUserToLoad: true,
  },
  {
    title: 'Digital Edits',
    path: '/projects/:projectId/digital-edits/:invoiceId',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: DigitalEdits,
    waitForUserToLoad: true,
  },
  {
    title: 'Invoice Comments',
    path: '/projects/:projectId/invoice-comments/:invoiceId',
    headers: [BlankHeader],
    layout: MaxWidthLayout,
    component: CommentWindow,
    waitForUserToLoad: true,
    noCnCHeader: true,
  },
  {
    title: '',
    path: '/projects/:projectId/digitalEdit/tokens/:token',
    headers: [BlankHeader],
    layout: MaxWidthLayout,
    component: RedirectComp,
    waitForUserToLoad: true,
    noCnCHeader: true,
  },
];

export default projectFeatureInvoiceRoutes;
