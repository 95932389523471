import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
  getCapTrackingData,
  getCapLoading,
} from 'feature/CapTracking/selectors';
import { fetchCapTracking } from 'feature/CapTracking/actions';
import { dtsCapVar } from 'feature/CapTracking/capUtils';

import CapTracking from 'feature/CapTracking/CapTracking';

const useStyles = makeStyles(({ palette }) => ({
  DtsCapTrackWrap: {},
}));

const mapState = (state, ownProps) => ({
  capTrackingData: getCapTrackingData(state, ownProps.employeeId),
  loading: getCapLoading(state, ownProps.employeeId),
});

const mapDispatch = dispatch => ({
  onFetchCapTracking: params => dispatch(fetchCapTracking(params)),
});

const DtsCapTrackWrap = props => {
  const classes = useStyles();
  const { employeeId, capTrackingData, onFetchCapTracking, loading } = props;

  React.useEffect(() => {
    onFetchCapTracking({ employeeId });
  }, [employeeId, onFetchCapTracking]);

  const isEmpty = capTrackingData.length === 0;

  return (
    <Box className={classes.DtsCapTrackWrap}>
      {loading || isEmpty ? (
        <Box
          sx={{ fontSize: '1.5em', color: 'text.disabled', p: '16px 220px' }}
        >
          {loading ? <LinearProgress /> : 'No allowance cap on timecard'}
        </Box>
      ) : (
        <CapTracking capTrackingData={capTrackingData} variant={dtsCapVar} />
      )}
    </Box>
  );
};

DtsCapTrackWrap.propTypes = { children: PropTypes.element };

export default compose(connect(mapState, mapDispatch))(DtsCapTrackWrap);
