/* eslint-disable mui-unused-classes/unused-classes */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip, CircularProgress, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  EmergencyIcon,
  ForceResubmitIcon,
} from 'containers/Employees/Reviews/Shared/timecardUtils';
import {
  isInstantDealTimecard,
  isInvalidDealTimecard,
} from 'utils/wtcWeekUtils';
import InstantDealIcon from 'components/Shared/InstantDealIcon';
import InvalidDealIcon from 'components/Shared/InvalidDealIcon';

import moment from 'moment';
//utils
import {
  STYLING,
  searchDateFormat,
  makeDaysEntered,
  canTimecardBeSelected,
  makeDaysEnteredTooltip,
} from 'components/Employees/Reviews/SearchTimecards/searchUtils';

//components
import TimecardCommentIcon from 'containers/Employees/Reviews/Shared/TimecardCommentIcon';
import { OtherApprovers } from 'components/Employees/Reviews/ApproverList';
import { onBlurNumber, statusClass } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  cell: {
    border: `1px ${palette.primary['+8']} solid`,
    borderLeft: 'none',
    borderRight: 'none',
    whiteSpace: 'nowrap',
    padding: STYLING.cellPadding,
  },
  emergency: {
    paddingRight: 0,
  },
  numbers: {
    textAlign: 'right',
  },

  toolTipText: {
    fontSize: 14,
  },

  //Employee styles
  link: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      color: palette.misc.link,
    },
  },
  //Current Approver Styles
  currentApprovers: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'default',
  },
  approver: {
    color: palette.info.main,
    paddingRight: 5,
    alignSelf: 'center',
  },
  emergencyIcon: {
    paddingTop: 5,
  },
  checkbox: {
    padding: 1,
  },
  errorDay: {
    color: 'red',
    fontWeight: 800,
  },
  hours: {
    padding: '5px 10px',
    fontSize: 12,
  },
  styleContent: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    cursor: 'pointer',
    padding: '3px',
    borderRadius: '5px',
    marginLeft: '5px !important',
    marginRight: '5px !important',
    display: 'inline-block',
    // display: 'flex', //If we want to go full width pills, swap out this display for the inline-block one
    // alignItems: 'center',  // should do it for all status pills ie search invoices as well. so holding off for now
    marginTop: '2px !important',
    marginBottom: '2px !important',
  },
  shortCell: {
    maxWidth: 75,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  //Button Colors are referenced programmatically
  redBtn: {
    background: palette.button.lightRed.background,
    border: `solid 1px ${palette.button.lightRed.background}`,
    color: palette.button.lightRed.color,
  },
  orangeBtn: {
    background: palette.button.orange.background,
    border: `solid 1px ${palette.button.orange.background}`,
    color: palette.button.orange.color,
  },
  yellowBtn: {
    background: palette.button.yellow.background,
    border: `solid 1px ${palette.button.yellow.background}`,
    color: palette.button.yellow.color,
  },
  grayBtn: {
    background: palette.button.gray.background,
    border: `solid 1px ${palette.button.gray.background}`,
    color: palette.button.gray.color,
  },
  greenBtn: {
    background: palette.button.lightGreen.background,
    border: `solid 1px ${palette.button.lightGreen.background}`,
    color: palette.button.lightGreen.color,
  },
}));

const currencyColumns = ['allowances', 'laborTotal', 'grossTotal'];
const numColumns = ['account', 'totalHours', 'paidHours', 'workHours'];

const totalColumns = ['paidHours', 'laborTotal', 'grossTotal'];

const Cell = props => {
  const classes = useStyles();
  const { cell, onClickTimecard, row, currentUserRole } = props;
  const id = cell.column.id;
  let value = cell.value;

  // set up formatting for cell based on column ID.
  // Can't move this in to utils because I want to keep the styles defined here.
  // if this gets much larger, will probably break each cell type to their own component

  const isCurrency = currencyColumns.includes(id);
  const isNum = numColumns.includes(id);
  const isTotalVal = totalColumns.includes(id);

  const isStatus = id === 'status';
  const isEmployee = id === 'employee';
  const isCurrentApprovers = id === 'currentApprovers';
  const isComment = id === 'comments';
  const isDate = id === 'weekEndingDate';
  const isEmergency = id === 'emergencyType';
  const isAllowances = id === 'allowances';
  const isSelection = id === 'selection';
  const isBatchNum = id === 'batchWorksightHtgId';
  const isDaysEntered = id === 'daysEntered';
  const isLastModified = id === 'lastModified';

  const isInvoiceDesc = id === 'invoiceChargeDescription';
  const isBatchName = id === 'batchName';

  if (isCurrency && !isAllowances && (value || value === 0)) {
    value = `$${value.toFixed(2)}`;
  }

  if (isAllowances && (value || value === 0)) {
    value = `$${onBlurNumber(value)}`;
  }
  if (isEmergency) {
    const isInstantDealMemoTC = isInstantDealTimecard(cell.row.original);
    const isInvalidDealMemo = isInvalidDealTimecard(cell.row.original);
    const emergencyType = cell.row.original.emergencyType;
    if (isInstantDealMemoTC) {
      value = <InstantDealIcon />;
    } else if (isInvalidDealMemo) {
      value = <InvalidDealIcon />;
    } else if (emergencyType) {
      value = <EmergencyIcon className={classes.emergencyIcon} />;
    } else if (cell.row.original.workflowAction === 'resubmitToEmployee') {
      value = <ForceResubmitIcon className={classes.emergencyIcon} />;
    }
  }

  if (isInvoiceDesc || isBatchName) {
    value = (
      <Tooltip title={value || ''}>
        <div className={classes.shortCell}>{value}</div>
      </Tooltip>
    );
  }

  if (isDate) {
    value = (
      <span
        onClick={e => {
          setTimeout(() => {
            //setTimeout lets the selected be visible
            onClickTimecard({ timecard: row.original });
          }, 0);
        }}
      >
        {searchDateFormat(value)}
      </span>
    );
  }

  if (isEmployee) {
    value = (
      <span
        className={classes.link}
        onClick={e => {
          setTimeout(() => {
            //setTimeout lets the selected be visible
            onClickTimecard({ timecard: row.original });
          }, 0);
        }}
      >
        {value}
      </span>
    );
  }

  if (isCurrentApprovers && value) {
    const approversList = value.split('~');
    const firstApprover = approversList[0];

    value = (
      <div
        className={classes.currentApprovers}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <span className={classes.approver}>{firstApprover}</span>
        <OtherApprovers list={approversList} />
      </div>
    );
  }

  if (isComment) {
    const { timecardEntryHeaderId, hasComment, status } = cell.row.original;
    if (status !== 'Paid' || hasComment) {
      const partialTimecard = {
        timecardEntryHeaderId,
        hasComment,
        status,
      };
      value = <TimecardCommentIcon partialTimecard={partialTimecard} />;
    }
  }

  if (isTotalVal) {
    const worksightStatus = cell.row.original.worksightStatus;
    if (worksightStatus !== 'Approved') {
      value = (
        <Tooltip
          title={
            <span className={classes.toolTipText}>
              Totals will display once the timecard is approved by the payroll
              accountant
            </span>
          }
        >
          <div>--</div>
        </Tooltip>
      );
    }
  }

  //checkbox rendered here, render defined in tableColumns
  if (isSelection) {
    if (canTimecardBeSelected(currentUserRole, cell.row.original.status)) {
      value = cell.render('Cell');
    } else {
      value = <div></div>;
    }
  }

  if (isBatchNum) {
    value = (
      <span
        onClick={e => {
          setTimeout(() => {
            //setTimeout lets the selected be visible
            onClickTimecard({ timecard: row.original });
          }, 0);
        }}
      >
        {cell.value}
      </span>
    );
  }

  if (isDaysEntered) {
    if (value && Object.keys(value).length > 0) {
      value = (
        <Tooltip
          title={<span>{makeDaysEnteredTooltip({ value })}</span>}
          arrow
          classes={{ tooltip: classes.hours }}
        >
          <div>{makeDaysEntered({ value, errorClass: classes.errorDay })}</div>
        </Tooltip>
      );
    } else {
      value = makeDaysEntered({ value, errorClass: classes.errorDay });
    }
  }

  if (isStatus) {
    const hasProgressIndicator = row.original.progressIndicator;
    value = (
      <div
        className={clsx(classes.styleContent, classes[statusClass(cell.value)])}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {hasProgressIndicator && (
            <CircularProgress sx={{ mr: 1 }} size={12} />
          )}
          {cell.value}
        </Box>
      </div>
    );
  }

  if (isLastModified) {
    value = moment(value).format('YYYY-DD-MM - HH:mm');
  }
  const { key, ...cellProps } = cell.getCellProps();
  return (
    <td
      key={key}
      {...cellProps}
      className={clsx(classes.cell, {
        [classes.numbers]: isCurrency || isNum,
        [classes.emergency]: isEmergency,
        [classes.checkbox]: isSelection,
      })}
      style={{
        maxWidth: 275,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value}
    </td>
  );
};

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
  onClickTimecard: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

export default Cell;
