import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
//comps
import AllowanceRow from './AllowancesRow';
import { WTCTableCell as TableCell } from 'components/Shared/Table/';
//utils
import { canadianLabel, isRegionCanada } from 'utils/helperFunctions';
import { createNewWTCAllowance } from 'utils/wtcWeekUtils';

//actions
import { downloadDefaultAllowanceDocument } from 'actions/profile';
import { showAlert } from 'actions/alert';

//selectors
import {
  getAllowancesTableOrder,
  getPayCodes,
  getDateOptions,
  isAllowanceDisabled,
  getAllowanceTypes,
} from 'selectors/wtc';
import { getProjectUser } from 'selectors/session';
import CapTrackingModal from 'feature/CapTracking/CapTrackingModal';
import ShowCapBtn from 'feature/CapTracking/ShowCapBtn';
import { wtcCapVar } from 'feature/CapTracking/capUtils';
import CapTrackingDeleteModal from 'feature/CapTracking/CapTrackingDeleteModal';
import { getCapTrackingData } from 'feature/CapTracking/selectors';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'block !important',
    width: '100%',
    boxShadow: 'none',
  },
  allowanceTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  addRowRow: {
    float: 'right',
    position: 'absolute',
    top: 16,
    right: 32,
    display: 'flex',
  },
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  table: {
    overflowX: 'auto',
  },
  tableBody: {
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary['+9'],
    },
  },
  expansionDetails: {
    paddingTop: 0,
  },
  supportDocHeader: { lineHeight: 1 },
  supportDocHeaderText: { fontSize: 10, color: 'gray' },
}));

const mapState = (state, ownProps) => ({
  allowancesOrder: getAllowancesTableOrder(state),
  payCodes: getPayCodes(state),
  dateOptions: getDateOptions(state),
  currentUser: getProjectUser(state),
  allowanceDisabled: isAllowanceDisabled(state),
  allowanceTypes: getAllowanceTypes(state),
  capTrackingData: getCapTrackingData(state, ownProps.employeeId),
});

const mapDispatch = dispatch => ({
  onDownloadDocument: token => {
    dispatch(downloadDefaultAllowanceDocument({ token, fileName: token }));
  },
  onShowAlert: params => dispatch(showAlert(params)),
});

const RenderBody = props => {
  const {
    fields,
    classes,
    allowancesOrder,
    masterRowData,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    dealMemos,
    dealMemoId,
    episodes,
    loadEpisodes,
    payCodes,
    allowances = [],
    change,
    blur,
    error,
    allowanceDisabled,
    upmEnabled,
    maskingFunc,
    accountCodeMask,
    dateOptions,
    isUPM,
    //
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    subdivisions,
    locationFuncs,
    onShowAlert,
    project,
    pendingCalculation,
    onDownloadDocument,
    timecard,
    employeeId,
    allowanceTypes,
    capTrackingData = [],
  } = props;

  const allowanceErrors = error.allowances || [];
  const filterArray = allowances?.filter(
    item => parseFloat(item?.factor || 1) === 1.0,
  );
  const hasFactorNot1 = filterArray.length === allowances?.length;

  function handleAddRow() {
    const locationTypeId = timecard?.locationTypeId || null;
    const newAllowance = createNewWTCAllowance(
      masterRowData,
      dealMemos,
      locationTypeId,
      workLocations,
    );
    fields.push(newAllowance);
  }

  function handleCopyRow(currentIndex, selectedRow) {
    selectedRow.copied = true;
    selectedRow.rowId = null;
    selectedRow.tempId = crypto.randomUUID();
    fields.splice(currentIndex + 1, 0, selectedRow);
  }
  const caRegion = isRegionCanada(project.region);

  return (
    <div className={classes.table}>
      <Box className={classes.addRowRow}>
        {pendingCalculation && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UpdateIcon
              sx={{ height: 16, width: 16, mr: '3px', color: 'primary.main' }}
            />
            <Typography key={'sub'} variant="caption">
              Allowance actions disabled during calculation{' '}
            </Typography>
          </Box>
        )}
        <ShowCapBtn disabled={pendingCalculation} employeeId={employeeId} />

        <Button
          variant="text"
          color="primary"
          onClick={handleAddRow}
          disabled={allowanceDisabled || pendingCalculation}
        >
          <AddIcon />
          <span>Add Row</span>
        </Button>
      </Box>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {_.map(allowancesOrder, (header, i) => {
              const iscaRegionLocationType =
                caRegion && header.columnId === 'locationType';
              const isFactorVisible =
                hasFactorNot1 && header.columnId === 'factor';
              return (
                <TableCell
                  key={i}
                  display={
                    iscaRegionLocationType || isFactorVisible
                      ? false
                      : header.visible
                  }
                  header
                >
                  {header.label !== 'Supporting Document' ? (
                    <span>{canadianLabel(header.label)}</span>
                  ) : (
                    <div className={classes.supportDocHeader}>
                      <span>{header.label}</span>
                      <br />
                      <span className={classes.supportDocHeaderText}>
                        (max size 10 MB, use PDF, JPG, PNG formats)
                      </span>
                    </div>
                  )}
                </TableCell>
              );
            })}
            <TableCell display sticky={true}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {fields.map((allowanceName, index, { get }) => {
            const reimbursementType = _.get(
              allowances,
              `[${index}].reason`,
              '',
            );
            const reimbursementId = !_.isEmpty(reimbursementType)
              ? reimbursementType.id
              : null;

            const category = allowanceTypes.find(
              type => type.id === reimbursementId,
            )?.category;

            const capItem = capTrackingData.find(
              cap => cap.category === category,
            );
            const allowanceError = allowanceErrors[index];
            const allowance = get(index) || {};
            const key = allowance.rowId || allowance.tempId;

            const currentStateId = _.get(
              allowances,
              `[${index}].workState.id`,
              '',
            );
            const currentCountryId = _.get(
              allowances,
              `[${index}].workCountry.id`,
              '',
            );

            const supportDocument = _.get(
              allowances,
              `[${index}].comment`,
              '',
            ).replace(/<[^>]+>/g, '');
            // regex for removing some html tag

            return (
              <AllowanceRow
                onShowAlert={onShowAlert}
                removeRow={() => fields.remove(index)}
                key={key}
                allowancesOrder={allowancesOrder}
                allowanceName={allowanceName}
                allowanceData={allowance}
                workLocations={workLocations}
                dealMemos={dealMemos}
                dealMemoId={dealMemoId}
                episodes={episodes}
                loadEpisodes={loadEpisodes}
                payCodes={payCodes}
                supportDocument={supportDocument}
                index={index}
                occupationCodes={occupationCodes}
                loadOccupationCodes={loadOccupationCodes}
                change={change}
                blur={blur}
                allowanceError={allowanceError}
                masterRowData={masterRowData}
                allowanceDisabled={allowanceDisabled}
                upmEnabled={upmEnabled}
                isUPM={isUPM}
                maskingFunc={maskingFunc}
                accountCodeMask={accountCodeMask}
                dateOptions={dateOptions}
                handleCopyRow={handleCopyRow}
                currentStateId={currentStateId}
                currentCountryId={currentCountryId}
                countries={countries}
                stateOptions={stateOptions}
                countyOptions={countyOptions}
                cityOptions={cityOptions}
                subdivisions={subdivisions}
                locationFuncs={locationFuncs}
                caRegion={caRegion}
                hasFactorNot1={hasFactorNot1}
                project={project}
                pendingCalculation={pendingCalculation}
                onDownloadDocument={onDownloadDocument}
                capItem={capItem}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

RenderBody.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  allowancesOrder: PropTypes.object.isRequired,
  masterRowData: PropTypes.object.isRequired,
  occupationCodes: PropTypes.array.isRequired,
  loadOccupationCodes: PropTypes.func.isRequired,
  workLocations: PropTypes.array.isRequired,
  dealMemos: PropTypes.array.isRequired,
  dealMemoId: PropTypes.string,
  episodes: PropTypes.array.isRequired,
  loadEpisodes: PropTypes.func.isRequired,
  payCodes: PropTypes.array.isRequired,
  allowances: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  blur: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  allowanceDisabled: PropTypes.bool.isRequired,
  upmEnabled: PropTypes.bool.isRequired,
  maskingFunc: PropTypes.func.isRequired,
  accountCodeMask: PropTypes.string.isRequired,
  dateOptions: PropTypes.array.isRequired,
  isUPM: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  stateOptions: PropTypes.array.isRequired,
  countyOptions: PropTypes.array.isRequired,
  cityOptions: PropTypes.array.isRequired,
  subdivisions: PropTypes.array.isRequired,
  locationFuncs: PropTypes.object.isRequired,
  onShowAlert: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  pendingCalculation: PropTypes.bool.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  timecard: PropTypes.object.isRequired,
};

const Allowances = props => {
  const { allowances, timecard } = props;
  const employeeId = timecard?.employee.id;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  React.useEffect(() => {
    if (allowances && allowances.length) setExpanded(true);
  }, [allowances]);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <Accordion className={classes.root} expanded={expanded}>
        <AccordionSummary
          onClick={handleExpand}
          expandIcon={<ExpandMoreIcon />}
          variant="iconOnRight"
        >
          <Typography>
            <span className={classes.allowanceTitle}>Allowances</span>
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.expansionDetails}>
          <FieldArray
            name="allowances"
            component={RenderBody}
            classes={classes}
            {...props}
          />
        </AccordionDetails>
      </Accordion>
      <CapTrackingModal employeeId={employeeId} variant={wtcCapVar} />
      <CapTrackingDeleteModal employeeId={employeeId} />
    </div>
  );
};

Allowances.propTypes = {
  allowances: PropTypes.array.isRequired,
  allowancesOrder: PropTypes.object.isRequired,
  payCodes: PropTypes.array.isRequired,
  dateOptions: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  allowanceDisabled: PropTypes.bool.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onShowAlert: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  timecard: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Allowances);
