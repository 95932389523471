import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';

const SubmitMenuItem = props => {
  const { onClick = () => {}, disabled, clickTimeout = 750, ...rest } = props;

  const [clicked, setClicked] = React.useState(false);

  const setClick = () => {
    setClicked(true);
    onClick();
    setTimeout(() => {
      setClicked(false);
    }, clickTimeout);
  };

  return (
    <MenuItem onClick={setClick} disabled={disabled || clicked} {...rest} />
  );
};

SubmitMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  clickTimeout: PropTypes.number,
  disabled: PropTypes.bool,
};

export default SubmitMenuItem;
