import _ from 'lodash';

export const getCapData = state =>
  _.get(state, 'capTracking.capTrackingData', {});

export const getCapTrackingData = (state, employeeId) => {
  const capTrackingData = getCapData(state);
  return capTrackingData[employeeId] || [];
};
export const getCapLoading = (state, employeeId) => {
  const loading = _.get(state, 'capTracking.loading');
  return loading[employeeId] || false;
};
export const getCapSaving = state => _.get(state, 'capTracking.saving', false);
export const getCapDeleting = state =>
  _.get(state, 'capTracking.deleting', false);
