import _ from 'lodash';
import { matchPath } from 'react-router-dom';

function validateRedirectOrRoute(array) {
  if (process.env.NODE_ENV === 'development') {
    array.forEach((obj, index) => {
      // Check if it's a redirect
      if (obj.hasOwnProperty('redirect')) {
        validateRedirect(obj, index);
      } else {
        // Otherwise, check if it's a valid route
        validateRoute(obj, index);
      }
    });

    const paths = array.map(obj => obj.path);
    const pathCounts = {};

    // Count the occurrences of each path
    paths.forEach(path => {
      pathCounts[path] = (pathCounts[path] || 0) + 1;
    });

    // Find the duplicates
    const duplicatePaths = Object.keys(pathCounts).filter(
      path => pathCounts[path] > 1,
    );

    if (duplicatePaths.length > 0) {
      console.warn(
        'Duplicate paths found in routes. Please ensure all paths are unique.',
      );
      console.warn('Duplicate paths:', duplicatePaths);
    }

    /**
     * Verify that generic paths come after specific paths with similar signature
     */
    for (let i = 0; i < paths.length - 1; i++) {
      for (let j = i + 1; j < paths.length - 1; j++) {
        if (
          !matchPath(paths[i], { path: paths[j], exact: true }) &&
          matchPath(paths[j], { path: paths[i], exact: true })
        ) {
          console.warn(
            'Generic Path should be AFTER the specific path. Please check the order of the paths.',
          );
          console.warn(
            ` Generic Path: ${paths[i]}\n  Specific Path: ${paths[j]}`,
          );
          // throw new Error("Path order is incorrect.");
        }
      }
    }
  }
}

function validateRedirect(obj, index) {
  const { path, redirect, missingRedirectParams = false } = obj;
  // Check if there are exactly 2 keys: 'path' and 'redirect'
  if (!path) {
    console.warn(`invalid redirect: missing required field 'path'.`);
    console.warn(obj);
  }

  if (!redirect) {
    console.warn(`invalid redirect: missing required field 'redirect'.`);
    console.warn(obj);
  }

  if (typeof path !== 'string') {
    console.warn(`invalid redirect: 'path' should be a string.`);
    console.warn(obj);
  }

  if (typeof redirect !== 'string') {
    console.warn(`invalid redirect: 'redirect' should be a string.`);
    console.warn(obj);
  }

  const regex = /:(\w+)/g;

  const pathParams = path.match(regex);
  const redirectParams = redirect.match(regex);

  if (
    _.isEqual(pathParams, redirectParams) === false &&
    !missingRedirectParams
  ) {
    console.warn(
      `invalid redirect: 'path' and 'redirect' should have the same parameters`,
    );
    console.warn(obj);
  }
}

function validateRoute(obj, index) {
  const requiredFields = {
    path: ['string'],
    headers: ['array'],
    layout: ['object', 'function'],
    component: ['object', 'function'],
  };

  const optionalFields = {
    title: 'string',
    waitForUserToLoad: 'boolean',
    noCnCHeader: 'boolean',
  };

  // Check required fields
  for (const [key, type] of Object.entries(requiredFields)) {
    if (!obj.hasOwnProperty(key)) {
      console.warn(`invalid route: missing required field '${key}'.`);
      console.warn(obj);
    }

    if (type.includes('array')) {
      if (!Array.isArray(obj[key])) {
        console.warn(`invalid route: '${key}' should be an array.`);
        console.warn(obj);
      } else {
        const arr = obj[key];
        arr.forEach((item, i) => {
          if (typeof item !== 'object' && typeof item !== 'function') {
            console.warn(
              `invalid route: '${key}' should be an array of objects or function. Found type:${typeof item}`,
            );
            console.warn(obj);
          }
        });
      }
    }

    if (!Array.isArray(obj[key]) && type.includes(typeof obj[key]) === false) {
      console.warn(
        `invalid route: '${key}' should be: ${type}. Found type:${typeof obj[
          key
        ]}`,
      );
      console.warn(obj);
    }
  }

  // Check optional fields
  for (const [key, type] of Object.entries(optionalFields)) {
    if (obj.hasOwnProperty(key) && typeof obj[key] !== type) {
      console.warn(
        `invalid route: optional field '${key}' should be a ${type}. Found type:${typeof obj[
          key
        ]}`,
      );
      console.warn(obj);
    }
  }

  // Ensure no extra fields exist
  const allAllowedKeys = [
    ...Object.keys(requiredFields),
    ...Object.keys(optionalFields),
  ];
  const extraKeys = Object.keys(obj).filter(
    key => !allAllowedKeys.includes(key),
  );

  if (extraKeys.length > 0) {
    console.warn(
      `Object at index ${index} is an invalid route: extra fields found (${extraKeys.join(
        ', ',
        console.warn(obj),
      )}).`,
    );
    console.warn(obj);
  }
}

export default validateRedirectOrRoute;
