import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  canadianLabel,
  compose,
  normalizeStrNumber,
} from 'utils/helperFunctions';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Paper,
  Autocomplete,
  IconButton,
  Box,
  Tooltip,
  InputAdornment,
  LinearProgress,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withModalDialog from 'decorators/withModalDialog';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { hide as closeModal } from 'actions/modalDialog';
import * as actions from 'feature/EmployeeTimecard/actions';
import { show as showModal } from 'actions/modalDialog';

import { getAllowanceTypeOptions } from 'feature/EmployeeTimecard/selectors';
import { getModalParams } from 'selectors/modalDialog';
import {
  TIMECARD_PENDING_EMP_REVIEW,
  TIMECARD_INCOMPLETE,
} from 'components/Shared/constants';

import {
  TitleBox,
  ContentBox,
  FooterBox,
  HeaderText,
  TextField,
  FooterButton,
  AutoCompTextField,
  StyledInputLabel,
  Button,
} from '../Shared/styledComponents';

import * as sel from 'feature/EmployeeTimecard/selectors';

import { NEW_ALLO_ID } from 'feature/EmployeeTimecard/empTimecardUtils';
import DragDropFile from './DragDropFile';
import AlloFlagBadge from 'components/Shared/Text/AlloFlagBadge';

import { CONFIRM_EDIT_MODAL, ALLOWANCE_DIALOG } from '../Modals/modalNames';

const useStyles = makeStyles(({ palette }) => ({
  AllowanceModal: { maxWidth: 360 },
}));

const formSelector = formValueSelector(sel.FORM_NAME);

const mapState = state => ({
  params: getModalParams(state, ALLOWANCE_DIALOG),
  allowances: formSelector(state, 'allowances'),
  allowanceTypeOptions: getAllowanceTypeOptions(state),
  savingAllowance: sel.getLoading(state, 'savingAllowance'),
  isTcEditable: sel.getIsTcEditable(state),
  typeAOptions: sel.getTypeAOptions(state),
  dealMemo: sel.getActiveDealMemo(state),
  status: sel.getTimecardStatus(state),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(closeModal({ dialog: ALLOWANCE_DIALOG })),
  onSaveAllowance: (data, dealMemo) =>
    dispatch(actions.saveAllowance({ data, dealMemo })),
  onShowEditModal: () => dispatch(showModal({ dialog: CONFIRM_EDIT_MODAL })),
});

const AllowanceModal = props => {
  const classes = useStyles();
  const {
    onCloseModal,
    params,
    allowanceTypeOptions,
    onSaveAllowance,
    allowances = [],
    savingAllowance,
    isTcEditable,
    typeAOptions,
    dealMemo,
    status,
    onShowEditModal,
  } = props;

  const { worksightId: incomingId } = params;
  const [worksightId] = React.useState(incomingId);

  const isNew = worksightId === NEW_ALLO_ID;
  const isTcPending = status === TIMECARD_PENDING_EMP_REVIEW;
  const isIncomplete = status === TIMECARD_INCOMPLETE;

  const showSaveBtn = isTcEditable || isTcPending;
  const showEditBtn = isTcPending;

  const [units, setUnits] = React.useState(1);
  const [allowanceTypeFlag, setAllowanceTypeFlag] = React.useState('M');
  const [frequency, setFrequency] = React.useState('F');
  const [sequenceNumber, setSequenceNumber] = React.useState(0);
  const [sequenceGuid, setSequenceGuid] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [isEdited, setIsEdited] = React.useState(false);
  const [combineCheckCode, setCombineCheckCode] = React.useState(false);

  //To display tooltip on mobile
  const [open, setOpen] = React.useState(false);

  const [rate, setRate] = React.useState('');

  const [allowanceType, setAlloType] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [initialFilename, setInitialFilename] = React.useState('');

  const [initialAllowance, setInitialAllowance] = React.useState({});

  const [fileRequired, setFileRequired] = React.useState(false);
  const [showTaxMe, setShowTaxMe] = React.useState(false);
  const initialCombineCheckCode = initialAllowance?.combineCheckCode || false;

  const baseAllowance = allowances?.find(a => a.worksightId === worksightId);
  const isFileSame =
    file?.name === initialFilename ||
    (!file?.name && !initialFilename && !baseAllowance?.filename);
  const hasFileAndIsSame = file && file.name === initialFilename;
  const isTypeSame = allowanceType?.id === initialAllowance?.htgAllowanceTypeId;
  const isRateSame = Number(rate) === Number(initialAllowance?.rate);
  const isUnitsSame = Number(units) === Number(initialAllowance?.units);
  const isCombineCheckCodeSame = combineCheckCode === initialCombineCheckCode;

  const savePendingDisabled =
    isTcPending &&
    ((!file && fileRequired) || (hasFileAndIsSame && isTypeSame));

  const saveIncompleteDisabled =
    isIncomplete && (!isTcEditable || !allowanceType);

  const otherStatus = !isTcPending && !isIncomplete;

  const noChanges =
    isRateSame &&
    isUnitsSame &&
    isFileSame &&
    isTypeSame &&
    isCombineCheckCodeSame;

  const saveDisabled =
    noChanges ||
    savingAllowance ||
    savePendingDisabled ||
    saveIncompleteDisabled ||
    otherStatus;

  React.useEffect(() => {
    //if already isEdited true, don't change it
    if (!initialAllowance?.isEdited) {
      if (!isEdited && (!isRateSame || !isUnitsSame)) {
        setIsEdited(true);
      } else if (isEdited && isRateSame && isUnitsSame) {
        setIsEdited(false);
      }
    }
  }, [isEdited, isRateSame, isUnitsSame, initialAllowance]);

  React.useEffect(() => {
    if (baseAllowance) {
      setAlloType(baseAllowance.allowanceType);
      const newRate = normalizeStrNumber(baseAllowance.rate, {
        decimalPrecision: 4,
      });
      setRate(newRate);
      setUnits(baseAllowance.units || 1);
      setAllowanceTypeFlag(
        !baseAllowance.allowanceTypeFlag
          ? 'M'
          : baseAllowance.allowanceTypeFlag,
      );
      setAmount(baseAllowance.amount || 0);
      setFrequency(baseAllowance.frequency);
      setSequenceNumber(baseAllowance?.sequenceNumber || 0);
      setSequenceGuid(baseAllowance?.id || null);

      setIsEdited(baseAllowance.isEdited || false);
      setCombineCheckCode(baseAllowance.combineCheckCode || false);

      if (baseAllowance.filename) {
        const file = { name: baseAllowance.filename };
        setFile(file);
        setInitialFilename(file.name);
      }

      setInitialAllowance(_.cloneDeep(baseAllowance));
    }
  }, [worksightId, baseAllowance]);

  React.useEffect(() => {
    const type = allowanceTypeOptions.find(t => t.id === allowanceType?.id);

    const hasFile = !!file;

    if (type?.employeeMandatory && !hasFile) {
      setFileRequired(true);
    } else {
      setFileRequired(false);
    }
  }, [allowanceType, allowanceTypeOptions, file]);

  //clear out file data if file is null so it can be saved
  //values will get filled in from useState file on save
  React.useEffect(() => {
    if (file === null) {
      setInitialAllowance(oldVal => {
        return {
          ...oldVal,
          filename: null,
          s3Path: null,
          token: null,
          document: null,
        };
      });
    }
  }, [file]);

  React.useEffect(() => {
    setAmount((Number(rate) || 0) * Number(units));
  }, [rate, units]);
  const rateInfoRef = React.useRef(null);

  const isFlagA = allowanceTypeFlag === 'A';
  const flagType = isFlagA ? (isEdited ? 'modified' : 'deal') : 'manual';

  let options = allowanceTypeOptions;
  let setTypeAOptions = false;
  const sequence =
    initialAllowance?.sequenceNumber > 0
      ? initialAllowance.sequenceNumber
      : initialAllowance.sequenceGuid;
  if (typeAOptions[sequence] && isFlagA) {
    options = typeAOptions[sequence];
    setTypeAOptions = true;
  }

  let typeSelectDisabled;
  if (setTypeAOptions && isTcPending) {
    typeSelectDisabled = false;
  } else {
    typeSelectDisabled = !isTcEditable || (isFlagA && !setTypeAOptions);
  }

  let selectTooltip = '';
  if (isFlagA && typeSelectDisabled) {
    selectTooltip =
      'This allowance is being applied from your deal memo. Add a new allowance to select a different type';
  }

  React.useEffect(() => {
    if (isFlagA && (sequenceNumber > 0 || sequenceGuid)) {
      const dealAllowances = dealMemo?.dealMemoAllowances || [];
      const dealAllowance = dealAllowances.find(da =>
        sequenceNumber > 0
          ? da.sequenceNumber === sequenceNumber
          : da.id === sequenceGuid,
      );

      if (dealAllowance?.payCode1 && dealAllowance?.payCode2) {
        const payCode1 = dealAllowance.payCode1;
        const payCode2 = dealAllowance.payCode2;

        const payCode1Full = options.find(a => a.id === payCode1.id);

        const payCode2Full = options.find(a => a.id === payCode2.id);

        if (
          allowanceType?.id === payCode2.id &&
          payCode1Full?.employeeMandatory === false &&
          payCode2Full?.employeeMandatory === true &&
          !file &&
          (isTcEditable || isTcPending)
        ) {
          setShowTaxMe(true);
          return;
        }
      }
    }
    if (showTaxMe) {
      setShowTaxMe(false);
    }
  }, [
    allowanceType?.id,
    dealMemo?.dealMemoAllowances,
    file,
    isFlagA,
    isTcEditable,
    isTcPending,
    options,
    sequenceNumber,
    sequenceGuid,
    showTaxMe,
  ]);

  const handleTaxMeBtn = React.useCallback(() => {
    const dealAllowance = dealMemo?.dealMemoAllowances.find(da =>
      sequenceNumber > 0
        ? da.sequenceNumber === sequenceNumber
        : da.id === sequenceGuid,
    );

    const payCode1 = dealAllowance?.payCode1 || {};
    const payCode1Full = options.find(a => a.id === payCode1.id);

    if (payCode1Full) {
      setAlloType(payCode1Full);
    }
  }, [options, dealMemo?.dealMemoAllowances, sequenceNumber, sequenceGuid]);

  const checkLabel = canadianLabel('check');
  const liveCheckLabel = canadianLabel('Live Check');
  let unitsClassName = '';
  if (isFlagA) {
    unitsClassName =
      frequency === 'D'
        ? 'PENDO_auto_allowance_daily_units'
        : 'PENDO_auto_allowance_weekly_units';
  } else {
    unitsClassName = 'PENDO_manual_allowance_units';
  }
  return (
    <Paper className={classes.AllowanceModal}>
      {savingAllowance && <LinearProgress />}
      <TitleBox>
        <HeaderText>{isNew && 'Add '}Allowance</HeaderText>
        <IconButton disabled={savingAllowance} onClick={onCloseModal}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox sx={{ gap: 3, pt: 3 }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <StyledInputLabel>Type</StyledInputLabel>
            <Box sx={{ position: 'relative', top: '-12px' }}>
              <AlloFlagBadge variant={'empTimecard'} flagType={flagType} />
            </Box>
          </Box>{' '}
          <Tooltip title={selectTooltip}>
            <span>
              <Autocomplete
                disabled={typeSelectDisabled}
                options={options}
                value={allowanceType}
                renderInput={params => (
                  <AutoCompTextField
                    {...params}
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px 14px !important',
                        color: 'text.primary',
                      },
                    }}
                  />
                )}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v?.id}
                onChange={(e, value) => {
                  setAlloType(value);
                  rateInfoRef?.current?.focus();
                }}
              />
            </span>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flex="3">
            <StyledInputLabel>Rate</StyledInputLabel>
            <TextField
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                },
              }}
              className={'PENDO_allowance_rate'}
              inputRef={rateInfoRef}
              disabled={!isTcEditable}
              value={rate}
              onFocus={e => {
                setTimeout(() => {
                  e.target.select();
                }, 50);
              }}
              onChange={e => {
                let value = e.target.value;
                const regex = /^\d{0,5}(\.\d{0,4})?$/;
                if (!regex.test(value)) {
                  return;
                }
                const val = normalizeStrNumber(value, {
                  decimalPrecision: 4,
                });
                setRate(val);
              }}
            />
          </Box>
          <Box
            flex="1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'flex-end',
              marginBottom: '10px',
              fontSize: '24px',
            }}
          >
            &times;
          </Box>
          <Box flex="3">
            <StyledInputLabel>Units</StyledInputLabel>
            <TextField
              sx={{
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                },
              }}
              value={units}
              disabled={!isTcEditable}
              onFocus={e => {
                setTimeout(() => {
                  e.target.select();
                }, 50);
              }}
              className={unitsClassName}
              onChange={e => {
                let value = e.target.value;
                const regex = /^\d{0,3}(\.\d{0,2})?$/;
                if (!regex.test(value)) {
                  return value;
                }
                const val = normalizeStrNumber(value, {
                  decimalPrecision: 2,
                });
                setUnits(val);
              }}
              onBlur={() => {
                if (!units || units === 0) {
                  setUnits(1);
                }
              }}
              InputProps={{
                endAdornment: flagType !== 'manual' && (
                  <InputAdornment position="end">
                    {frequency === 'D' ? 'daily' : 'week'}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
        </Box>
        <Box>
          <StyledInputLabel>Total</StyledInputLabel>
          <TextField
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                padding: '10px 14px',
              },
            }}
            disabled={true}
            value={amount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Switch
            checked={combineCheckCode}
            disabled={!isTcEditable}
            onChange={e => setCombineCheckCode(e.target.checked)}
          />
          <Box sx={{ fontWeight: 500, mr: '5px' }}>{liveCheckLabel}</Box>
          <Tooltip
            open={open}
            title={`When enabled, this allowance will be paid on a separate paper ${checkLabel}.`}
            placement="top"
          >
            <span
              style={{ marginTop: '5px' }}
              //for mobile
              onTouchStart={() => setOpen(!open)}
              //for desktop
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <InfoOutlinedIcon fontSize="small" />
            </span>
          </Tooltip>
        </Box>
        <DragDropFile
          file={file}
          setFile={setFile}
          setInitialFilename={setInitialFilename}
          fileRequired={fileRequired}
          isTcEditable={isTcEditable}
          isTcPending={isTcPending}
          disabled={savingAllowance || (!isTcEditable && !isTcPending)}
        />
        {showTaxMe && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className="PENDO-TC-TAX-ME-BTN"
              variant="outlined"
              sx={{ maxWidth: '210px' }}
              startIcon={<ErrorOutlineIcon />}
              onClick={handleTaxMeBtn}
            >
              Skip Document - Tax Me
            </Button>
          </Box>
        )}
      </ContentBox>
      <FooterBox>
        <FooterButton
          variant="outlined"
          onClick={onCloseModal}
          disabled={savingAllowance}
        >
          {isTcEditable ? 'Cancel' : 'Close'}
        </FooterButton>
        {showSaveBtn && (
          <FooterButton
            onClick={() => {
              onSaveAllowance(
                {
                  ...initialAllowance,
                  worksightId,
                  allowanceType,
                  amount,
                  rate: Number(rate),
                  units: Number(units),
                  allowanceTypeFlag,
                  frequency,
                  file,
                  isEdited,
                  combineCheckCode,
                },
                dealMemo,
              );
            }}
            disabled={saveDisabled}
          >
            Save
          </FooterButton>
        )}
        {showEditBtn && (
          <FooterButton disabled={savingAllowance} onClick={onShowEditModal}>
            Edit Amount
          </FooterButton>
        )}
      </FooterBox>
    </Paper>
  );
};

AllowanceModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onSaveAllowance: PropTypes.func.isRequired,
  allowances: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  allowanceTypeOptions: PropTypes.array.isRequired,
  savingAllowance: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  typeAOptions: PropTypes.object.isRequired,
  dealMemo: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
};

export default compose(
  withModalDialog({
    dialog: ALLOWANCE_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(AllowanceModal);
