import {
  Button as MuiButton,
  TextField as MuiTextField,
  styled,
  Chip,
  Box,
  Paper,
  InputLabel,
} from '@mui/material';

import SubmitButtonComp from 'components/Shared/SubmitButton';

export const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: '8px',
  fontSize: '14px',
}));

export const SubmitButton = styled(SubmitButtonComp)(({ theme }) => ({
  borderRadius: '8px',
  fontSize: '14px',
}));

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    top: '-11px',
  },
  '.MuiInputBase-input': { padding: '4px' },
}));

export const TextField = props => {
  return <StyledTextField variant="outlined" {...props} />;
};

export const AutoCompTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    fontSize: 14,
  },
  //Used to  remove the space reserved for the autocomplete clearX
  '& .MuiInput-root': {
    paddingRight: '10px !important',
  },
  //Used to hide the autocomplete clearX
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input': {
    padding: '4px !important',
    color: theme.palette.text.secondary,
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: '0px 30px 0px 0px !important',
  },
}));

export const CountChip = styled(Chip)(({ theme }) => ({
  height: '24px',
  marginLeft: '5px',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.gray['+8'],
  '& .MuiChip-label': {
    paddingLeft: '8px',
    paddingRight: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3px',
      paddingRight: '3px',
      fontSize: '12px',
      width: '15px',
    },
  },
}));

export const TabPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
}));

export const TabHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '12px',
}));

// Modal Replacement
export const TitleBox = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '18px',
  padding: '12px 24px 8px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `solid 1px ${theme.palette.gray['+7']}`,
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px',
  gap: '12px',
}));

export const HeaderText = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
}));

export const FooterBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '12px 24px',
  };
});

export const FooterButton = styled(SubmitButton)(({ theme }) => ({
  flex: 1,
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.gray['-9'],
  fontSize: '14px',
  fontWeight: '500',
  marginBottom: '7px',
}));

export const StyledFieldBox = styled(Box)(({ theme }) => ({
  marginBottom: '10px',
  width: '100%',
}));
