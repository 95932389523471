import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import {
  Typography,
  AppBar,
  LinearProgress,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
//selectors
import { isProcessingApproval } from 'selectors/reviews';
import {
  getOtherTimecards,
  getLoadingOtherTimecards,
  currentUserLevel,
  isCurrentUserUPM,
  isCurrentUserPA,
} from 'selectors/wtc';
import { getProject } from 'selectors/routeParams';
//actions
import * as actions from 'actions/wtc';
import { showAlert } from 'actions/alert';

import FadeInScroll from 'components/Shared/FadeInScroll';
import WTCHeaderButtons from './WTCHeaderButtons';
import InstantDealIcon from 'components/Shared/InstantDealIcon';

import AdditionalFields from 'components/Employees/Reviews/WTC/AdditionalFields/AdditionalFieldsContainer';
import { weekEndingFormat } from 'utils/formatDate';

import { isInstantDealTimecard } from 'utils/wtcWeekUtils';

import PrintMenu from './WTCPrintMenu';
import OtherTimecards from './OtherTimecards';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    height: 125,
    color: palette.gray.main,
    backgroundColor: palette.gray.background,
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
    justifyItems: 'end',
    display: 'flex',
  },
  instantDealIcon: {
    fontSize: 12,
    fontWeight: 'bold',
    color: palette.warning.dark,
  },
  briefInfo: {
    fontSize: 12,
    fontWeight: 500,
  },
  rightBar: {
    display: 'flex',
    marginBottom: 40,
  },
  additionalField: {
    width: 184,
  },
  multiBatchTitle: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const mapState = state => ({
  isBulkApprovalProcessing: isProcessingApproval(state),
  otherTimecards: getOtherTimecards(state),
  loadingOtherTimecards: getLoadingOtherTimecards(state),
  userLevel: currentUserLevel(state),
  isUPM: isCurrentUserUPM(state),
  isPA: isCurrentUserPA(state),
  projectId: getProject(state),
});

const mapDispatch = dispatch => ({
  onFetchOtherTimecards: () => dispatch(actions.fetchOtherTimecards()),
  onShowAlert: params => dispatch(showAlert(params)),
});

const StickyHeader = props => {
  const {
    timecard,
    canRenderTimecard,
    processing,
    wtcUpdating,
    isMultiBatch,
    onDirtyFlagCheck,
    onForceCommentAction,
    onSaveTimecard,
    onApprovalWithoutChange,
    onSetAutoCoding,
    onFetchOtherTimecards,
    loadingOtherTimecards,
    otherTimecards,
    isUPM,
    isPA,
    userLevel,
    projectId,
    onShowAlert,
  } = props;
  const classes = useStyles();
  let name, weekEnding, batchCode, batchName;

  const isInstantDealTC = isInstantDealTimecard(timecard);

  const isMediumScreen = useMediaQuery('(max-width:1650px)') ? true : false;

  if (canRenderTimecard) {
    name = timecard.employee.name.includes(':')
      ? timecard.employee.name.split(':')[0]
      : timecard.employee.name;
    weekEnding = weekEndingFormat(timecard.weekEndingDate);
    batchCode = timecard.worksightHtgId;
    batchName = timecard.batch.name;
  } else {
    return null;
  }

  const timecardElement = document.getElementById('wtcTimecard');

  return (
    // customThreshold should a estimate number of pixels
    // before the static element is blocked and not visible
    <FadeInScroll customThreshold={100} target={timecardElement ?? undefined}>
      <AppBar>
        <div className={classes.root}>
          <div>
            <Typography className={classes.name}>{name}</Typography>
            <div className={classes.instantDealIcon}>
              {isInstantDealTC && (
                <Box>
                  <InstantDealIcon
                    color="warning.dark"
                    label={'Offer Pending Approval'}
                  />
                </Box>
              )}
            </div>
          </div>
          <div className={classes.infoGroup}>
            <OtherTimecards
              onFetchOtherTimecards={onFetchOtherTimecards}
              otherTimecards={otherTimecards}
              loading={loadingOtherTimecards}
              userLevel={userLevel}
              isUPM={isUPM}
              isPA={isPA}
              projectId={projectId}
              onDirtyFlagCheck={onDirtyFlagCheck}
              onShowAlert={onShowAlert}
              weekEnding={weekEnding}
            />
            <Typography
              className={clsx(classes.briefInfo, {
                [classes.multiBatchTitle]: isMultiBatch,
              })}
              onClick={() =>
                isMultiBatch
                  ? onDirtyFlagCheck({
                      actionType: 'navToBatchWTC',
                      timecardEntryHeaderId: timecard.timecardEntryHeaderId,
                      batchWorksightId: timecard.batch.id,
                    })
                  : {}
              }
            >
              Batch: {`${batchCode} - ${batchName}`}
            </Typography>
          </div>
          <div>
            <WTCHeaderButtons
              onForceCommentAction={onForceCommentAction}
              onSaveTimecard={onSaveTimecard}
              onApprovalWithoutChange={onApprovalWithoutChange}
              onSetAutoCoding={onSetAutoCoding}
              isMediumScreen={isMediumScreen}
              isInstantDealTC={isInstantDealTC}
            />
          </div>
          <div className={classes.rightBar}>
            <PrintMenu isMediumScreen={isMediumScreen} />
            <div className={classes.additionalField}>
              {canRenderTimecard && <AdditionalFields />}
            </div>
          </div>
        </div>
        {(processing || wtcUpdating) && <LinearProgress />}
      </AppBar>
    </FadeInScroll>
  );
};

StickyHeader.propTypes = {
  timecard: PropTypes.object.isRequired,
  canRenderTimecard: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  wtcUpdating: PropTypes.bool.isRequired,
  isMultiBatch: PropTypes.bool.isRequired,
  onDirtyFlagCheck: PropTypes.func.isRequired,
  onForceCommentAction: PropTypes.func.isRequired,
  onSaveTimecard: PropTypes.func.isRequired,
  onApprovalWithoutChange: PropTypes.func.isRequired,
  onSetAutoCoding: PropTypes.func.isRequired,
  onFetchOtherTimecards: PropTypes.func.isRequired,
  loadingOtherTimecards: PropTypes.bool.isRequired,
  otherTimecards: PropTypes.array.isRequired,
  isUPM: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
  userLevel: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  onShowAlert: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(StickyHeader);
