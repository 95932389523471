import './axiosdebug';

//root /me call located in: src/providers/Auth.js

const URL_ME = '/me';
const REGISTER_AUTH_TICKET = '/me/register';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async revokeToken() {
    const rsp = await clientV2.delete(URL_ME);
    return rsp.data;
  },

  async getAuthTicket() {
    const rsp = await clientV2.post(REGISTER_AUTH_TICKET);
    return rsp.data;
  },
  async report(data) {
    // await clientV2.post(REPORT_URL, data); //TODO update when we have a real endpoint HOUR-12960
    await sleep(250);
    return;
  },
});
