import React from 'react';

import { Header, ProjectHeader, FullWidthLayout } from 'containers/Layout';

import projectCrewTimecardRoutes from './projectCrewTimecardRoutes';
import projectLegacyTimecardRoutes from './projectLegacyTimecardRoutes';

import { MY_TIMECARDS_PATH, TIMECARD_PATH } from 'components/Routes/paths';

//components
import TimecardHistoryView from 'containers/Employees/Timecards/TimecardHistoryView';
import Timecard from 'feature/EmployeeTimecard/Timecard';

const NavTo = React.lazy(() => import('containers/Employees/Timecards/NavTo'));

const projectTimecardRoutes = [
  {
    title: 'My Timecards',
    path: MY_TIMECARDS_PATH,
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: TimecardHistoryView,
    waitForUserToLoad: true,
  },
  {
    title: 'Timecard',
    path: TIMECARD_PATH,
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: Timecard,
    waitForUserToLoad: true,
  },
  {
    path: '/projects/:projectId/navto/timecard',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: NavTo,
  },
  {
    path: '/projects/:projectId/me/timecards/:timecardId/review',
    redirect: TIMECARD_PATH,
  },
  {
    path: '/projects/:projectId/me/timecards/:timecardId/approval-flows/:approvalFlowId/review',
    redirect: TIMECARD_PATH,
    missingRedirectParams: true, //suppresses the warning about mismatched parameters
  },

  ...projectCrewTimecardRoutes,
  ...projectLegacyTimecardRoutes,
];

export default projectTimecardRoutes;
