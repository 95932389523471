import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CapTrackingItem from './CapTrackingItem';

//actions
import { saveCap } from './actions';
import { show as showModal } from 'actions/modalDialog';

//selectors
import { getCapSaving } from './selectors';
import { currentUser } from 'selectors/session';

import { PA } from 'components/props/profiles';

import { deleteCapModal, wtcCapVar } from './capUtils';
import { isWTCDisabled } from 'selectors/wtc';

const useStyles = makeStyles(({ palette }) => ({
  CapTracking: {
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
  },
}));

const mapState = state => ({
  activeUser: currentUser(state),
  saving: getCapSaving(state),
  disabled: isWTCDisabled(state),
});

const mapDispatch = dispatch => ({
  onShowDeleteModal: capItem =>
    dispatch(showModal({ dialog: deleteCapModal, modalParams: capItem })),
  onSave: obj => dispatch(saveCap(obj)),
});

const CapTracking = props => {
  const {
    capTrackingData = [],
    activeUser,
    onSave,
    onShowDeleteModal,
    variant,
    saving,
    disabled,
  } = props;

  const classes = useStyles();

  const isWTC = variant === wtcCapVar;
  const canEdit = isWTC && activeUser.role === PA && !disabled;

  const actionDisabled = saving;

  return (
    <Box className={classes.CapTracking}>
      {capTrackingData?.map(capItem => {
        return (
          <CapTrackingItem
            key={capItem.category}
            capItem={capItem}
            onSave={onSave}
            onShowDeleteModal={onShowDeleteModal}
            canEdit={canEdit}
            actionDisabled={actionDisabled}
          />
        );
      })}
    </Box>
  );
};

CapTracking.propTypes = {
  capTrackingData: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  activeUser: PropTypes.object.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(CapTracking);
