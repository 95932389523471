import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import _ from 'lodash';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useTable, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';

import Header from './Header';
import EmptyState from 'containers/Employees/Reviews/Shared/EmptyState';
import LoadingSquare from 'containers/Employees/Reviews/Shared/LoadingSquare';
import HeaderRow from './HeaderRow';
import Row from './Row';
import SavingBackdrop from './SavingBackdrop';

//selectors
import * as sel from 'selectors/dts';
import { getIsProjectCompleted } from 'selectors/project';

//actions
import * as actions from 'actions/dts';
import { showAlert } from 'actions/alert';

//utils
import {
  parseTableData,
  update,
  getCascadingChanges,
  checkRoundingOnNewDeal,
  scanStates,
  DEFAULT_SORT,
  FIXED_COLUMNS,
  MAX_EMPLOYEE_COUNT,
} from './dtsUtils';
import { handleSort } from 'utils/reactTableUtils';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '83vh',
  },
  emptyState: {
    margin: 'auto',
  },
  showTableContainer: {
    marginTop: 20,
    fontSize: 12,
  },
  loadingCover: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
  },
  tableContainer: {
    height: 'calc((100vh - 250px) * .95)',
    overflow: 'scroll',
    transition: 'width 0.3s;',
    position: 'relative',
  },
  withFilters: {
    maxWidth: 'calc(99vw - 360px)',
  },
  // withoutFilters: {
  //   maxWidth: 'calc(99vw - 133px)',
  // },
  windowWithFilters: {
    width: 'calc(99vw - 360px)',
  },
  // windowWithoutFilters: {
  //   width: 'calc(99vw - 133px)',
  // },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  tableBody: {
    zIndex: 0,
    position: 'relative',
  },
}));

const mapState = (state, { match }) => ({
  rawData: sel.getRawData(state),
  tableColumns: sel.getTableColumns(state),
  initialHiddenColumns: sel.getHiddenColumns(state),
  effectiveDate: sel.getEffectiveDate(state),
  storedSort: sel.getStoredSort(state),
  loading: sel.getLoading(state),
  hasTemplateError: sel.getTemplateError(state),
  allDayTypes: sel.getAllDayTypes(state),
  saveCounts: sel.getSavedCounts(state),
  employeeCount: sel.getEmployeeCount(state),
  isProjectCompleted: getIsProjectCompleted(state),
  isSaving: sel.getIsSaving(state),
});

const mapDispatch = dispatch => ({
  onSetEffectiveDate: ({ date }) => {
    if (date) {
      dispatch(actions.setEffectiveDate({ date }));
      if (date._isValid) {
        dispatch(actions.fetchFilterOptions());
        dispatch(actions.fetchData());
      }
    }
  },
  onStoreSort: storedSort => dispatch(actions.storeSort({ storedSort })),
  onShowAlert: params => dispatch(showAlert(params)),
});

//Reminder Add props to watch array in Memo to re-render on change
const DTSTable = props => {
  const classes = useStyles();
  const {
    //---DTS Data---
    hasTemplateError,
    hasError,
    employeeCount,
    loading,
    dtsDirty,
    liveDataRef,
    //---DTS functions---
    save,
    listTimecards,
    setHasError,
    //---Modal---
    onConfirmModal,
    confirmModalIfDirty,
    //---redux-props---
    rawData,
    effectiveDate,
    tableColumns,
    initialHiddenColumns,
    isSaving,
    allDayTypes,
    // saveCompletedCount,
    // totalSaveCount,
    saveCounts,
    storedSort,
    //---redux-dispatch---
    onStoreSort,
    onSetDirty,
    onSetEffectiveDate,
    onShowAlert,
    isProjectCompleted,
  } = props;
  // const showFilters = true; //uncomment when we show/hide filters

  //hooks + data setup

  const [tableData, setTableData] = useState([]);

  const [currDate, setCurrDate] = useState(effectiveDate);
  const overflowContainerRef = useRef(null);

  useEffect(() => {
    const errorCheck = tableData.reduce((acc, val) => {
      if (!_.isEmpty(val.errors)) return true;
      return acc || false;
    }, false);

    setHasError(hasError => {
      if (hasError !== errorCheck) return errorCheck;
      else return hasError;
    });
  }, [hasError, setHasError, tableData]);

  useEffect(() => {
    if (effectiveDate !== currDate) {
      liveDataRef.current = {};
      setTableData([]);
      setCurrDate(effectiveDate);
    }
  }, [currDate, effectiveDate, liveDataRef]);

  const editableCounts = useMemo(
    () => tableData.filter(tc => tc.editable === true).length,
    [tableData],
  );

  const isEmpty = tableData.length === 0;

  const actualColumns = useMemo(() => {
    return FIXED_COLUMNS.concat(tableColumns);
  }, [tableColumns]);

  // sanity checking that whats on the liveData matches the tableData
  // Will only discover bugs in the update function, anything missed by the parseFunction will be  missed here too
  // useEffect(() => {
  //   const parsedLiveData = parseTableData({
  //     data: liveData,
  //     effectiveDate,
  //     tableColumns,
  //   });

  //   // This can be called between updates of live and table dataParams
  //   // skip check if either table is empty since it wil be checked on next update
  //   if (parsedLiveData.length === 0 || tableData.length === 0 || loading) {
  //     return;
  //   }

  //   const sortedCheck = _.sortBy(tableData, ['tcIndex']);
  //   const sortedLive = _.sortBy(parsedLiveData, ['tcIndex']);

  //   for (let index = 0; index < sortedCheck.length; index++) {
  //     try {
  //       const liveRow = sortedLive[index];
  //       const checkRow = sortedCheck[index];

  //       const KEYS_TO_SKIP = [
  //         'dealMemo',
  //         'errors',
  //         'warnings',
  //         'touched',
  //         'unrounded',
  //         'hoursWorked',
  //       ];

  //       for (const key in checkRow) {
  //         if (KEYS_TO_SKIP.includes(key)) continue;
  //         if (Object.hasOwnProperty.call(checkRow, key)) {
  //           const liveElm = JSON.stringify(liveRow[key]);
  //           const checkElm = JSON.stringify(checkRow[key]);

  //           if (liveElm !== checkElm) {
  //             console.error(`misMatch tc#:${index} - ${key}`);
  //             console.log('live: \t', sortedLive[index]);
  //             console.log('table:\t', sortedCheck[index]);
  //           }
  //         }
  //       }

  //       for (const key in liveRow) {
  //         if (KEYS_TO_SKIP.includes(key)) continue;
  //         if (Object.hasOwnProperty.call(liveRow, key)) {
  //           const liveElm = JSON.stringify(liveRow[key]);
  //           const checkElm = JSON.stringify(checkRow[key]);

  //           if (liveElm !== checkElm) {
  //             console.error(`misMatch tc#:${index} - ${key}`);
  //             console.log('live: \t', sortedLive[index]);
  //             console.log('table:\t', sortedCheck[index]);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error(`Error tc#:${index} }`);
  //       console.log('live row : ', sortedLive);
  //       console.log('table row: ', sortedCheck);
  //     }
  //   }
  // }, [tableData, liveData, tableColumns, effectiveDate, loading]);

  // central update function called on blur and CTA
  // tableData goes to display on screen
  // liveData is the payload that will be return to the BE when form is submitted.
  const updateDTSData = useCallback(
    updateArgs => {
      const {
        original,
        rowIndex,
        columnId,
        newVal,
        cta = false,
        unrounded,
      } = updateArgs;

      const newValues = getCascadingChanges({
        columnId,
        original,
        newVal,
        unrounded: cta ? unrounded : undefined,
      });

      if (newValues.workState) {
        scanStatesRef.current = true;
      }

      const showDealRoundingWarning = checkRoundingOnNewDeal(
        newValues,
        original,
      );

      setTableData(tableData => {
        const [newLiveData, newTableData, updateMetaData] = update({
          liveData: liveDataRef.current,
          tableData,
          rowIndex,
          newValues,
          effectiveDate,
          columns: actualColumns,
          cta,
          allDayTypes,
        });
        const { makeDirty, showCTARoundingWarning } = updateMetaData;
        if (makeDirty) {
          onSetDirty(true);
        }

        if (showCTARoundingWarning) {
          onShowAlert({
            message:
              'Rounding rules are different for some employees. Values have been rounded accordingly.',
            variant: 'info',
          });
        }
        if (showDealRoundingWarning) {
          onShowAlert({
            message:
              'The selected deal memo has different rounding rules than the previous deal memo. Values have been rounded accordingly.',
            variant: 'info',
          });
        }
        liveDataRef.current = newLiveData;
        return newTableData;
      });
    },

    [
      liveDataRef,
      effectiveDate,
      actualColumns,
      allDayTypes,
      onSetDirty,
      onShowAlert,
    ],
  );

  const reactTable = useTable(
    {
      columns: actualColumns,
      data: tableData,
      initialState: {
        sortBy: DEFAULT_SORT,
        hiddenColumns: initialHiddenColumns,
      },
      autoResetSortBy: false,
      manualSortBy: true,
      onConfirmModal,
      updateDTSData,
      editableCounts,
      containerRef: overflowContainerRef,
    },
    useSortBy,
    useSticky,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { hiddenColumns },
    setHiddenColumns,
    setSortBy: setSortReactTable,
    state: { sortBy },
  } = reactTable;

  useEffect(() => {
    //if there's a sort order stored, set it 1x
    if (storedSort.length > DEFAULT_SORT.length) {
      setSortReactTable(storedSort);
    }
  }, [setSortReactTable, storedSort]);

  const resetTable = useCallback(() => {
    const newLiveData = _.cloneDeep(rawData);
    liveDataRef.current = newLiveData;
    const parsedData = parseTableData({
      data: newLiveData,
      effectiveDate,
      tableColumns,
    });

    scanStatesRef.current = true;

    const sortedData = handleSort({
      data: parsedData,
      sortBy,
      tableColumns: actualColumns,
    });

    setTableData(sortedData);
    onSetDirty(false);
  }, [
    actualColumns,
    effectiveDate,
    liveDataRef,
    onSetDirty,
    rawData,
    sortBy,
    tableColumns,
  ]);

  const scanStatesRef = useRef(false);

  useEffect(() => {
    if (scanStatesRef.current) {
      scanStatesRef.current = false;
      scanStates(liveDataRef?.current?.timecards, setHiddenColumns);
    }
  });

  //reset table and live data when rawData changes
  const rawChangeRef = useRef(false);
  useEffect(() => {
    rawChangeRef.current = true;
  }, [rawData]);
  useEffect(() => {
    if (tableColumns.length > 0 && rawChangeRef.current === true) {
      resetTable();
      rawChangeRef.current = false;
    }
  }, [rawData, resetTable, tableColumns]);

  //wrapper for reactTable setSortBy to store sort order in redux
  const setSortBy = newSort => {
    setSortReactTable(newSort);
    onStoreSort(newSort);
  };

  const currSortRef = useRef(sortBy);

  useEffect(() => {
    //manualSortBy performed here
    if (
      tableData.length > 0 &&
      actualColumns &&
      !_.isEqual(sortBy, currSortRef.current)
    ) {
      setTableData(data =>
        handleSort({
          data,
          sortBy,
          tableColumns: actualColumns,
        }),
      );
      currSortRef.current = sortBy.slice();
    }
  }, [actualColumns, sortBy, tableData.length]);

  const [coverHeight, setCoverHeight] = useState(window.innerHeight - 250);
  const [coverWidth, setCoverWidth] = useState(window.innerWidth - 375);

  const resizeCover = useCallback(() => {
    if (overflowContainerRef.current) {
      const { height, width } =
        overflowContainerRef.current.getBoundingClientRect();
      setCoverHeight(height);
      setCoverWidth(width);
    }
  }, []);

  useEffect(() => {
    resizeCover();
  }, [rawData, resizeCover]);

  useEffect(() => {
    window.addEventListener('resize', resizeCover);
    return () => {
      window.removeEventListener('resize', resizeCover);
    };
  }, [resizeCover]);

  const tableBody = () => {
    if (!loading && (hasTemplateError || isEmpty)) {
      const variant = hasTemplateError
        ? 'dtsTemplateError'
        : employeeCount > MAX_EMPLOYEE_COUNT
        ? 'maxEmployees'
        : 'noResults';

      return <EmptyState className={classes.emptyState} variant={variant} />;
    }

    return (
      <>
        {loading && (
          <Box
            className={classes.loadingCover}
            sx={{ height: coverHeight, width: coverWidth }}
          >
            <LoadingSquare isSaving={isSaving} />
          </Box>
        )}
        <main
          className={clsx(classes.tableContainer, {
            [classes.withFilters]: true, //showFilters,    //uncomment when we show/hide filters
            // [classes.withoutFilters]: !showFilters,    //uncomment when we show/hide filters
          })}
          ref={overflowContainerRef}
        >
          <table {...getTableProps()} className={classes.table}>
            <HeaderRow headerGroups={headerGroups} setSortBy={setSortBy} />
            <tbody {...getTableBodyProps()} className={classes.tableBody}>
              {rows.map((row, rowIndex) => {
                return (
                  <Row
                    prepareRow={prepareRow}
                    row={row}
                    rowIndex={rowIndex}
                    key={`${row.original.employeeId}-${row.original.timecardEntryHeaderId}`}
                    hiddenColCount={hiddenColumns && hiddenColumns.length}
                    showCapTrack={row.original.showCapTrack} //needed for memo update
                  />
                );
              })}
              {/* Extra buffer row for CTA icon */}
              {rows.length > 0 && <tr style={{ height: '30px' }} />}
            </tbody>
          </table>
          <SavingBackdrop
            saveCompletedCount={saveCounts.completed}
            totalSaveCount={saveCounts.total}
          />
        </main>
      </>
    );
  };

  return (
    <div className={clsx(classes.root, { [classes.emptyState]: isEmpty })}>
      <div
        className={clsx(classes.showTableContainer, {
          [classes.windowWithFilters]: true, // showFilters,    //uncomment when we show/hide filters
          // [classes.windowWithoutFilters]: !showFilters,    //uncomment when we show/hide filters
        })}
      >
        <Header
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          tableColumns={tableColumns}
          effectiveDate={effectiveDate}
          onSetEffectiveDate={onSetEffectiveDate}
          confirmModalIfDirty={confirmModalIfDirty}
          dtsDirty={dtsDirty}
          hasError={hasError}
          save={save}
          listTimecards={listTimecards}
          clearChanges={resetTable}
          loading={loading}
          isProjectCompleted={isProjectCompleted}
          isSaving={isSaving}
        />
        {tableBody()}
      </div>
    </div>
  );
};

DTSTable.propTypes = {
  rawData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  effectiveDate: PropTypes.object.isRequired,
  tableColumns: PropTypes.array.isRequired,
  dtsDirty: PropTypes.bool.isRequired,
  saveCompletedCount: PropTypes.number,
  totalSaveCount: PropTypes.number,
  hasError: PropTypes.bool.isRequired,
  liveDataRef: PropTypes.object.isRequired,
  saveCounts: PropTypes.object.isRequired,
  onSetEffectiveDate: PropTypes.func.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  onSetDirty: PropTypes.func.isRequired,
  onShowAlert: PropTypes.func.isRequired,
  isProjectCompleted: PropTypes.bool.isRequired,
  hasTemplateError: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  listTimecards: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  onConfirmModal: PropTypes.func.isRequired,
  confirmModalIfDirty: PropTypes.func.isRequired,
  initialHiddenColumns: PropTypes.array.isRequired,
  storedSort: PropTypes.array.isRequired,
  employeeCount: PropTypes.number.isRequired,
  isSaving: PropTypes.bool.isRequired,
  allDayTypes: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(DTSTable);
