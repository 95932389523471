import React from 'react';
import { Box, Button } from '@mui/material';
import InstantDealInfoAlert from 'components/images/InstantDealInfoAlert';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { startPlusUrl } from 'constants/config/apiServer';
const useStyles = makeStyles(({ palette }) => ({
  InstantDeal: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `solid 1px ${palette.gray['+7']}`,
    padding: '4px 10px',
    fontSize: '14px',
    lineHeight: 1.5,
    borderRadius: '12px',
    margin: '10px',
    backgroundColor: palette.gray['+10'],
  },
  InstantDealMobile: {
    display: 'block',
  },
  button: {
    fontWeight: 500,
    borderRadius: '8px',
    marginLeft: '0px',
    padding: '5px',
  },
  mobileButton: {
    width: '100%',
    lineHeight: '20px',
    paddingLeft: '0px',
  },
}));
const InstantDealMessage = props => {
  const { isMobile = false } = props;
  const classes = useStyles();

  const handleCompleteOffer = () => {
    window.location.href = `${startPlusUrl}/onboarding`;
  };
  return (
    <Box
      className={clsx(classes.InstantDeal, {
        [classes.InstantDealMobile]: isMobile,
      })}
    >
      <Box sx={{ ml: '7px' }}>
        <InstantDealInfoAlert />
      </Box>
      <Box
        sx={{
          color: 'text.primary',
          p: '7px',
          pl: '15px',
          pt: isMobile ? '0px' : '7px',
        }}
      >
        <Box sx={{ fontWeight: 'bold' }}>Review Your Offers</Box>
        <Box sx={{ color: 'text.secondary', lineHeight: '1.5rem' }}>
          Your offer(s) may require your attention. Review them now in Start+.
        </Box>
        <Box sx={{ pt: '10px' }}>
          <Button
            variant="text"
            color="primary"
            className={clsx(classes.button, {
              [classes.mobileButton]: isMobile,
            })}
            onClick={handleCompleteOffer}
          >
            View Offers
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default InstantDealMessage;
