import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Paper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { capModal } from './capUtils';
import CapTracking from './CapTracking';
import withModalDialog from 'decorators/withModalDialog';
import ClearIcon from '@mui/icons-material/Clear';
//actions
import { fetchCapTracking } from 'feature/CapTracking/actions';
import { hide as hideModal } from 'actions/modalDialog';

import { getCapTrackingData } from 'feature/CapTracking/selectors';
import { useIsMobile } from 'utils/customHooks';

import { useDidMount } from 'utils/customHooks';

import {
  ContentBox,
  TitleBox,
} from 'feature/EmployeeTimecard/Shared/styledComponents';
const useStyles = makeStyles(({ palette }) => ({
  CapTrackingModal: {},
  mobileModal: {
    maxWidth: '350px',
  },
}));

const mapState = (state, ownProps) => {
  const employeeId = ownProps.employeeId;
  return {
    capTrackingData: getCapTrackingData(state, employeeId),
  };
};

const mapDispatch = dispatch => ({
  onHideModal: () => dispatch(hideModal({ dialog: capModal })),
  onFetchData: params => dispatch(fetchCapTracking(params)),
});

const CapTrackingModal = props => {
  const classes = useStyles();
  const { onHideModal, variant, capTrackingData, employeeId, onFetchData } =
    props;

  const onMobile = useIsMobile();

  useDidMount(() => {
    onFetchData({ employeeId });
  });

  return (
    <Paper
      className={clsx(classes.CapTrackingModal, {
        [classes.mobileModal]: onMobile,
      })}
    >
      <TitleBox>
        <Box sx={{ fontWeight: 600, fontSize: '18px' }}>Cap Tracker</Box>
        <Box>
          <ClearIcon
            className={'PENDO_cap_modal_close'}
            onClick={onHideModal}
            sx={{ color: 'text.secondary', cursor: 'pointer' }}
          />
        </Box>
      </TitleBox>
      <ContentBox>
        <CapTracking
          variant={variant}
          capTrackingData={capTrackingData}
          employeeId={employeeId}
        />
      </ContentBox>
    </Paper>
  );
};

CapTrackingModal.propTypes = {
  capTrackingData: PropTypes.array.isRequired,
  onHideModal: PropTypes.func,
  variant: PropTypes.string,
  employeeId: PropTypes.string.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: capModal,
    roundedCorners: true,
    maxWidth: 'md',
    clickAwayClose: true,
  }),
)(CapTrackingModal);
