export const ALLOWANCE_DIALOG = 'AllowanceModal';
export const CONFIRM_EDIT_MODAL = 'confirmEmpEdit';
export const CONFIRM_MODAL = 'ConfirmTimecardModal';
export const DEALMEMO_DIALOG = 'EmployeeDealMemoModal';
export const DELETE_TC_DIALOG = 'empDeleteTimecard';

export const DELETE_TC_ALLOWANCE_DIALOG = 'empTimecardAllowanceDelete';
export const SETTINGS_DIALOG = 'TimecardSettingsModal';
export const MOBILE_DAY_MODAL = 'MobileDayModal';
export const SIGNATURE_MODAL = 'empSignatureModal';
export const CONFIRM_COPY_TO_ALL_DAYS_MODAL = 'confirmCopyToAllDays';
export const SUBMIT_REMINDER_MODAL = 'submitReminderModal';

export const UNSAVED_CHANGES_MODAL = 'unsavedChangesModal';
export const HISTORY_DIALOG = 'historyDialog';
export const DEL_TC_FROM_LIST = 'DeleteTimecardFromList';
export const DAY_LEVEL_COMMENT_MODEL = 'empDayLevelCommentModal';
