import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import withRouter from 'decorators/withRouter';

import Button from '@mui/material/Button';
import {
  getIsProjectArchived,
  getIsProjectCompleted,
  getProjectDetails,
} from 'selectors/project';
import { currentUser } from 'selectors/session';

import { db } from 'feature/EmployeeTimecard/empTimecardUtils';
import InstantDealMessage from 'components/Shared/InstantDealMessage';

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  isProjectArchived: getIsProjectArchived(state),
  isProjectCompleted: getIsProjectCompleted(state),
  projectDetails: getProjectDetails(state),
  currentUser: currentUser(state),
});

class CreateTimecard extends Component {
  render() {
    const {
      projectId,
      isUserDeleted,
      isProjectArchived,
      isProjectCompleted,
      projectDetails,
      currentUser,
      style,
      isInstantDealTC,
      isMobile,
    } = this.props;

    const isDraft = projectDetails?.isDraft;
    const isTimePlusEnabled = projectDetails?.timePlusEnabled;
    const canAccessNextYearProject = projectDetails?.canAccessNextYearProject;
    const hasWorkSightId = !!currentUser.workSightId;
    const disableCreate =
      isUserDeleted ||
      isProjectArchived ||
      isProjectCompleted ||
      isDraft ||
      !isTimePlusEnabled ||
      canAccessNextYearProject ||
      !hasWorkSightId;

    if (disableCreate) {
      db('isUserDeleted', isUserDeleted);
      db('isProjectArchived', isProjectArchived);
      db('isProjectCompleted', isProjectCompleted);
      db('isDraft', isDraft);
      db('!isTimePlusEnabled', !isTimePlusEnabled);
      db('canAccessNextYearProject', canAccessNextYearProject);
      db('!hasWorkSightId', !hasWorkSightId);
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: isInstantDealTC ? 'space-between' : 'right',
        }}
      >
        {isInstantDealTC && !isMobile && (
          <div
            style={{
              padding: '7px',
              width: '80%',
              paddingLeft: '0px',
            }}
          >
            <InstantDealMessage />
          </div>
        )}
        <div style={style} key={`/projects/${projectId}/me/timecards/fresh-0`}>
          <NavLink
            to={`/projects/${projectId}/me/timecards/fresh-0`}
            onClick={e => {
              if (disableCreate) {
                e.preventDefault();
              }
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={disableCreate}
            >
              Create
            </Button>
          </NavLink>
        </div>
      </div>
    );
  }
}
CreateTimecard.propTypes = {
  projectId: PropTypes.string.isRequired,
  isUserDeleted: PropTypes.bool.isRequired,
  isProjectArchived: PropTypes.bool.isRequired,
  isProjectCompleted: PropTypes.bool.isRequired,
  projectDetails: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  style: PropTypes.object,
};
export default withRouter(connect(mapState)(CreateTimecard));
