import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import { Autocomplete, TextField, Popper } from '@mui/material';
import { dbPendo } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  wtcTextField: {
    '& .MuiInput-input': {
      fontSize: 14,
    },
    //Used to  remove the space reserved for the autocomplete clearX
    '& .MuiInput-root': {
      paddingRight: '10px !important',
    },
    //Used to hide the autocomplete clearX
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
  errorTextField: {
    backgroundColor: theme.palette.misc.errorBackground,
  },
}));

//util functions
const optionHash = {};
// handles the case where 2 options have the same label
// identical labels causes weird render/filter issues
// only changes the label, not the value of the option
const hashOptions = (str, option) => {
  if (!optionHash[str]) {
    optionHash[str] = [option.id];
  } else {
    let index = optionHash[str].findIndex(i => i === option.id);
    if (index === -1) {
      optionHash[str].push(option.id);
      index = optionHash[str].findIndex(i => i === option.id);
    }
    if (index) {
      str += ` (${index})`;
    }
  }
  return str;
};

const getOptionLabel = (option, isDate) => {
  if (typeof option === 'string') {
    if (isDate && option) {
      return moment(option, 'YYYY-MM-DD').format('MM-DD-YYYY');
    } else {
      return option;
    }
  }

  let str = '';
  if (option?.code) str = `${option.code}`;
  if (option?.code && option?.name) str += ' - ';
  if (option?.name) str += option.name;

  return hashOptions(str, option);
};

const isOptionEqualToValue = (o, v) => {
  if (typeof o === 'string') {
    return o === v;
  } else {
    return o.id === v.id;
  }
};

const canvasContext = document.createElement('canvas').getContext('2d');
canvasContext.font = 'normal 12pt Roboto';

const getDynamicTextWidth = text => {
  const metrics = canvasContext.measureText(text);
  const totalWidth = metrics.width + 15; //Add to account for arrow icon
  return totalWidth;
};

const AutoPopper = props => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.style?.width) {
    //Allow options popper to be wider than Autocomplete comp
    // eslint-disable-next-line react/destructuring-assignment
    delete props.style.width;
  }
  return <Popper {...props} />;
};

AutoPopper.propTypes = {
  style: PropTypes.object,
};

const reportToPendo = (name, option) => {
  try {
    const parts = name.split('.');
    const field = parts.length > 1 ? parts[parts.length - 1] : name;
    const data = {
      reportType: 'AutoCompleteTabWTC',
      field,
      value: getOptionLabel(option),
    };
    //report to pendo
    const pendoData = { ...data }; //pendo modifies the object so we send a copy
    window.pendo.track(data.reportType, pendoData);
    dbPendo(data.reportType, data.field);
  } catch (error) {
    console.error('Pendo Report Failed', error);
  }
};

/**
 *
 * @param {*} props
 * @returns
 */
const WTCAutoV2 = props => {
  const classes = useStyles();
  let { options = [] } = props;
  const {
    input: { onChange, onFocus, onBlur, value, name },
    meta: { error },
    disabled,
    async,
    loadOptions,
    minWidth = 70,
    maxWidth = 225,
    label = '',
    isClearable = true,
    onOpen = () => {},
    onClose = () => {},
    stringValue = false, //is options a list of strings ? otherwise assume objs
    isDate = false,
    isMasterRow = false,
    pendoClass = '',
    cellCoordClass,
  } = props;

  if (!stringValue) {
    const isValueInOptions = options.find(o => o.id === value?.id);
    if (!isValueInOptions && value?.id) {
      options = options.slice();
      options.unshift(_.cloneDeep(value));
    }
  }
  const [textVal, setTextVal] = useState(getOptionLabel(value, isDate));
  const highLightedRef = useRef(null);
  const popperOpenRef = useRef(false);

  //update display value if value changes
  useEffect(() => {
    setTextVal(getOptionLabel(value, isDate));
  }, [isDate, value]);

  // fire onChange event even if they click on the same value for masterRow
  const componentsProps = isMasterRow
    ? {
        paper: {
          onClick: e => {
            const clickedIndex = e.target.getAttribute('data-option-index');
            const newValue = options[clickedIndex];
            if (newValue?.id && newValue.id === value?.id) {
              onChange(newValue);
            }
          },
        },
      }
    : {};

  return (
    <Autocomplete
      value={value || null}
      id={`auto-complete-for-${name}`}
      renderInput={params => {
        params.inputProps.value = textVal;
        params.inputProps.className = clsx(pendoClass, cellCoordClass);
        let dynamicWidth = getDynamicTextWidth(textVal);
        if (dynamicWidth < 30) dynamicWidth = '100%';

        return (
          <TextField
            className={clsx(classes.wtcTextField, {
              [classes.errorTextField]: !!error,
            })}
            label={label}
            sx={{
              minWidth,
              width: dynamicWidth,
              maxWidth,
              // width: `max(calc(100%),${dynamicWidth}px)`,
              // width: '100%', need input width to be set on the largest one and the rest will follow suit
            }}
            error={!!error}
            placeholder=""
            onChange={e => {
              setTextVal(e.target.value);
              if (async) loadOptions(e.target.value);
            }}
            {...params}
          />
        );
      }}
      // loading={true} //TODO - use this eventually
      openOnFocus={false}
      disabled={disabled}
      options={options}
      autoHighlight={true}
      componentsProps={componentsProps}
      PopperComponent={AutoPopper}
      getOptionLabel={o => getOptionLabel(o, isDate)}
      isOptionEqualToValue={isOptionEqualToValue}
      onOpen={() => {
        popperOpenRef.current = true;
        onOpen();
      }}
      onClose={() => {
        popperOpenRef.current = false;
        onClose();
      }}
      onChange={(e, value) => {
        onChange(value);
        setTextVal(getOptionLabel(value, isDate));
      }}
      onFocus={() => onFocus()}
      onBlur={() => onBlur()}
      onHighlightChange={(e, option) => {
        highLightedRef.current = option;
      }}
      onKeyDown={e => {
        if (e.key === 'Backspace' && isClearable) {
          if (textVal === getOptionLabel(value, isDate)) {
            onChange(null);
            setTextVal('');
          }
        } else if (e.key === 'Tab') {
          if (popperOpenRef.current) {
            onChange(highLightedRef.current);
            reportToPendo(name, highLightedRef.current);
          }
        }
      }}
    />
  );
};

WTCAutoV2.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.bool,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  onOpen: PropTypes.func, //should be memoized/useCallback'ed
  onClose: PropTypes.func,
  stringValue: PropTypes.bool,
  isDate: PropTypes.bool,
  isMasterRow: PropTypes.bool,
  pendoClass: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isClearable: PropTypes.bool,
  meta: PropTypes.object.isRequired,
};

// export default WTCAutoV2;

export default React.memo(WTCAutoV2, (prev, next) => {
  if (prev.onOpen !== next.onOpen) {
    return false;
  }

  const prevJson = JSON.stringify(prev);
  const nextJson = JSON.stringify(next);

  const isEqual = prevJson === nextJson;
  return isEqual;
});
