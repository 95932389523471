import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import {
  Paper,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  LinearProgress,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';

import {
  ContentBox,
  TitleBox,
  FooterBox,
  Button,
} from 'feature/EmployeeTimecard/Shared/styledComponents';

import { onMRFRunDownload, setHotCostParams } from 'actions/reports';
import { hide as hideModal } from 'actions/modalDialog';

import withModalDialog from 'decorators/withModalDialog';

import { getHotCostParams, getLoading } from 'selectors/reports';

export const hotCostReportModalName = 'HotCostReportTypeModal';

const mapState = state => ({
  loading: getLoading(state),
  hotCostParams: getHotCostParams(state),
});
const mapDispatch = dispatch => ({
  onHideModal: () => dispatch(hideModal({ dialog: hotCostReportModalName })),
  onRunHotCostReport: hotCostParams => {
    dispatch(setHotCostParams({ hotCostParams }));
    dispatch(onMRFRunDownload());
  },
});
const useStyles = makeStyles(({ palette }) => ({
  HotCost: {
    width: '500px',
  },
  supportingText: {
    fontSize: '14px',
    color: palette.gray['+3'],
    paddingLeft: '30px',
  },
}));

const HotCostReportTypeModal = props => {
  const { onHideModal, onRunHotCostReport, loading, hotCostParams } = props;
  const [reportType, setReportType] = React.useState(null);
  const classes = useStyles();

  const onRun = () => {
    const newHotCostParams = { ...hotCostParams, reportType };
    onRunHotCostReport(newHotCostParams);
  };
  return (
    <Paper className={classes.HotCost}>
      {loading && <LinearProgress />}
      <TitleBox>
        <Box sx={{ fontWeight: 600, fontSize: '20px' }}>Budgeted Values</Box>
        <Box>
          <ClearIcon
            onClick={onHideModal}
            sx={{ color: 'text.secondary', cursor: 'pointer' }}
          />
        </Box>
      </TitleBox>
      <ContentBox>
        <RadioGroup
          value={reportType}
          onChange={e => setReportType(e.target.value)}
        >
          <FormControlLabel
            value="Automatic"
            control={<Radio />}
            label="Automatic"
          />
          <Box className={classes.supportingText}>
            Uses Studio and Distant budgeted values, according to each crew
            members's work location for the day
          </Box>

          <FormControlLabel value="Studio" control={<Radio />} label="Studio" />
          <Box className={classes.supportingText}>
            Uses the Studio budgeted values.
          </Box>

          <FormControlLabel
            value="Distant"
            control={<Radio />}
            label="Distant"
          />
          <Box className={classes.supportingText}>
            Uses the Distant budgeted values.
          </Box>

          <FormControlLabel value="Other" control={<Radio />} label="Other" />
          <Box className={classes.supportingText}>
            Uses the Other budgeted values.
          </Box>
        </RadioGroup>
      </ContentBox>
      <FooterBox sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={onHideModal}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || !reportType}
            className={classes.button}
            onClick={() => onRun()}
          >
            Run
          </Button>
        </Box>
      </FooterBox>
    </Paper>
  );
};
HotCostReportTypeModal.propTypes = {
  onHideModal: PropTypes.func.isRequired,
  onRunHotCostReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hotCostParams: PropTypes.object.isRequired,
};
export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({ dialog: hotCostReportModalName }),
)(HotCostReportTypeModal);
