import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  CapTrackingLegend: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    gap: '6px',
    color: palette.text.secondary,
  },
  outerBox: {
    display: 'flex',
    height: 'fit-content',
    gap: '4px',
  },
  dotBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    paddingTop: '2px',
  },
  text: { display: 'flex', gap: '4px', alignItems: 'center', flexWrap: 'wrap' },
  dot: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

const CapTrackingLegend = props => {
  const classes = useStyles();
  const { amount, color, label } = props;

  return (
    <Box className={classes.CapTrackingLegend}>
      <Box className={classes.outerBox}>
        <Box className={classes.dotBox}>
          <Box className={clsx(classes.dot)} sx={{ backgroundColor: color }} />
        </Box>
        <Box className={classes.text}>
          <span>
            {`${amount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}`}
          </span>

          <span>{label}</span>
        </Box>
      </Box>
    </Box>
  );
};

CapTrackingLegend.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CapTrackingLegend;
