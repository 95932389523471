import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import { useIsMobile } from 'utils/customHooks';

// decorators
import withDataGrid from 'decorators/withDataGrid';
// components
import TimecardList from 'components/Employees/Timecards/List';
import TimecardCardList from 'components/Employees/Timecards/CardList';
import DeleteListModal from 'feature/EmployeeTimecard/Modals/DeleteListModal';
// selectors
import { sortDataNoPagination } from 'selectors/sorting';
import { userDeleted, getProjUsers } from 'selectors/session';
import { getLoading } from 'selectors/timecards';
import { getProject } from 'selectors/routeParams';
import { getTimecards } from 'selectors/timecard/common';
// actions
import { fetch as fetchTimecards, setFromURL } from 'actions/timecards';

import { show as showModal } from 'actions/modalDialog';
import {
  EMPLOYEE_STATUS_TO_LABEL_MAP,
  TIMECARD_PROCESSING,
  TIMECARD_REJECTED,
  TIMECARD_INCOMPLETE,
  TIMECARD_READY_FOR_ME,
} from '../../../components/Shared/constants';
import { setCurrentApprovalFlow } from '../../../actions/reviews';
import CreateTimecard from '../../Nav/Pages/CreateTimecard';
import { getProjectDetails } from 'selectors/project';

import { DEL_TC_FROM_LIST } from 'feature/EmployeeTimecard/Modals/modalNames';

import { useDidMount } from 'utils/customHooks';
import { isInstantDealTimecard } from 'utils/wtcWeekUtils';

const grid = 'timecard-history-view';

const PENDING = [
  TIMECARD_PROCESSING.toLowerCase(),
  TIMECARD_INCOMPLETE.toLowerCase(),
  TIMECARD_REJECTED.toLowerCase(),
  EMPLOYEE_STATUS_TO_LABEL_MAP.pending_employee_review.toLowerCase(),
  TIMECARD_READY_FOR_ME.toLowerCase(),
];

const mapStateToProps = (state, ownProps) => {
  const timecards = getTimecards(state);

  let approvedTimecardId;
  if (ownProps.location && ownProps.location.search) {
    const [key, value] = ownProps.location.search.split('=');
    if (key && key.includes('approvedTimecardId')) {
      approvedTimecardId = value;
    }
  }
  return {
    timecards: sortDataNoPagination(state => timecards, grid)(state),
    projectDetails: getProjectDetails(state),
    activeUser: getProjUsers(state),
    loading: getLoading(state),
    project: getProject(state),
    approvedTimecardId,
    isUserDeleted: userDeleted(state),
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(fetchTimecards());
  },

  onClickV1: timecard => {
    dispatch(setFromURL({ fromURI: match.url }));
    const statusLowercase = timecard.status.toLowerCase();
    const finish = PENDING.includes(statusLowercase);

    let toURL = `/projects/${timecard.projectId}/me/timecardsv1/${timecard.timecardId}`;
    if (finish) {
      if (
        statusLowercase ===
          EMPLOYEE_STATUS_TO_LABEL_MAP.pending_employee_review.toLowerCase() &&
        timecard.activeApprovalFlowId
      ) {
        dispatch(
          setCurrentApprovalFlow({
            approvalFlow: { id: timecard.activeApprovalFlowId },
          }),
        );
        toURL = `${toURL}/approval-flows/${timecard.activeApprovalFlowId}/review?finishTimecard=false`;
      } else if (
        statusLowercase !== TIMECARD_REJECTED &&
        timecard.activeApprovalFlowId
      ) {
        dispatch(
          setCurrentApprovalFlow({
            approvalFlow: { id: timecard.activeApprovalFlowId },
          }),
        );
        toURL = `${toURL}/approval-flows/${timecard.activeApprovalFlowId}/review?finishTimecard=true`;
      }
    } else {
      toURL = `${toURL}/review?fromHistory=true`;
    }

    dispatch(push(toURL));
  },
  onClick: timecard => {
    let toURL = `/projects/${timecard.projectId}/me/timecards/${timecard.timecardId}`;
    dispatch(push(toURL));
  },
  onDelete: timecardId => {
    dispatch(
      showModal({
        dialog: DEL_TC_FROM_LIST,
        maxWidth: 'md',
        modalParams: { timecardId },
      }),
    );
  },
});

const TimecardHistoryView = props => {
  const { isUserDeleted, onFetchData, timecards } = props;

  useDidMount(() => onFetchData());

  const isMobile = useIsMobile();

  const isInstantDealTC = timecards.some(tc => isInstantDealTimecard(tc));

  return (
    <div className="history">
      {isMobile && <TimecardCardList {...props} />}

      {!isMobile && (
        <React.Fragment>
          <CreateTimecard
            style={{ textAlign: 'right', marginBottom: 30, marginTop: 10 }}
            isUserDeleted={isUserDeleted}
            isInstantDealTC={isInstantDealTC}
            isMobile={isMobile}
          />
          <TimecardList {...props} />
        </React.Fragment>
      )}

      <DeleteListModal />
    </div>
  );
};

TimecardHistoryView.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  isUserDeleted: PropTypes.bool.isRequired,
};

export default withDataGrid(grid)(
  connect(mapStateToProps, mapDispatchToProps)(TimecardHistoryView),
);
