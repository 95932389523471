/**
 * This file contains all the paths for the application that need to be referenced elsewhere.
 *
 * Exporting directly from the routes file is not recommended as it can lead to circular dependencies.
 */

//project timecard routes
export const MY_TIMECARDS_PATH = '/projects/:projectId/me/timecards';
export const TIMECARD_PATH = '/projects/:projectId/me/timecards/:timecardId';

//projectCrewTimecard routes
export const CREW_CREATE_PATH =
  '/projects/:projectId/timecards/week-ending/:weekEndingdate/department/:departmentId';
export const CREW_TIMECARD_PATH =
  '/projects/:projectId/timecards/week-ending/:weekEnding/department/:departmentId/team/:id';

//project invoice routes
export const SEARCH_INVOICE_PATH = '/projects/:projectId/search-invoices';
