import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

/**
 * SubmitButton prevents multiple clicks on the button, to be used anywhere a button directly fires an API call or action.
 * Drop in replacement for MUI Button.
 *
 * Does NOT support form submission throttling. Form submission must be throttled at the onSubmit function.
 */
const SubmitButton = props => {
  const { onClick, disabled, clickTimeout = 750, ...rest } = props;

  const [clicked, setClicked] = React.useState(false);

  const setClick = e => {
    setClicked(true);
    onClick(e);
    setTimeout(() => {
      setClicked(false);
    }, clickTimeout);
  };

  return <Button onClick={setClick} disabled={disabled || clicked} {...rest} />;
};

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  clickTimeout: PropTypes.number,
  disabled: PropTypes.bool,
  type: (props, propName, componentName) => {
    const type = props.type;
    const onClick = props[propName];

    if (type && typeof onClick !== 'function') {
      return new Error(
        `SubmitButton cannot be used for form submission.\n\nForm submit must be throttled at the onSubmit function.\nSee feature/CrewList/Modals/Impersonate.js for example\n\n`,
      );
    }
    return null;
  },
};

export default SubmitButton;
